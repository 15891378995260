<template>
  <div class="order-print">
    <!-- 右边部分 -->
    <div class="right">

      <div class="add-food">
        <van-form>
          <van-field label="切换至管理模式">
            <template #input>
              <van-switch v-model="managerMode" active-color="#1890FF" inactive-color="#dcdee0" @change="changeMode" :active-value="1" :inactive-value="0" />
            </template>
          </van-field>
          <div class="storeModelState">
            <!-- <div class="mg-bt10">
              <div class="font_title">
                <span>经营模式：</span
                >关闭大量管理功能，使页面更加简洁，使用更加轻便，<span
                  >偏向日常使用</span
                >
              </div>
              <div class="font_title">
                <span>管理模式：</span
                >打开管理功能，可对餐厅、菜单、会员等基础设置进行维护，<span
                  >偏向门店管理</span
                >
              </div>
            </div> -->
            <table cellspacing="0" cellpadding="0" style="border-collapse: collapse" width="70%" border="0">
              <thead bgcolor="#F2F2F2">
                <tr>
                  <td class="table_titl">功能</td>
                  <td class="table_titl">经营模式</td>
                  <td class="table_titl">管理模式</td>
                </tr>
              </thead>
              <tbody>
                <tr :class="['color-333', 'ft-24', 'text-center', 'tbody-tr']" v-for="(item, index) in modelStateList" :key="index">
                  <td class="tablewidth">{{ item.name }}</td>
                  <td class="tablewidth">
                    <van-icon name="cross" v-if="item.operate == 0" />
                    <van-icon name="success" v-else />
                  </td>
                  <td class="tablewidth"><van-icon name="success" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </van-form>
      </div>
    </div>
    <div class="ratholOrChair" v-if="isChange">
      <div class="modal-content-box relative" style="z-index: 2002">
        <img src="@/static/images/close.png" class="close absolute" alt srcset @click="closePopDialog" />
        <div class="wrapper PingFang-Medium">
          <!-- 标题 -->
          <div :class="[
              'title',
              'color-333',
              'ft-28',
              'flex',
              'flex-ac',
              'flex-jc',
              'ft-bold',
            ]">
            {{ title }}
          </div>

          <div class="content">
            <div class="deskcontent">
              <div class="di-box">
                <div class="dilabel"></div>
                <div class="desklabel" v-if="managerMode == 0">
                  <span>确认切换为经营模式？</span>
                </div>
                <div v-if="managerMode == 1 && isChange" class="flex flex-ac">
                  <div class="dilabel">管理密码</div>
                  <input class="diinput" type="password" v-model="originPaw" @keyup.enter="confirmPopDialog" />
                </div>
                <div v-if="managerMode == 1 && isChange" class="ft-20 color-666 mg-tp15 flex flex-ac" style="margin-left: 13.8rem;">
                  <van-icon name="info" color="#666" size="2.4rem" class="mg-rt7" />
                  默认管理密码为：123456
                </div>
                <div v-if="errorMsg != ''" class="ft-24 color-e60012 mg-tp15" style="margin-left: 1.8rem;">{{ errorMsg }}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 9rem"></div>
        <!-- 确认/取消 -->
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closePopDialog">取消</button>
          <button class="confirm" @click="confirmPopDialog">确认</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      originPaw: "", // 管理密码
      managerMode: 0,
      isChange: false,
      modelStateList: [
        {
          name: "产品主页",
          operate: 1,
        },
        {
          name: "收银结算",
          operate: 1,
        },
        {
          name: "订单管理",
          operate: 1,
        },
        {
          name: "会员管理",
          operate: 1,
        },
        {
          name: "店铺信息管理",
          operate: 0,
        },
        {
          name: "商品管理",
          operate: 0,
        },
        {
          name: "商品分类管理",
          operate: 0,
        },
        {
          name: "会员等级管理",
          operate: 0,
        },
        {
          name: "员工管理",
          operate: 0,
        },
        {
          name: "短信设置",
          operate: 0,
        },
        {
          name: "管理密码修改",
          operate: 0,
        },
      ],
      title: "管理模式",
      errorMsg: '',
    };
  },
  computed: {
    ...mapGetters({ shop: "shop" }),
  },
  watch: {
    'shop.user_model': {
      handler(newV, oldV) {
        this.managerMode = newV;
      },
      deep: true
    }
  },
  methods: {
    changeMode(e) {
      this.managerMode = e;
      this.originPaw = "";
      this.errorMsg = '';
      this.isChange = true;
    },
    // 提交
    onSubmit(e) {
      if (this.managerMode && this.originPaw == "") {
        this.errorMsg = '管理密码不能为空';
        this.managerMode = this.shop.user_model;
        return;
      }
      let params = {
        type: this.managerMode, // 1,改为管理模式  0，改为普通模式
      }
      if (this.managerMode) {
        params['paw'] = this.originPaw;
      }
      this.$api.dt_changeModel(params).then((res) => {
        if (res.code == 1) {
          if (this.managerMode == 1) {
            this.$toast("已切换至管理模式，门店设置已更新");
          } else if (this.managerMode == 0) {
            this.$toast("已切换至经营模式，门店设置已更新");
          }
          this.isChange = false;
          this.getShop();
          this.errorMsg = '';
        } else {
          this.errorMsg = res.msg || "操作失败";
        }
      });
    },
    getShop() {
      this.$api.dt_getShopInfo().then((res) => {
        if (res.code == 1) {
          // user_model:1，表示是店长模式 0，表示为普通模式
          // this.$store.commit("setShopInfo", res.data);
          this.$store.dispatch("setShopInfo", res.data);
        }
      });
    },
    closePopDialog() {
      this.isChange = false;
      if (this.managerMode == 0) {
        this.managerMode = 1;
      } else if (this.managerMode == 1) {
        this.managerMode = 0;
      }
    },
    confirmPopDialog() {
      if (this.managerMode && this.originPaw == "") {
        this.errorMsg = '管理密码不能为空';
        return;
      }
      this.onSubmit();
    },
  },
  created() {
    this.getShop();
  },
};
</script>

<style lang="less" scoped>
.order-print {
  height: 100%;
  text-align: left;
}
.storeModelState {
  background: #fff;
  padding: 3rem 3rem;
}

.right {
  background: #fff;
  height: 100%;
}

.add-food {
  //border: 1px solid red;
  // height: calc(100vh - 1rem);
  // margin-top: 0.2rem;

  .van-cell.van-field {
    font-size: 2.6rem;
    padding: 2rem 3rem;

    /deep/.van-field__label {
      width: 28rem;
    }

    /deep/.van-cell__title span {
      line-height: 6rem;
    }

    /deep/.van-field__control {
      height: 6rem;

      .van-radio__icon {
        font-size: 2.4rem;
      }

      .van-radio__icon--checked {
        font-size: 2.4rem;

        .van-icon {
          background-color: #1588f5;
          border-color: #1588f5;
        }
      }
    }
  }

  .van-cell.van-field {
    font-size: 2.6rem;

    /deep/textarea.van-field__control {
      line-height: 1.1;
    }

    /deep/textarea.van-field__control::placeholder {
      transform: translateY(0.1rem);
    }

    /deep/.van-field__word-limit {
      font-size: 2.6rem;
    }
  }

  .van-button--info {
    width: 8.33rem;
    height: 1.27rem;
    background: #1588f5;
    border-radius: 0.13rem;
    border: 0;
  }
}

.font_title {
  font-size: 2.6rem;
  height: 4.5rem;
  span {
    font-weight: bold;
    color: #1588f5;
  }
}
.tabletitle {
  font-size: 0.4rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.25rem;
  color: #1588f5;
}
table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
table {
  margin: 0 auto;
  position: relative;
  display: table;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  tbody {
    overflow-y: scroll;
    display: block;
    height: calc(85vh - 19rem);
    width: 100%;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    display: block;
    width: 0.6rem;
    height: 0.5rem;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 0.5rem;
    min-width: 0.4rem;
    border-radius: 0.8rem;
    background-color: rgb(217, 217, 217);
  }
}
.table_titl {
  text-align: center;
  font-size: 2.4rem;
  // width: 5rem;
  height: 7.5rem;
  font-weight: bold;
}
.tbody-tr {
  overflow-y: scroll;
  width: 100%;
  border-bottom: 0.15rem rgba(0, 0, 0, 0.06) solid;
  padding: 1.5rem 0;
  min-height: 7.5rem;
  &.odd {
    background: #fef7ef;
  }
}
.van-button--normal {
  font-size: 0.4rem;
}
.tablewidth {
  width: 5rem;
  height: 0.75rem;
}
.ratholOrChair {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content-box {
    width: 82rem;
    max-height: 61vh;
    height: fit-content;
    border-radius: 0.8rem;
    background: #fff;
  }
  .close {
    width: 2.6rem;
    height: 2.6rem;
    right: 2.5rem;
    top: 2.5rem;
    z-index: 10;
  }
  .wrapper {
    padding: 0rem 3.5rem;
    position: relative;

    .title {
      padding-top: 2.5rem;
      border-bottom: 0.15rem solid rgba(204, 204, 204, 1);
      padding-bottom: 2.5rem;
    }
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8.5rem;
    .confirm,
    .cancel {
      color: #fff;
      min-width: 20rem;
      height: 6.5rem;
      border-radius: 0.8rem;
      font-size: 2.4rem;
      padding: 0 2.5rem;
    }
    .cancel {
      background: #eaeaea;
      color: #666666;
      margin-right: 10rem;
    }
    .confirm {
      background: #1588f5;
    }
  }
}

.content {
  margin: 5rem 5rem 3rem;
  position: relative;
  min-height: 18vh;
  display: flex;
  justify-content: center;
  .deskcontent {
    // display: flex;
    // align-items: flex-start;
    font-size: 2.6rem;

    .di-box {
      margin-bottom: 2.5rem;
      // display: flex;
      // align-items: center;
      .dilabel {
        font-size: 2.6rem;
        .desklabel {
          display: flex;
          align-items: flex-start;
          font-size: 2.6rem;
        }
      }
      .diinput {
        width: 40rem;
        height: 6rem;
        border: 0.15rem solid #dedede;
        border-radius: 0.8rem;
        margin-left: 3rem;
        padding: 0 2.5rem;
        flex: 1;
      }
    }
  }
}
</style>
