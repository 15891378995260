<template>
  <div class="app-main">
    <div class="left-menu">
      <img src="@/static/dzb.png" class="logo-img" />
      <div class="menu-list">
        <div v-for="item in menu" :key="item.type" class="menu-item" :class="{ 'active-menu-item': active_menu == item.type, }" @click="handleChangeMenu(item)">
          <img :src="item.icon" class="menu-icon" />
          <span class="menu-title">{{ item.title }}</span>
        </div>
      </div>
    </div>

    <div class="top-bar">
      <div class="flex flex-ac account-box" style="height: 100%;">
        <img src="@/static/images/shopimg-default.png" class="logoimg" />
        <div class="account-info">
          <div class="ft-24 one-line-ellipsis color-666 mg-bt5" style="height: 3rem; line-height: 3rem;">{{ shop.name }}</div>
          <div class="ft-24 one-line-ellipsis color-666">{{ account }}</div>
        </div>
      </div>
      <div class="top-bar-notify" v-if="notityShow && notifyNum > 0">
          {{ printOrderNotify }}
        <button v-show="this.notifyNum > 0" class="clear-notify" @click="toggleVoice">{{isVoiceEnabled ? '语音开':'语音关' }}</button>
        <button v-show="this.notifyNum > 0" class="clear-notify" @click="clearNotify">清除通知</button>
      </div>
      <div class="top-bar-time" v-else>{{ now_time_str }}</div>
      <div class="top-bar-btns">
        <!-- 通知按钮 -->
         <button class="t-b-b-btn bg-FE724F" @click="openNotice">
          <!-- <van-icon name="notification" size="2.6rem" color="#fff" class="mg-rt15" /> -->
          通知 {{ this.notifyNum > 0 ? '(' + this.notifyNum + ')' : '' }}
        </button>
        <button class="t-b-b-btn bg-1588F5" @click.stop="openDevieBox">
          <van-icon name="setting" color="#fff" size="3.2rem" class="mg-rt15" @click.stop="openChooseBox" v-if="nowDevice == 'android' || nowDevice == 'mt_android'" />
          <van-loading v-if="is_show_loading_box" color="#fff" size="3.2rem" style="margin-right: 0.8rem;" />
          钱箱
        </button>
        <button class="t-b-b-btn bg-3DB956" @click="changeModel"><van-icon name="exchange" size="2.6rem" color="#fff" class="mg-rt15" />{{ changeModelTextH }}</button>
        <button class="t-b-b-btn bg-1D5CFA" @click="toSuccession">交接班</button>
        <div class="flex flex-ac mg-rt30" @click="showIpContModal">
          <img src="@/static/images/IP.png" class="fixed ipimg" />
        </div>
      </div>
    </div>

    <!-- 切换模式 -->
    <div class="changemodel-modal" v-if="isShowModal">
      <div class="changemodel-box">
        <img src="@/static/images/close.png" class="close absolute" alt srcset @click="closeModal" />
        <div class="modaltitle">{{ changeModelText }}</div>
        <div class="modalcont">
          <div class="modalcont-item" v-if="managerMode == 1">
            <div class="flex flex-ac">
              <label class="modalcont-label">管理密码</label>
              <input class="modalcont-input" type="password" @keyup.enter="confirmChange" v-model="originPaw" />
            </div>
            <div class="ft-22 color-666 mg-tp15 flex flex-ac" style="margin-left: 15.8rem;">
              <van-icon name="info" color="#666" size="2.4rem" class="mg-rt15" />
              默认管理密码为：123456
            </div>
            <div v-if="errorMsg != ''" class="ft-22 color-e60012 mg-tp15" style="margin-left: 15.8rem;">{{ errorMsg }}</div>
          </div>
          <div class="modalcont-text" v-if="managerMode == 0">确认切换为经营模式？</div>
        </div>
        <div class="btns flex flex-ac flex-jc">
          <button class="cancel" @click="closeModal">取消</button>
          <button class="confirm " @click="confirmChange">确认</button>
        </div>
      </div>
    </div>

    <popup-of-dialog v-if="showDialog" :type="dialogType" :title="dialogTitle" :message="dialogMsg" :data="dialogData" :showCancelBtn="false" :showConfirmBtn="false" @closePopDialog="closePopDialog"></popup-of-dialog>

    <div class="choose-scale-modal" v-if="show_choose_box">
      <div class="modal-content-box relative">
        <img src="@/static/images/close.png" class="close" alt srcset @click="closeBoxModal" />
        <div class="wrapper">
          <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">钱箱连接方式</div>
          <div class="choose-scle-box">
            <van-radio-group v-model="choose_box">
              <van-radio name="1" icon-size="3rem" class="scale-radio">和成东内置</van-radio>
              <van-radio name="2" icon-size="3rem" class="scale-radio">小票机</van-radio>
              <van-radio name="3" icon-size="3rem" class="scale-radio">美团内置</van-radio>
              <!-- <van-radio name="4" icon-size="3rem" class="scale-radio">蓝牙打印机</van-radio> -->
            </van-radio-group>
            <button class="clear-scale" @click="choose_box = '';">清空选择</button>
          </div>
        </div>
        <div style="height: 9rem;"></div>
        <!-- 确认/取消 -->
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeBoxModal">取消</button>
          <button class="confirm " @click="confirmBoxModal">保存</button>
        </div>
      </div>
    </div>

    <!-- 系统提示 -->
    <van-notice-bar
      class="global-notify-box"
      v-show="isShowNotify"
      mode="closeable"
      left-icon="volume-o"
      color="#000"
      background="#ffede9"
    >
      {{notifyContent}}
    </van-notice-bar>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import popupOfDialog from './popupOfDialog.vue';
import { openBox } from '../../utils/outPrint';
import newSelfPickup from '@/assets/voice/new_self_pickup.MP3';
import newTakeAway from '@/assets/voice/new_take_away.MP3';
import { clear } from 'xe-utils';
export default {
  name: 'navtar',
  components: {
    'popup-of-dialog': popupOfDialog,
  },
  data() {
    return {
      allMenuList: [
        { type: 1, title: '收银', name: 'index', icon: require('@/static/images/shouyin.png') },
        { type: 4, title: '会员', name: 'vipManagement', icon: require('@/static/images/huiyuan.png') },
        { type: 2, title: '商品', name: 'manageProducts', icon: require('@/static/images/shangpin.png') },
        { type: 3, title: '订单', name: 'orderManage', icon: require('@/static/images/dingdan.png') },
        { type: 6, title: '报表', name: 'revenue', icon: require('@/static/images/baobiao.png') },
        { type: 5, title: '设置', name: 'featureSet', icon: require('@/static/images/shezhi.png') },
      ],
      active_menu: 1,
      is_show_loading_box: false,
      isShowModal: false,
      originPaw: '',
      managerMode: 0, // // 1,改为管理模式  0，改为普通模式
      errorMsg: '',
      showDialog: false,
      dialogType: '',
      dialogTitle: '',
      dialogMsg: '',
      dialogData: {},
      show_choose_box: false,
      box_type: '',
      choose_box: '',
      printOrderJobIntervalId: null, // 定时器ID
      printOrderNotify: '',  //轮询自取和外卖订单提示打印通知
      notifyNum: 0,
      isVoiceEnabled: true, // 添加语音播报开关状态
      notifyOrders: [],  //订单通知列表
      notityShow: false, // 订单通知显示状态
    }
  },
  computed: {
    ...mapGetters({ shop: 'shop' }),
    now_time_str() {
      return this.$store.state.now_time_str || '';
    },
    changeModelTextH() {
      if (this.shop.user_model == 1) {
        return '经营模式'
      } else {
        return '管理模式'
      }
    },
    changeModelText() {
      if (this.shop.user_model == 1) {
        return '切换至经营模式'
      } else {
        return '切换至管理模式'
      }
    },
    localIp() {
      let ip = '';
      if (this.nowDevice == 'android' || this.nowDevice == 'mt_android') {
        ip = this.$store.state.deviceInfo.ip || '';
      } else if (this.nowDevice == 'windows') {
        ip = this.$store.state.deviceIp || '';
      }
      return ip;
    },
    nowDevice() {
      return localStorage.nowDevice || '';
    },
    account() {
      return localStorage.ls_account || '';
    },
    menu() {
      let menu_ = this.allMenuList;
      if (this.shop.user_model != 1) {
        menu_ = menu_.filter(e => e.type != 6);
      }
      return menu_;
    },
    isShowNotify() {
      console.log('this.isShowNotify:', this.$store.state.isShowNotify);
      return this.$store.state.isShowNotify;
    },
    notifyContent() {
      return this.$store.state.notifyContent;
    },
  },
  created() {
    this.active_menu = 1;
    this.setCurrentRoute();
    this.startPrintOrderJobPolling(); // 启动定时任务
  },
  methods: {
    openNotice(){
      this.notityShow = !this.notityShow;
      this.isVoiceEnabled = true;
    },
    closeNotify() {  
      // 关闭提醒
      this.notityShow = false;
      // 同时关闭语音播报
      this.isVoiceEnabled = false;
    },
    clearNotify() {
      this.notifyNum = this.notifyNum - 1;
      let data = {
        shop_id: this.shop.id,
        order_id: this.notifyOrders[0].order_id,
      }
      //调用清除通知接口
      this.$api.updateOrderJob(data).then(res => {
        if (res.code == 1) {
          this.$toast("清除成功");
        } else {
          this.$toast("清除失败");
        }
      });
    },
    confirmBoxModal() {
      let query = {
        name: this.shop.name,
        tel: this.shop.tel,
        xq: this.shop.xq || '',
        workTimeS: this.shop.workTimeS,
        workTimeE: this.shop.workTimeE,
        scale_type: this.shop.scale_type,
        box_type: this.choose_box,
      }
      this.$api.dt_updateShopInfo(query).then(async (res) => {
        if (res.code == 1) {
          this.$toast("保存成功，门店设置已更新");
          localStorage.setItem('box_type', this.choose_box);
          this.closeBoxModal();
        } else {
          this.$toast(res.msg || "修改门店信息失败");
        }
      });
    },
    closeBoxModal() {
      this.show_choose_box = false;
    },
    openChooseBox() {
      this.choose_box = localStorage.box_type;
      this.show_choose_box = true;
    },
    showIpContModal() {
      this.dialogType = 'showIp';
      this.dialogTitle = 'IP';
      this.dialogMsg = `本机IP地址：【${this.localIp}】`;
      this.showDialog = true;
    },
    closePopDialog() {
      this.showDialog = false;
    },
    closeModal() {
      this.isShowModal = false;
      this.$store.commit('updateIsChangeModel', false);
      this.managerMode = 0;
      this.originPaw = '';
    },
    confirmChange() {
      if (this.managerMode == 1 && this.originPaw == "") {
        this.errorMsg = '管理密码不能为空'
        return;
      }
      let data = {
        type: this.managerMode, // 1,改为管理模式  0，改为普通模式
      }
      if (this.managerMode) {
        data['paw'] = this.originPaw;
      }
      this.$api.dt_changeModel(data).then((res) => {
        if (res.code == 1) {
          this.$toast(res.msg || "操作成功");
          this.closeModal();
          this.getShop();
        } else {
          this.errorMsg = res.msg || "操作失败";
        }
      });
    },
    getShop() {
      this.$api.dt_getShopInfo().then((res) => {
        if (res.code == 1) {
          // user_model:1，表示是店长模式 0，表示为普通模式
          // this.$store.commit("setShopInfo", res.data);
          this.$store.dispatch("setShopInfo", res.data);
        }
      });
    },
    // 切换模式
    changeModel() {
      this.errorMsg = '';
      if (this.shop.user_model == 1) { // 需要切换至经营模式
        this.managerMode = 0;
        this.isShowModal = true;
      } else { // 需要切换至管理模式
        this.managerMode = 1;
        this.isShowModal = true;
        this.$store.commit('updateIsChangeModel', true);
      }
    },
    setCurrentRoute() {
      // console.log(this.$route);
      const index = this.menu.findIndex(e => (e.name == this.$route.name || e.name == this.$route.meta.parent_name));
      if (index > -1) {
        this.active_menu = this.menu[index].type;
      }
    },
    toSuccession() {
      this.$router.push({
        path: "/succession",
        query: { name: "succession" },
      });
    },
    openDevieBox() {
      this.is_show_loading_box = true;
      setTimeout(() => {
        this.is_show_loading_box = false;
      }, 500);
      // this.$dc.openMoneyBox();
      if (this.nowDevice == 'android' || this.nowDevice == 'mt_android') {
        openBox();
      } else if (this.nowDevice == 'windows') {
        this.$device.openCasher();
      }
    },
    handleChangeMenu(item) {
      if (this.active_menu != item.type) {
        this.active_menu = item.type;
        this.$router.replace({
          name: item.name,
          query: {
            name: item.name
          }
        })
      }
    },
    startPrintOrderJobPolling() {
      // 每隔一段时间调用一次printOrderJob方法，这里假设每隔60秒调用一次
      this.printOrderJobIntervalId = setInterval(() => {
        this.printOrderJob();
      }, 20000);
    },
    clearPrintOrderJobPolling() {
      clearInterval(this.printOrderJobIntervalId); // 清除定时器
    },
    printOrderJob() {
      console.log('haidaozy printOrderJob shop:',this.shop);
      // 调用printOrderJob接口的方法
      this.$api.printOrderJob({shop_id:this.shop.id}).then((response) => {
        if (response.code === 1) {
          // 处理成功的逻辑
          console.log('haidaozy printOrderJob success:', response);
          this.notifyOrders = response.data || [];

          let orders = response.data || [];
          // 通知栏显示待处理订单条数。
          this.notifyNum = orders.length;  // 设置通知栏显示的待处理订单条数
          this.printOrderNotify = '您有' + orders.length + '条小程序订单待打印。'
          // 清除之前的定时器
          this.clearPrintOrderJobTimers();
          // 设置新的定时器
          this.playOrderVoicesWithDelay(orders, 0);

          // 更新 Vuex store
          this.$store.dispatch('updateNotifyOrders', this.notifyOrders);

        } else {
          // 处理错误的逻辑
          console.error('printOrderJob failed:', response.msg);
        }
      }).catch((error) => {
        console.error('Error calling printOrderJob:', error);
      });
    },
    handlePrintOrder(response) {
      // 打印订单的逻辑
      console.log('Printing order:', response);
      // 在这里添加具体的打印订单逻辑
    },
    handleCloseVoice() {
      // 关闭语音的逻辑
      console.log('Closing voice');
      // 在这里添加具体的关闭语音逻辑
    },
    clearPrintOrderJobTimers() {
      if (this.printOrderTimers) {
        this.printOrderTimers.forEach(timer => clearTimeout(timer));
      }
      this.printOrderTimers = [];
    },
    playOrderVoicesWithDelay(orders, index) {
      if (index >= orders.length) return;

      const delay = index === 0 ? 0 : 5000; // 第一条订单立即播放，后续订单间隔60秒
      const timer = setTimeout(() => {
        this.playOrderVoice(orders[index]);
        this.playOrderVoicesWithDelay(orders, index + 1);
      }, delay);

      this.printOrderTimers.push(timer);
    },
    toggleVoice() {
      this.isVoiceEnabled = !this.isVoiceEnabled;
      console.log('isVoiceEnabled:', this.isVoiceEnabled);
    },
    playOrderVoice(element) {
      if (!this.isVoiceEnabled) return; // 如果语音播报未开启，直接返回

      let audioPath;
      switch (element.order_type) {
        case 2:
          // 新的外卖订单
          audioPath = newTakeAway; // 使用require确保路径正确
          break;
        case 3:
          // 新的自取订单
          audioPath = newSelfPickup; // 使用require确保路径正确
          break;
        default:
          console.warn(`未知的订单类型: ${element.type}`);
          return;
      }

      if (audioPath) {
        const instance = new Audio(audioPath); // 创建音频对象并设置路径
        instance.play()
          .then(() => {
            console.log('音频播放成功');
          })
          .catch(error => {
            console.error('音频播放失败:', error);
          });
      }
    }
  },
  beforeDestroy() {
    this.clearPrintOrderJobPolling(); // 清除定时任务
  },
  watch: {
    $route() {
      this.setCurrentRoute()
    }
  },
}
</script>
<style lang="less">
.global-notify-box {
  position: fixed !important;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  font-size: 2rem !important;
  height: 8rem !important;
  /deep/.van-icon {
    font-size: 0.4rem;
  }
  /deep/.van-notice-bar__left-icon {
    margin-right: 0.2rem;
  }
  /deep/.van-notice-bar__right-icon {
    margin-left: 0.2rem;
  }
}
.choose-scale-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-content-box {
    width: 72rem;
    max-height: 88vh;
    height: fit-content;
    border-radius: 0.8rem;
    background-color: #fff;
  }
  .close {
    width: 2.6rem;
    height: 2.6rem;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
    z-index: 10;
  }
  .wrapper {
    padding: 0rem 3.5rem;
    position: relative;

    .title {
      padding-top: 2.5rem;
      border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
    }
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8.5rem;
    .confirm,
    .cancel {
      color: #fff;
      min-width: 20rem;
      height: 6.5rem;
      border-radius: 0.8rem;
      font-size: 2.4rem;
      padding: 0 2.5rem;
    }
    .cancel {
      background: #eaeaea;
      color: #666666;
      margin-right: 10rem;
    }
    .confirm {
      background: #1588f5;
    }
  }

  .choose-scle-box {
    margin: 4rem 10rem;
    .scale-radio {
      margin-bottom: 2.7rem;
      font-size: 2.4rem;
    }
    .clear-scale {
      font-size: 2.1rem;
      color: #ffffff;
      border: 0;
      padding: 0 1.6rem;
      height: 5rem;
      border-radius: 0.8rem;
      display: flex;
      align-items: center;
      background: #1588f5;
    }
  }
}
.changemodel-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .changemodel-box {
    background: #fff;
    width: 82rem;
    max-height: 61vh;
    border-radius: 0.8rem;
    position: relative;

    .modaltitle {
      color: #333;
      font-size: 2.8rem;
      font-weight: bold;
      padding-top: 2.5rem;
      border-bottom: 0.15rem solid rgba(204, 204, 204, 1);
      padding-bottom: 2.5rem;
      text-align: center;
    }

    .close {
      width: 2.6rem;
      height: 2.6rem;
      right: 2.5rem;
      top: 2.5rem;
      z-index: 10;
    }

    .modalcont-item {
      width: 84%;
      padding: 4rem 5rem 0;
      margin: auto;
      min-height: 23vh;

      .modalcont-label {
        font-size: 2.6rem;
        margin-right: 2rem;
      }

      .modalcont-input {
        width: 40rem;
        height: 6rem;
        border: 0.15rem solid #dedede;
        border-radius: 0.8rem;
        margin-left: 3rem;
        padding: 0 2.5rem;
        font-size: 2.6rem;
      }
    }

    .modalcont-text {
      font-size: 2.8rem;
      margin: 11rem 2.5rem 13rem;
      text-align: center;
    }
  }

  .btns {
    width: 100%;
    height: 8.5rem;
    .confirm,
    .cancel {
      color: #fff;
      min-width: 20rem;
      height: 6.5rem;
      border-radius: 0.8rem;
      font-size: 2.4rem;
      padding: 0 2.5rem;
    }
    .cancel {
      background: #eaeaea;
      color: #666666;
      margin-right: 10rem;
    }
    .confirm {
      background: #1588f5;
    }
  }
}
.app-main {
  ::-webkit-scrollbar {
    display: none;
  }
  .left-menu {
    height: 100vh;
    width: 12rem;
    background: #333333;

    .logo-img {
      width: 5.6rem;
      height: 5.6rem;
      margin-top: 2rem;
      border-radius: 1rem;
    }

    .menu-list {
      margin-top: 2rem;
      height: calc(100% - 13rem);
      overflow-y: scroll;

      .menu-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 12rem;
        // margin-bottom: 2rem;

        .menu-icon {
          width: 3.17rem;
          height: 3.17rem;
          margin-bottom: 1rem;
        }

        .menu-title {
          font-size: 2.2rem;
          color: #fff;
          font-weight: 600;
        }
      }

      .menu-item:last-child {
        margin-bottom: 0;
      }

      .active-menu-item {
        background: #1588f5;
      }
    }
  }

  .top-bar {
    background: #fff;
    width: calc(100vw - 13.5rem);
    height: 8.5rem;
    position: fixed;
    top: 0;
    right: 0;
    left: 13.5rem;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .account-box {
      .logoimg {
        width: 5rem;
        height: 5rem;
        margin-right: 1.3rem;
        margin-left: 1.5rem;
      }
      .account-info {
        max-width: 25rem;
        text-align: left;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .top-bar-time {
      font-size: 2.6rem;
      font-weight: bold;
      flex: 1;
    }
    .top-bar-notify{
      font-size: 2.6rem;
      font-weight: bold;
      flex: 1;
    }

    .clear-notify {
      margin-left: 0.4rem; // 可选：添加一些间距
      border:1px solid #666666;
    }

    .top-bar-btns {
      display: flex;
      align-items: center;
      .t-b-b-btn {
        font-size: 2.4rem;
        font-weight: 400;
        color: #ffffff;
        border: 0;
        padding: 0 3rem;
        height: 6rem;
        border-radius: 0.8rem;
        margin-right: 2.5rem;
        display: flex;
        align-items: center;
      }
    }

    .ipimg {
      width: 4.5rem;
      height: 4.5rem;
    }
  }
}
</style>