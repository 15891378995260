import request from '@/request/http';

const cc = {
  // 登陆
  login: (data, dontLoad) => {
    return request({
      url: 'Login/login',
      data,
      method: 'post'
    }, dontLoad)
  },
  // 系统设置 页面显示
  systemShow: data => {
    return request({
      url: 'System/systemShow',
      data
    })
  },
  // 系统设置 修改
  systemAction: data => {
    return request({
      url: 'System/systemAction',
      data,
      method: 'post'
    })
  },
  // 财务管理 （账户余额信息）
  info: data => {
    return request({
      url: 'Wallet/info',
      data
    })
  },
  // 财务管理 提现记录
  walletLog: data => {
    return request({
      url: 'Wallet/walletLog',
      data
    })
  },
  // 财务管理 申请提现显示
  withdrawalShow: data => {
    return request({
      url: 'Wallet/withdrawalShow',
      data
    })
  },
  // 财务管理 申请提现操作
  withdrawalAction: data => {
    return request({
      url: 'Wallet/withdrawalAction',
      data,
      method: 'post'
    })
  },
  // 员工管理 页面显示
  stafListf: data => {
    return request({
      url: 'Staff/stafListf',
      data
    })
  },
  // 员工管理 获取门店权限列表
  stafRole: data => {
    return request({
      url: 'Staff/stafRole',
      data
    })
  },
  // 员工管理 交班记录
  StaffHandover: data => {
    return request({
      url: 'Staff/handover',
      data
    })
  },
  // 员工管理 添加/编辑/删除员工
  staffAction: data => {
    return request({
      url: 'Staff/staffAction',
      data,
      method: 'post'
    })
  },
  // 库存管理 出库明细
  stockOutBound: data => {
    return request({
      url: 'Stock/stockOutBound',
      data
    })
  },
  // 库存管理 入库明细（采购订单）
  stockStorage: data => {
    return request({
      url: 'Stock/stockStorage',
      data
    })
  },
  // 库存管理 采购下单
  purchasingOrder: data => {
    return request({
      url: 'Stock/stockStorage',
      data
    })
  },
  // 库存管理 采购订单详情
  stockStorageInfo: data => {
    return request({
      url: 'Stock/stockStorageInfo',
      data
    })
  },
  // 销售订单退货操作
  returnOrder: (data, dontLoad) => {
    return request({
      url: 'Order/returnOrder',
      data,
      method: 'post'
    }, dontLoad)
  },
  // 门店采购入库操作
  stockUp: data => {
    return request({
      url: 'Stock/stockUp',
      data,
      method: 'post'
    })
  },
  // 库存管理—采购下单
  stockPay: data => {
    return request({
      url: 'Stock/stockPay',
      data,
      method: 'post'
    })
  },
  // 生成条形码
  createBarCode: data => {
    return request({
      url: 'Good/createBarCode ',
      data,
      method: 'get'
    })
  },
  //登出打印数据
  Logout: data => {
    return request({
      url: 'Login/Logout ',
      data,
      method: 'post'
    })
  },
  //会员卡列表
  getMemberCardList: data => {
    return request({
      url: 'Member/getMemberCardList ',
      data,
      method: 'get'
    })
  },
  //会员列表
  getMemberList: data => {
    return request({
      url: 'Member/getMemberList',
      data,
      method: 'get'
    })
  },
  //会员详情
  getMember: data => {
    return request({
      url: 'Member/getMember',
      data,
      method: 'get'
    })
  },
   //会员禁用
   memberSetting: data => {
    return request({
      url: 'Member/memberSetting',
      data,
      method: 'post'
    })
  },
  //新增会员
  saveMember: data => {
    return request({
      url: 'Member/saveMember',
      data,
      method: 'post'
    })
  },
  //手机号搜索会员
  getMemberByPhone: data => {
    return request({
      url: 'Member/getMemberByPhone',
      data,
      method: 'get'
    })
  },
  //充值vip金额
  memberChargeRec: data => {
    return request({
      url: 'Member/memberChargeRec',
      data,
      method: 'post'
    })
  },
  //支付
  pay: data => {
    return request({
      url: 'member/pay',
      data,
      method: 'post'
    })
  },
  //余额调整
  memberBalanceChange: data => {
    return request({
      url: 'Member/memberBalanceChange',
      data,
      method: 'post'
    })
  },
  //会员记录
  memberRecord: data => {
    return request({
      url: 'Member/memberRecord',
      data,
      method: 'get'
    })
  },
  //获取系统信息
  getServerInfo: data => {
    return request({
      url: `getServerInfo`,
      data,
      method: 'get'
    })
  },
  //获取客服联系方式
  //手机号搜索会员
  // getMemberByPhone: data => {
  //   return request({
  //     url: 'Member/getMemberByPhone',
  //     data,
  //     method: 'get'
  //   })
  // },
}

export default cc
