import config from '../../config'
import store from '../store/index'
// console.log('dcserver')
const socket = io.connect(config.socket_url);
// const socket = io.connect("http://localhost:11937");
window.socket = socket


const getEnv = () => {
    let env = 'development'
    try {
        // console.log("%c 🍊 config", "color:#3f7cff", config);
        let arr = Object.keys(config)
        for (let i = 0; i < arr.length; i++) {
            let item = config[arr[i]]
            // console.log("%c 🥟 item", "color:#42b983", item);
            if (item.indexOf("demo") > -1) {
                env = 'development'
                return env
            }
        }
        env = 'production'
        return env
    } catch (err) {
        env = 'development'
        return env
    }
}
// console.log('socket', socket)
socket.on('connect', () => {
    // console.log('%c 🥘 connect: ', 'font-size:20px;background-color: #465975;color:#fff;');
    emitInfo()
});

socket.on('checkConnection',(type) => {
    // console.log("%c 🥃 checkConnection", "color:#ed9ec7",type);
    setTimeout(() => {
        if(type == 'requireInfo'){
            emitInfo()
        }else if(type == 'checkConnection'){
            socket.emit('checkConnection','alive')
        }else{
            // do nothing
        }
    },500)
})

const emitInfo = () => {
    let device = '未知设备'
    try {
        if (localStorage.nowDevice == 'android') {
            device = 'Android'
        } else if (localStorage.nowDevice == 'mt_android') {
            device = '美团'
        } else if (localStorage.nowDevice == 'ios') {
            device = 'IOS'
        } else {
            device = 'Windows'
        }
    } catch (err) {
        console.error('Socket set device name error')
    }
    let loginData = null
    try {
        loginData = JSON.parse(localStorage.getItem("dcServerData"))
        console.log("%c Line:66 🍯 loginData", "color:#b03734", loginData);
    } catch (err) {
        loginData = null
    }
    if (loginData) {
        console.log("%c Line:72 🥟 loginData", "color:#7f2b82", loginData);
        let str = {
            ...loginData,
            device: device,
            project_id: '店掌宝-会员收银',
            env: getEnv()
        }
        console.log("%c Line:74 🍬 str", "color:#2eafb0", str);

        emitSocket('formalLogin', socket, str)
    } else {
        let str = {
            // 测试
            uid: '未登录的用户',
            project_id: '店掌宝-会员收银',
            device: device,
            env: getEnv()
        };
        emitSocket('login', socket, str)
    }
    let interval = Math.floor(Math.random() * 60)
    // console.log(`socket emit will retry after ${interval} minutes`)
    setTimeout(() => {
        emitInfo()
    }, interval * 60 * 1000)
}

const emitSocket = (event, socket, obj) => {
    try {
        if (store.state.deviceMac) {
            // console.log('发现设备ID，开始EMIT')
            let emitData = JSON.parse(JSON.stringify(obj))
            emitData.deviceMac = store.state.deviceMac
            emitData.nowVersion = store.state.nowVersion || "0.0.0"
            // console.log('now emitting', `[${event}]`, emitData)
            socket.emit(event, emitData)
        } else {
            console.log('无设备ID，等待3s后重试')
            // store.commit('updateDeviceMac', 'liyi')
            setTimeout(() => {
                emitSocket(event, socket, obj)
            }, 3000)
        }
    } catch (err) {
        console.error(err)
    }
}

socket.on("disconnect", () => {
    // console.log(socket.connected); // false
});

export default socket;
