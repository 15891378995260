import { render, staticRenderFns } from "./setPrintType.vue?vue&type=template&id=45eb0106&scoped=true&"
import script from "./setPrintType.vue?vue&type=script&lang=js&"
export * from "./setPrintType.vue?vue&type=script&lang=js&"
import style0 from "./setPrintType.vue?vue&type=style&index=0&id=45eb0106&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45eb0106",
  null
  
)

export default component.exports