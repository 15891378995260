<template>
  <div class="con">
    <div class="right">
      <div class="tab-content">
        <div class="switch switch-card">
          <div class="flex flex-jsb">
            <div class="flex-1 mg-rt40">
              <div class="card-title">基础信息</div>
              <div class="switch-item switch-item-inline shopinfo-item">
                <div class="mg-rt60 color-c8c9cc">商户编号</div>
                <input v-model="shop.id" disabled class="shopinfo-input" />
              </div>
              <div class="switch-item switch-item-inline shopinfo-item">
                <div class="mg-rt60" :class="{'color-c8c9cc': shop.user_model == 0}">门店名称</div>
                <input v-model="shopName" :disabled="shop.user_model == 0" class="shopinfo-input" />
              </div>
              <div class="switch-item switch-item-inline shopinfo-item">
                <div class="mg-rt60" :class="{'color-c8c9cc': shop.user_model == 0}">联系电话</div>
                <input v-model="shopMobile" :disabled="shop.user_model == 0" class="shopinfo-input" />
              </div>
              <div class="switch-item switch-item-inline shopinfo-item">
                <div class="mg-rt60" :class="{'color-c8c9cc': shop.user_model == 0}">门店地址</div>
                <input v-model="shopAddress" :disabled="shop.user_model == 0" class="shopinfo-input" style="flex: 1;" maxlength="30" />
              </div>
              <div class="switch-item">
                <div class="mg-bt40">
                  <span class="mg-rt20" :class="{'color-c8c9cc': shop.user_model == 0}">经营时间</span>
                </div>
                <div class="flex flex-ac">
                  <input placeholder="请选择开始时间" class="timeinput" readonly @click="showPicker = true" v-model="start_time" :disabled="shop.user_model == 0" />
                  <span>&nbsp;&nbsp;至&nbsp;&nbsp;</span>
                  <input placeholder="请选择结束时间" class="timeinput" readonly @click="showPickerEnd = true" v-model="end_time" :disabled="shop.user_model == 0" />
                </div>
              </div>
              <van-popup v-model="showPicker" position="bottom" round class="popup-picker-custom">
                <van-datetime-picker v-model="start_time" type="time" title="选择时间" :min-hour="0" :max-hour="23" @confirm="onConfirm" @cancel="showPicker = false" />
              </van-popup>
              <van-popup v-model="showPickerEnd" position="bottom" round class="popup-picker-custom">
                <van-datetime-picker v-model="end_time" type="time" title="选择时间" :min-hour="0" :max-hour="23" @confirm="onConfirmEnd" @cancel="showPickerEnd = false" />
              </van-popup>

              <!-- <div class="switch-item">
                                <div class="mg-bt40">
                                    <span class="mg-rt20" :class="{'color-c8c9cc': shop.user_model == 0}">电子秤（选填）</span>
                                </div>
                                <div class="flex flex-ac">
                                    <van-radio-group v-model="scale_type" direction="horizontal" :disabled="shop.user_model == 0">
                                        <van-radio name="1" icon-size="3rem" class="scale-radio">和成东</van-radio>
                                        <van-radio name="2" icon-size="3rem" class="scale-radio">商米</van-radio>
                                        <van-radio name="3" icon-size="3rem" class="scale-radio">美团</van-radio>
                                        <van-radio name="4" icon-size="3rem" class="scale-radio">一敏</van-radio>
                                    </van-radio-group>
                                    <button class="clear-scale" @click="scale_type = '';" :disabled="shop.user_model == 0">清空选择</button>
                                </div>
                            </div> -->

              <!-- <div class="switch-item">
                                <div class="mg-bt40">
                                    <span class="mg-rt20" :class="{'color-c8c9cc': shop.user_model == 0}">钱箱连接方式（选填）</span>
                                </div>
                                <div class="flex flex-ac">
                                    <van-radio-group v-model="box_type" direction="horizontal" :disabled="shop.user_model == 0">
                                        <van-radio name="1" icon-size="3rem" class="scale-radio">和成东内置</van-radio>
                                        <van-radio name="2" icon-size="3rem" class="scale-radio">USB打印机</van-radio>
                                        <van-radio name="3" icon-size="3rem" class="scale-radio">美团内置</van-radio>
                                        <van-radio name="4" icon-size="3rem" class="scale-radio">蓝牙打印机</van-radio>
                                    </van-radio-group>
                                    <button class="clear-scale" @click="box_type = '';" :disabled="shop.user_model == 0">清空选择</button>
                                </div>
                            </div> -->

            </div>
            <div class="printCode" v-if="shop.is_separate == 2">
              <div class="ft-28 ft-bold mg-tp15 mg-bt30 text-center">门店码</div>
              <div class="codebox" id="deskCodeBox" ref="deskCodeBox">
                <div class="bg-fff qrcodebox">
                  <div class="color-000 ft-34 ft-bold text-center one-line-ellipsis" style="width: 42.5rem; height: 6.5rem; line-height: 6.5rem;">{{shop.name}}</div>
                  <div class="qrcode flex flex-column">
                    <div v-show="!isLoading" id="qrcode" ref="storeCodeBox" class="codeimg"></div>
                    <div v-show="isLoading" class="codeimg-loading">
                      <van-loading color="#FE724F" size="1rem" />
                    </div>
                  </div>
                </div>

                <div class="color-666 text-center ft-24">扫码注册会员</div>

                <div class="flex flex-ac flex-jc desk-code-bt-box">
                  <van-icon name="scan" color="#fff" size="4rem" />
                  <div class="color-fff mg-lt5">扫码注册会员，即刻享受会员权益</div>
                </div>
              </div>
              <div class="flex mg-tp30 flex-jc" style="width: 49rem;" v-if="store_code_url != ''">
                <button @click="generateImage" class="deskcode-btn bg-3DB956 mg-rt50">下载</button>
                <!-- <button class="deskcode-btn bg-1D5CFA" @click="openDialog">购买</button> -->
              </div>
            </div>
          </div>
          <div class="s-s-btns" v-if="shop.user_model == 1">
            <button class="s-s-btn bg-1588F5 color-fff" @click="setStoreInfo">保存</button>
          </div>
        </div>
      </div>
    </div>

    <popupOfDialog v-if="showDialog" :type="dialogType" :title="dialogTitle" :message="dialogMsg" :data="{}" :list="dialogList" :showCancelBtn="false" :showConfirmBtn="false" @closePopDialog="closePopDialog"></popupOfDialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { connectSunMiScaleService, getScaleData, getScaleDateHcd, getScaleDateSm, isScaleSupported, sunMiScaleDataInfo } from '../../utils/outPrint';
import config from '../../../config';
import popupOfDialog from '@/views/component/popupOfDialog.vue';
import QRCode from 'qrcodejs2';
import html2canvas from "html2canvas";

export default {
  name: "store-setting",
  components: {
    popupOfDialog,
  },
  data() {
    return {
      showPicker: false,
      showPickerEnd: false,
      start_time: "",
      end_time: "",
      table_fee: '',
      water_bill_auto_finish: false, // 结账后自动完成流水单
      self_pick_auto_finish: false, // 结账后自动完成自取单
      shopName: "",
      shopMobile: "",
      shopAddress: "",
      scale_type: '',
      box_type: '',
      store_code_url: '',
      isLoading: true,
      qrcode_: '',
      showDialog: false,
      dialogType: '',
      dialogTitle: '',
      dialogMsg: '',
      dialogList: [],
    }
  },
  computed: {
    ...mapGetters({ shop: 'shop' }),
    platform_name() {
      return localStorage.nowDevice;
    },
  },
  methods: {
    // 选择开始时间
    onConfirm(data) {
      this.start_time = data;
      this.showPicker = false;
    },
    // 选择结束时间
    onConfirmEnd(data) {
      this.end_time = data;
      this.showPickerEnd = false;
    },
    setStoreInfo() {
      if (this.start_time == "" && this.end_time == "") {
        this.$toast("请选择经营时间");
        return;
      }
      if (this.start_time == "" && this.end_time != "") {
        this.$toast("请选择开始时间");
        return;
      }
      if (this.start_time != "" && this.end_time == "") {
        this.$toast("请选择结束时间");
        return;
      }
      let query = {
        name: this.shopName,
        tel: this.shopMobile,
        xq: this.shopAddress,
        workTimeS: this.start_time,
        workTimeE: this.end_time,
        scale_type: this.scale_type,
        box_type: this.box_type,
      }
      this.$api.dt_updateShopInfo(query).then(async (res) => {
        if (res.code == 1) {
          this.$toast("保存成功，门店设置已更新");
          this.getData();
          // localStorage.setItem('box_type', this.box_type);
          // if ((!localStorage.scale_type || localStorage.scale_type != this.scale_type) && this.platform_name == 'android') {
          //     if (this.scale_type == '1') {
          //         getScaleDateHcd();
          //     } else if (this.scale_type == '4') {
          //         getScaleData();
          //     } else if (this.scale_type == '3') {
          //         setTimeout(async () => {
          //             const scaleSupported = await isScaleSupported();
          //             this.$store.commit('updateMtScale', scaleSupported);
          //             console.log('scaleSupported', scaleSupported);
          //             console.log('this.$store.state.mtScale', this.$store.state.mtScale);
          //         }, 1500);
          //     } else if (this.scale_type == '2') {
          //         await connectSunMiScaleService();
          //         setTimeout(() => {
          //             sunMiScaleDataInfo();
          //         }, 500);
          //         getScaleDateSm();
          //     }
          // }
          // localStorage.setItem('scale_type', this.scale_type);
        } else {
          this.$toast(res.msg || "修改门店信息失败");
        }
      });
    },
    getData() {
      this.$api.dt_getShopInfo().then((res) => {
        if (res.code == 1) {
          // user_model:1，表示是店长模式 0，表示为普通模式
          // this.$store.commit("setShopInfo", res.data);
          this.$store.dispatch("setShopInfo", res.data);
          this.start_time = this.shop.workTimeS;
          this.end_time = this.shop.workTimeE;
          this.shopName = this.shop.name;
          this.shopMobile = this.shop.tel;
          this.shopAddress = `${this.shop.xq || ''}`;
          this.scale_type = this.shop.scale_type.toString() || '';
          this.box_type = this.shop.box_type.toString() || '';
          // is_separate：判断是否入驻，独立 还是未开通入驻，默认 0未开通 1独立版本，2 入驻版本
          if (this.shop.is_separate == 2) {
            this.$nextTick(() => {
              this.drawStoreCode();
            }, 100);
          }
        }
      });
    },
    generateImage() {
      var that = this;
      if (this.store_code_url == '') {
        this.$toast('门店码获取失败');
        return;
      }
      let element = this.$refs.deskCodeBox;
      html2canvas(element, {
        scale: 8,
        allowTaint: true,
        useCORS: true,
        tainttest: true, // 检测每张图片都已经加载完成
        logging: true,
        backgroundColor: null, // 转换图片可能会有白色底色，你可根据你的页面或者PM要求设置一下背景色，不要的话就null
      }).then((canvas) => {
        //  转成图片，生成图片地址
        this.hrefUrl = canvas.toDataURL("image/png");
        this.downloadDesk();
      });
    },
    // 下载桌码图片
    downloadDesk() {
      const that = this;
      if (this.platform_name == 'windows') {
        let a = document.createElement('a')
        a.setAttribute('target', '_blank');
        a.href = this.hrefUrl;
        a.download = this.shop.name;
        a.click();
        this.$toast('下载成功');
      } else if (this.platform_name == 'android' || this.platform_name == 'ios' || this.platform_name == 'mt_android') {
        // 初始化FileTransfer对象
        var fileTransfer = new FileTransfer();
        // console.log('cordova.file', JSON.stringify(cordova.file));
        var fileURL = cordova.file.externalApplicationStorageDirectory + this.shop.name + '.png';
        // 调用download方法
        fileTransfer.download(
          this.hrefUrl,         //uri网络下载路径
          fileURL,      //url本地存储路径
          function (entry) {
            // console.log("download complete: ", entry);
            that.$toast({ message: `下载成功，请在\n【图库】或【${fileURL}】中查看`, duration: 6000 });
          },
          function (error) {
            // console.log("download error source ", error.source);
            // console.log("download error target ", error.target);
            // console.log("upload error code", error.code);
          }
        );
      }
    },
    drawStoreCode() {
      document.getElementById("qrcode").innerHTML = "";
      if (this.qrcode) {
        this.qrcode_.clear();
      }
      // this.isLoading = true;
      // is_separate：判断是否入驻，独立 还是未开通入驻，默认 0未开通 1独立版本，2 入驻版本
      if (this.shop.is_separate == 2) {
        this.store_code_url = `${config.siteUrl}?par=${this.shop.id}`;
      }
      let imgWidth = this.fontSize ? parseFloat(this.fontSize).toFixed(2) * 100 * 2104 / 10000 : 1280; // 263 * 8
      this.qrcode_ = new QRCode(this.$refs.storeCodeBox, {
        text: this.store_code_url, // 需要转换为二维码的内容
        width: imgWidth,
        height: imgWidth,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
        ecLevel: 'H',
      })
      setTimeout(() => {
        this.isLoading = false;
      }, 300);
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    openDialog() {
      this.dialogTitle = '购买桌贴';
      this.dialogType = '1';
      this.dialogMsg = '';
      this.dialogData = {};
      this.dialogList = [
        {
          title: 'PVC桌贴',
          msgs: [
            '请扫描下方二维码购买桌贴',
            '价格：5元/张，根据门店桌位数量按需购买',
            '使用方法：贴附在餐桌表面即可',
          ],
          image: [
            require('@/static/images/table-code.png'),
            require('@/static/images/buy-tablecode.png'),
          ],
        },
        {
          title: '亚克力桌贴',
          msgs: [
            '请扫描下方二维码购买桌贴',
            '价格：10元/张，根据门店桌位数量按需购买',
            '使用方法：贴附在餐桌表面即可',
          ],
          image: [
            require('@/static/images/table-code.png'),
            require('@/static/images/buy-tablecode.png'),
          ],
        },
        {
          title: '立式桌牌',
          msgs: [
            '请扫描下方二维码购买桌牌',
            '价格：35元/张，根据门店桌位数量按需购买',
            '使用方法：附带木制底托，放置于餐桌上即可',
          ],
          image: [
            require('@/static/images/table-card.png'),
            require('@/static/images/buy-tablecode.png'),
          ],
        }
      ]
      this.showCancelBtn = false;
      this.showConfirmBtn = false;
      this.showDialog = true;
    },
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  padding: 3rem 0;
  height: calc(100vh - 12.5rem);
  overflow-y: scroll;
}

.s-s-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  height: 10rem;
  border-top: 0.1rem solid #eeeeee;
  .s-s-btn {
    width: 43rem;
    height: 7rem;
    border-radius: 0.8rem;
    font-size: 2.4rem;
  }
}
.shopinfo-item {
  padding-top: 2.2rem !important;
  padding-bottom: 2.2rem !important;
  justify-content: flex-start !important;
  .shopinfo-input {
    width: 50%;
    height: 6rem;
    background: #fff;
  }
  .shopinfo-input:disabled {
    color: #c8c9cc;
  }
}
.switch-item-inline {
  flex-direction: row !important;
  align-items: center !important;
}

.timeinput {
  height: 7rem;
  border: 0.15rem #dedede solid;
  border-radius: 0.8rem;
  font-size: 2.4rem;
  text-align: center;
  width: 24rem;
}
.mg-rt60 {
  margin-right: 6rem !important;
}

.con {
  text-align: left;
}

.right {
  background-color: #fff;
  position: relative;

  .switch {
    .switch-item {
      font-size: 2.4rem;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 2rem 3rem;
      border-bottom: 0.1rem solid #eeeeee;

      .scale-radio {
        margin-right: 3rem;
      }
      .clear-scale {
        font-size: 2.1rem;
        color: #ffffff;
        border: 0;
        padding: 0 1.6rem;
        height: 5rem;
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        background: #1588f5;
      }
    }

    .switch-item:last-child {
      border-bottom: none !important;
    }
  }

  .switch-card {
    box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.5);
    height: auto;
    padding-bottom: 0;
    margin: 0 3rem 3rem;
    border-radius: 0.8rem;

    .card-title {
      font-size: 2.6rem;
      font-weight: bold;
      padding: 3.5rem 3rem 2rem;
    }
  }
}

@qrwdith: 26rem;
.printCode {
  width: 46rem;
  padding: 2rem 2rem;
  .codebox {
    background: #fff;
    box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.5);
    border-radius: 0.8rem;
    overflow: hidden;
    width: 46rem;
    height: 55.5rem;
    .qrcodebox {
      padding: 2rem 1rem 2.5rem 1rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .codeimg-loading {
      width: @qrwdith;
      height: @qrwdith;
      background: #fff;
      // padding: 0.15rem;
      border-radius: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
    .codeimg {
      background: #fff;
      // padding: 0.15rem;
      border-radius: 0.8rem;
      width: fit-content;
      margin: 0 auto;
      width: 26rem;
      height: 26rem;
    }
    .qrcode {
      margin-top: 2.5rem;
      display: flex;
      justify-content: center;
      width: 26rem;
      height: 26rem;
    }

    .desk-code-bt-box {
      background: #1588f5;
      flex-wrap: nowrap;
      padding: 3rem 0 4.1rem;
      margin-top: 2.5rem;
      font-size: 2.4rem;
    }
  }
  .deskcode-btn {
    display: block;
    font-size: 2.2rem;
    border-radius: 0.8rem;
    width: 15rem;
    height: 5.5rem;
    color: #fff;
  }
  #qrcode {
    width: @qrwdith !important;
    height: @qrwdith !important;
    /deep/canvas,
    /deep/img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
</style>
