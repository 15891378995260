const config = {
  // 开发
  baseUrl: 'https://cashier.demo.api.dianzhangbao.com.cn/api/',
  siteUrl: 'https://cashier.demo.api.dianzhangbao.com.cn/',

  // 灰度
  // baseUrl: 'https://cashier.s.dianzhangbao.com.cn/api/',
  // siteUrl: 'https://cashier.s.dianzhangbao.com.cn/',

  // 正式
  // baseUrl: 'https://cashier.api.dianzhangbao.com.cn/api/',
  // siteUrl: 'https://cashier.api.dianzhangbao.com.cn/',

  netUrl: 'http://localhost:3001/',

  socket_url: 'https://dcserver.dianzhangbao.com.cn/', // 正式版新域名
}

export default config
