<!-- 新增调货 -->
<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <titlebar :name="name" />
    <div class="top_box">
      <div class="top_input">
        <van-icon name="search" />
        <input type="text" placeholder="输入商品名称或条码" v-model="param" />
      </div>
      <van-button class="top_btn" @click="getGoodsList()"> 搜索 </van-button>
    </div>
    <!-- 分类列表 -->
    <div class="tab_list" :class="{ index_hight: categoryList.length > 8 }">
      <van-button v-for="(item, index) in categoryList" :key="index" class="tab_btn" :class="{ choose: categoryId == item.id }" @click="chooseClassify(item)">
        {{ item.name }}
      </van-button>
    </div>
    <!-- 商品列表 -->
    <div class="index_list">
      <div v-for="(item, i) in goodsList" :key="i" @click="choosePro(item)" class="index_box" :class="{
          'isadd-icon': item.is_add == 1 && name == 'addPro',
        }">
        <img :src="item.img" class="index_img" />
        <div>
          <div class="goodsName">{{ item.name }}</div>
          <div class="goodsstock">库存：{{ item.stock }}{{ item.dw }}</div>
          <div class="goodsPrc">原价：¥{{ item.fact_price }}</div>
          <div class="goodsPrc">会员价：¥{{ item.member_price }}</div>
        </div>
        <div class="icon-con">
          <img src="../../static/online.png" class="icon" v-if="item.online === 1" />
          <img src="../../static/kucun.png" class="icon" v-if="item.dw == 'kg'" />
        </div>
      </div>
    </div>

    <van-popup v-model="popupShow" @closed="closePopup">
      <div class="popup">
        <div class="popup-title">
          <div>调货信息</div>
          <img src="https://cashier.demo.api.dianzhangbao.com.cn/202106/mdsy/close.png" @click="popupShow=false" />
        </div>
        <div class="popup-main">
          <div class="item-box">
            <div class="item-title">调货商品：</div>
            <div class="item-text">{{goodsInfo.name}}</div>
          </div>
          <div class="item-box" style="margin-top:5rem;">
            <div class="item-title">库存：</div>
            <div class="item-text">{{goodsInfo.stock}}</div>
          </div>
          <div class="item-box" style="margin-top:5rem;">
            <div class="item-title">调货数量：</div>
            <input type="number" class="num-input item-text" :max="goodsInfo.stock" v-model="info.num" />
            <div class="item-text">/{{goodsInfo.dw}}</div>
            <div class="item-text">（调货数量不能大于库存）</div>
          </div>
          <div class="item-box" style="margin-top:5rem;">
            <div class="item-title">调货店铺：</div>
            <div @click="showPicker=true" class="shop-name">{{shopName}}</div>
          </div>
          <van-button style="margin-top:8rem;" color="#1588F5" @click="handleSub">确定</van-button>
        </div>
      </div>
    </van-popup>

    <!--调货店铺-->
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker show-toolbar :default-index="pickerIndex" value-key="name" :columns="shopList" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import titlebar from "@/views/component/titlebar";
export default {
  name: "addStockTransfer",
  components: {
    titlebar,
  },
  data() {
    return {
      shopName: "点击选择店铺",
      shopList: [],
      showPicker: false,
      pickerIndex: 0,
      chooseIndex: "", //选中的分类
      goodsList: [], // 商品列表
      categoryList: [], // 商品分类列表
      categoryId: "", // 选择的商品分类ID
      param: "", // 商品名称或条码
      name: "",
      lastCategoryId: undefined, // 从商品详情回来储存的分类ID
      goodsInfo: "",
      popupShow: false,
      info: {
        id: undefined,
        num: 1,
        out_id: undefined
      }
    }
  },
  methods: {
    // 关闭调货弹框
    closePopup() {
      this.info = {
        id: undefined,
        num: 1,
        out_id: undefined
      }
      this.shopName = "点击选择店铺"
    },
    // picker确认
    onConfirm(e) {
      this.info.out_id = e.id;
      this.shopName = e.name;
      this.showPicker = false;
    },
    // 拉取商品分类列表
    getCategoryList() {
      return new Promise((resolve, reject) => {
        this.$api
          .goodsType({
            shop_id:
              this.name == "addPro" ? 0 : localStorage.getItem("shop_id"),
            pageSize: 999,
            page: 1,
          })
          .then((res) => {
            if (res.code == 1) {
              this.categoryList = res.data.list || [];
              if (this.lastCategoryId) {
                this.categoryId = this.lastCategoryId
              } else {
                this.categoryId = res.data.list.length > 0 ? res.data.list[0].id : "";
              }
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            console.log("首页拉取商品分类 error：", error);
            reject();
          });
      });
    },
    // 拉取商品列表
    getGoodsList() {
      let query = {
        // shop_id: localStorage.getItem('shop_id'), // 门店ID
        shop_id: localStorage.getItem("shop_id"), // 门店ID
        c_id: this.categoryId, // 分类ID -1获取平台全部商品 0没有分类id则是获取全部门店商品 大于0是获取指定分类下面的商品
        // c_id: -1,
        param: this.param,
        page: 1,
        limit: 200
      };
      this.$api.goodsList(query).then((res) => {
        this.goodsList = res.data.list;
      });
    },
    // 店铺列表
    getShopList() {
      this.$api.getShopList().then((res) => {
        this.shopList = res.data.list;
      });
    },
    // 跳转至新增商品
    choosePro(item) {
      console.log("%c Line:113 🥟 item", "color:#33a5ff", item);
      this.goodsInfo = item
      this.info.id = item.id
      this.popupShow = true
    },
    // 切换分类
    chooseClassify(item) {
      this.categoryId = item.id;
      this.getGoodsList();
    },
    // 确认调货
    handleSub() {
      if (this.info.num * 1 <= 0 || this.info.num > this.goodsInfo.stock) {
        Toast({
          message: '调货数量异常',
          position: 'top',
        });
        return
      }
      if (!this.info.out_id) {
        Toast({
          message: '请选择调货店铺',
          position: 'top',
        });
        return
      }
      this.$api.addStockTransfer(this.info).then((res) => {
        if (res.code == 1) {
          Toast({
            message: '调货成功',
            position: 'top',
          });
          this.popupShow = false
          this.getGoodsList();
        }
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.query.categoryId) {
        vm._data.lastCategoryId = from.query.categoryId
      }
    })
  },
  //生命周期 - 创建完成（访问当前this实例）
  async created() {
    this.name = this.$route.query.name || ""; //拿到传过来的标题
    await this.getCategoryList();
    this.getGoodsList();
    this.getShopList();
  },
}
</script>
<style lang="less" scoped>
.page {
  .top_box {
    padding: 4rem;
    display: flex;
    // justify-content: space-between;
    align-items: center;

    .top_input {
      width: 58.3rem;
      height: 8rem;
      background: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.4rem;
      font-weight: 400;
      color: #999999;

      input {
        background-color: #f5f5f5;
        margin-left: 2rem;
      }
    }

    .top_btn {
      width: 20.1rem;
      height: 6.8rem;
      background: #1588f5;
      font-size: 2.6rem;
      font-weight: 400;
      color: #ffffff;
      margin-left: 2rem;
    }
  }

  .tab_list {
    margin: 0 4rem;
    width: 184.7rem;
    background-color: #1588f5;
    display: grid;
    grid-template-columns: repeat(8, 12.5%);
    height: 10rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tab_btn {
      height: 10rem;
      background-color: #1588f5;
      border: none;
      border-radius: 0;
      font-size: 2.6rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 3.7rem;
      border: 0.1rem solid #fff;
    }

    .choose {
      background-color: #fff;
      color: #1588f5;
    }
  }

  .index_hight {
    height: 20rem !important;
  }

  .index_list {
    width: 184.7rem;
    // height: 50rem;
    flex: 1;
    padding-left: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(5, 19.5%);
    // grid-template-rows: repeat(auto-fill, 12rem);
    grid-template-rows: repeat(auto-fill, 16rem);
    grid-gap: 1.5rem 2rem;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    .index_box {
      position: relative;
      width: 31.9rem;
      // height: 12rem;
      height: 16rem;
      background: #ffffff;
      box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
      display: flex;
      align-items: center;
      text-align: left;
      position: relative;

      .index_img {
        min-width: 12rem;
        min-height: 12rem;
        max-width: 12rem;
        max-height: 12rem;
        margin-right: 3rem;
      }

      .goodsName {
        width: 15rem;
        font-size: 2rem;
        font-weight: bold;
        color: #333333;
        line-height: 3rem;
        margin-bottom: 0.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .goodsstock {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 0.3rem;
        //   color: #1588f5;
        line-height: 3rem;
      }
      .goodsPrc {
        font-size: 1.5rem;
        font-weight: 500;
        color: #1588f5;
        line-height: 3rem;
      }
      .icon-con {
        position: absolute;
        bottom: 0;
        right: 0;
        .icon {
          width: 3rem;
          margin-left: 0.4rem;
          /* height: 2rem; */
        }
      }
      .del-checkbox {
        position: absolute;
        left: 1rem;
        top: 1rem;
        background: #fff;
      }
    }

    .isadd-icon {
      background: rgba(245, 176, 135, 0.2) !important;
    }

    .addGoods {
      width: 31.9rem;
      height: 12rem;
      background: #ffffff;
      box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
      margin-bottom: 1.9rem;
      text-align: center;
      font-size: 2.6rem;
      font-weight: 400;
      color: #333333;
      line-height: 12rem;
    }

    .ft_30 {
      font-size: 3rem;
    }
  }

  .popup {
    width: 100rem;
    // height: 50rem;
    .popup-title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 4.6rem 3.1rem 4.6rem;
      height: 12.5rem;
      border-bottom: 0.1rem solid #e5e5e5;
      font-size: 3.6rem;
      font-weight: 400;
      color: #333333;
      img {
        width: 4.3rem;
        height: 4.3rem;
        margin-left: 29.4rem;
      }
    }
    .item-box {
      display: flex;
      align-items: center;
    }
    .item-title {
      width: 20rem;
      text-align: right;
      font-size: 3rem;
      font-weight: bold;
    }
    .item-text {
      color: #101010;
      font-size: 3rem;
    }
    .num-input {
      width: 16rem;
      padding: 2rem;
      background: #f4f4f4;
      font-weight: normal;
    }
    .popup-main {
      padding: 0 5rem 5rem 5rem;
    }
    .shop-name {
      width: 50rem;
      height: 8rem;
      padding: 0 2rem;
      line-height: 8rem;
      font-size: 3rem;
      background: #f4f4f4;
      color: #544d4d;
      text-align: left;
    }
  }
}
</style>
