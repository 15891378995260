<template>
  <div class="page">
    <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBack" />
    <div style="width: 100%; height: 1.5rem; background: #f4f4f4;"></div>
    <div class="type-head">
      <div class="flex" style="width: 100%">
        <div
          v-for="i in tab_list"
          :key="i.id"
          @click="toogle(i)"
          :class="{ 'ac': tab_id == i.id }"
          class="ab"
        >
          {{ i.name }}
        </div>
      </div>
    </div>
    <!-- 优惠券模板 开始 -->
    <div class="coupon-list" v-if="tab_id == 1">
      <div class="index_list">
        <div class="index_box addpro-index-box" @click="jumptoDetail(1)">
          <van-icon name="plus" size="4.6rem" class="mg-rt25" color="#333" />
          新增优惠券
        </div>
        <div v-for="(item, i) in coupon_list" :key="i" class="index_box" @click="jumptoDetail(2, item)" :class="{'expired-box': item.is_expire == 1,}">
          <img v-if="item.is_expire == 1" class="coupon-tag-img" src="@/static/images/coupon-expired.png" />
          <div class="coupon-left">
            <div class="color-1588F5 mg-bt15">
              <span class="mg-rt5 ft-40 ft-bold">{{ item.face }}</span>
              <span class="ft-22" v-if="item.type == 1" style="vertical-align: super;">元</span>
              <span class="ft-22" v-if="item.type == 2" style="vertical-align: super;">折</span>
            </div>
            <div class="ft-20 color-333">满{{ item.cill }}可用</div>
          </div>
          <div style="flex: 1;" class="pd-lt20 pd-rt5">
            <div class="ft-22 color-333 mg-bt25">{{ item.name }}</div>
            <div v-if="item.period == 1" class="ft-20 color-666">领券后{{ item.day }}天内有效</div>
            <div v-if="item.period == 2" class="ft-20 color-666">有效期：{{ item.start_time }} 至 {{ item.end_time }}</div>
            <div v-if="item.period == 3" class="ft-20 color-666">有效期：长期有效</div>
          </div>
          <van-icon name="edit" size="3.2rem" />
        </div>
      </div>
      <div class="flex mg-tp40 mg-bt30 mg-rt20" style="justify-content:flex-end;">
        <div class="pagination" v-if="count > 0">
          <van-pagination v-model="page" :total-items="count" :items-per-page="limit" mode="simple" force-ellipses
            @change="getCouponList()" style="background-color: #f2f2f2" />
        </div>
      </div>
    </div>
    <!-- 优惠券模板 结束 -->
    <!-- 发放优惠券 开始 -->
    <div class="tab-content" v-if="tab_id == 2">
      <div class="tab-c-item">
        <div class="card-item-line">
          <div class="mg-rt60">
            <span class="mg-rt20">发放模式</span>
          </div>
          <van-radio-group v-model="send_model" direction="horizontal">
            <van-radio name="1" icon-size="3.2rem">单个发放</van-radio>
            <van-radio name="2" icon-size="3.2rem">批量发放</van-radio>
          </van-radio-group>
        </div>
        <div class="card-item-line" v-if="send_model == 1">
          <div class="mg-rt60">
            <span class="mg-rt20">会员信息</span>
          </div>
          <button class="ff-chaoose-btn" @click="openMemModal">选择会员</button>
          <div v-if="Object.keys(choose_mem).length">
            <span class="mg-lt40">{{ choose_mem.name }}</span>
            <span class="mg-lt25">{{ choose_mem.phone }}</span>
          </div>
        </div>
        <div class="card-item-line" v-if="send_model == 2">
          <div class="mg-rt60">
            <span class="mg-rt20">会员信息</span>
          </div>
          <van-radio-group v-model="send_type" direction="horizontal">
            <van-radio name="1" icon-size="3.2rem">全部会员</van-radio>
            <van-radio name="2" icon-size="3.2rem">按会员等级</van-radio>
            <van-radio name="3" icon-size="3.2rem">按会员生日</van-radio>
          </van-radio-group>
        </div>
        <div class="card-item-line" v-if="send_type == 2">
          <div class="mg-rt60">
            <span class="mg-rt20">会员等级</span>
          </div>
          <van-checkbox-group v-model="mem_types" direction="horizontal">
            <van-checkbox icon-size="3.2rem" shape="square" v-for="item in member_type_list" :name="item.id" :key="item.id">{{ item.type_name }}</van-checkbox>
          </van-checkbox-group>
        </div>
        <div class="card-item-line" v-if="send_type == 3">
          <div class="mg-rt60">
            <span class="mg-rt20">会员生日</span>
          </div>
          <div class="flex flex-ac">
            <van-radio-group v-model="birth_type" direction="horizontal">
              <van-radio name="1" icon-size="3.2rem">选择日期</van-radio>
              <van-radio name="2" icon-size="3.2rem">选择月份</van-radio>
            </van-radio-group>
            <input v-if="birth_type == 1" v-model="birth" class="face-price-input mg-lt30" readonly placeholder="选择日期" style="width: 20rem;" @click="showCalendarBirth = true;" />
            <input v-if="birth_type == 2" v-model="birth" class="face-price-input mg-lt30" readonly placeholder="选择月份" style="width: 20rem;" @click="showCalendarBirthMonth = true;" />
          </div>
        </div>
        <div class="card-item-line">
          <div class="mg-rt60">
            <span class="mg-rt20">优惠券&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </div>
          <button class="ff-chaoose-btn" @click="openCouponModal">选择优惠券</button>
        </div>
        <ul v-if="choosed_coupon.length > 0">
          <li class="c-c-head">
            <div class="color-333 ft-22 text-center ft-bold">优惠券名称</div>
            <div class="color-333 ft-22 text-center ft-bold">数量</div>
            <div class="color-333 ft-22 text-center ft-bold">操作</div>
          </li>
          <li class="c-c-body" v-for="(item, index) in choosed_coupon">
            <div class="color-333 ft-22">{{ item.name }}</div>
            <div class="color-333 ft-22">
              <input v-if="platform_name == 'android'" class="stock-input" v-model="item.num" placeholder="请输入" readonly @click="openKeyboard(item, index)" />
              <input v-else class="stock-input" v-model="item.num" placeholder="请输入" type="number" />
            </div>
            <div><van-icon name="delete-o" color="#ee0a24" size="3.2rem" @click.stop="deleteThatCoupon(item, index)" /></div>
          </li>
        </ul>
      </div>
      <div class="s-s-btns">
        <button class="s-s-btn bg-1588F5 color-fff" @click="sendCoupon()">确认发放</button>
      </div>
    </div>
    <!-- 发放优惠券 结束 -->
    <div class="search-value" v-if="tab_id == 3">
      <div class="flex flex-ac search-item">
        <div class="flex flex-jsc dimension">
          <div v-for="(item, index) in dimensionDate" :key="index" :class="[
            'dimensiondate',
            date_id == index ? 'dimensiondate-check' : '',
          ]" @click="chooseType(item, index)">
            <span> {{ item }}</span>
          </div>
        </div>
        <input v-model="start_time" class="ordernum-input search-date-input" readonly placeholder="选择时间"
          @click="showCalendarStart = true" style="margin: 0 0 0 2rem" />
        <span class="ft-24">&nbsp;&nbsp;至&nbsp;&nbsp;</span>
        <input v-model="end_time" class="ordernum-input search-date-input" placeholder="选择时间" readonly
          @click="showCalendarEnd = true" />
      </div>
      <van-popup v-model="showCalendarStart" round position="bottom" class="popup-picker-custom">
        <van-datetime-picker
          v-model="defaultDate[0]"
          :min-date="minDate"
          :max-date="maxDate"
          type="datetime"
          @confirm="onConfirmTime($event, 1)"
          @cancel="showCalendarStart = false"
          item-height="8.5rem"
          :formatter="formatter"
        />
      </van-popup>
      <van-popup v-model="showCalendarEnd" round position="bottom" class="popup-picker-custom">
        <van-datetime-picker
          v-model="defaultDate[1]"
          :min-date="minDate"
          :max-date="maxDate"
          type="datetime"
          @confirm="onConfirmTime($event, 2)"
          @cancel="showCalendarEnd = false"
          item-height="8.5rem"
          :formatter="formatter"
        />
      </van-popup>

      <button class="search-btn mg-rt20" @click="handleFifler">筛选</button>
      <button class="search-btn" @click="resetTemp">重置</button>
    </div>
    <template v-if="tab_id == 3">
      <div class="checklog-box">
        <div class="revenue-title flex flex-jsb">
          <div class="flex" style="align-items: center">
            <div class="ft-bold">时间：{{ start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{ end_time }}</div>
          </div>
        </div>

        <table width="100%" style="border-collapse: collapse; margin-top: 2.5rem" border="0" cellspacing="0"
          cellpadding="0">
          <thead>
            <tr bgcolor="#F2F2F2" class="thead-tr" style="height: 7.5rem;">
              <td class="color-333 ft-22 text-center ft-bold">发放时间</td>
              <td class="color-333 ft-22 text-center ft-bold">优惠券编码</td>
              <td class="color-333 ft-22 text-center ft-bold">优惠券名称</td>
              <td class="color-333 ft-22 text-center ft-bold">会员手机号</td>
              <td class="color-333 ft-22 text-center ft-bold">状态</td>
            </tr>
          </thead>
          <tbody>
            <tr class="color-333 ft-22 text-center tbody-tr" v-for="(item, index) in send_log"
              :key="index">
              <td>{{ item.create_time }}</td>
              <td>{{ item.coupon_code }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.mobile }}</td>
              <td>{{ item.status | CouponStatus }}</td>
            </tr>
            <div class="mg-tp30 content" v-if="send_log.length === 0">
              <div class="nullStyle">
                <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
              </div>
            </div>
          </tbody>
        </table>
        <div class="flex mg-tp40" style="justify-content:flex-end;">
          <div class="pagination" v-if="count > 0">
            <van-pagination v-model="page" :total-items="count" :items-per-page="limit" mode="simple" force-ellipses
              @change="getSendLog" style="background-color: #f2f2f2" />
          </div>
        </div>
      </div>
    </template>

    <!-- 选择优惠券 开始 -->
    <div class="choose-coupon-modal" v-if="show_choose_coupon">
      <div class="modal-content-box relative" style="z-index: 2002;">
        <img src="@/static/images/close.png" class="close absolute" alt srcset @click="closeCouponModal" />

        <div class="wrapper">
          <!-- 标题 -->
          <div class="title color-333 ft-28 flex flex-ac flex-jc ft-bold' pd-bt25">选择优惠券</div>

          <div class="mocontent">
            <div class="deskcontent">
              <div class="index_list">
                <div v-for="(item, i) in modal_coupon_list" :key="i" class="index_box relative" @click="chooseThatCoupon(item)">
                  <van-checkbox class="del-checkbox" icon-size="2.6rem" v-if="tab_id == 2" :value="item.delChoosed" shape="square" />
                  <div class="coupon-left">
                    <div class="color-1588F5 mg-bt15">
                      <span class="mg-rt5 ft-38 ft-bold">{{ item.face }}</span>
                      <span class="ft-22" v-if="item.type == 1" style="vertical-align: super;">元</span>
                      <span class="ft-22" v-if="item.type == 2" style="vertical-align: super;">折</span>
                    </div>
                    <div class="ft-20 color-333">满{{ item.cill }}可用</div>
                  </div>
                  <div style="flex: 1;" class="pd-lt20 pd-rt5">
                    <div class="ft-22 color-333 mg-bt25">{{ item.name }}</div>
                    <div v-if="item.period == 1" class="ft-20 color-666">领券后{{ item.day }}天内有效</div>
                    <div v-if="item.period == 2" class="ft-20 color-666">有效期：{{ item.start_time }} 至 {{ item.end_time }}</div>
                    <div v-if="item.period == 3" class="ft-20 color-666">有效期：长期有效</div>
                  </div>
                </div>
              </div>
              <div class="flex mg-tp10 mg-bt10 mg-rt20" style="justify-content:flex-end;">
                <div class="pagination" v-if="m_count > 0">
                  <van-pagination v-model="m_page" :total-items="m_count" :items-per-page="m_limit" mode="simple" force-ellipses
                    @change="handleChangeOfModal" style="background-color: #f2f2f2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 9rem;"></div>
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeCouponModal">取消</button>
          <button class="confirm " @click="confirmCouponModal()">确认</button>
        </div>
      </div>
    </div>
    <!-- 选择优惠券 结束 -->

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      :showCancelBtn="false"
      :showConfirmBtn="false"
      @closePopDialog="closePopDialog"
    ></popupOfDialog>

    <van-popup v-model="show_vip_modal" :close-on-click-overlay="false" @closed="vipInfoShow = []; vipTel = '';" :round="true">
      <div class="vip-modal">
        <div class="vip-modal-head">
          <div class="vip-modal-head-title">会员选择</div>
          <img src="https://cashier.demo.api.dianzhangbao.com.cn/202106/mdsy/close.png" @click="show_vip_modal = false" />
        </div>
        <div class="vip-modal-body">
          <div class="vip-modal-body-input">
            <input v-model="vipTel" placeholder="手机号或姓名搜索" ref="membercodeinput" @keyup.enter="vipTelSearch" />
            <van-button color="#1588f5" @click="vipTelSearch">搜索</van-button>
          </div>
          <div class="vip-modal-body-info" v-if="vipInfoShow.length > 0">
            <div v-for="(item, index) in vipInfoShow" class="vipinformation flex flex-jsb flex-ac"
              :class="{'choosed1': choose_index == index,}"
              @click="confirmMember(index)"
            >
              <div>
                <div class="mg-bt15">{{ item.name }}</div>
                <div>{{ item.phone }}</div>
              </div>
              <div>￥{{ item.balance }}</div>
            </div>
          </div>
          <div class="vip-modal-body-info" v-if="is_show_notdata">
            <div class="nullStyle2">
              <p class="text-center ft-24 color-999">抱歉，暂无搜索结果</p>
              <img
                src="@/static/images/zanwu.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup v-model="showCalendarBirth" round position="bottom" class="popup-picker-custom">
      <van-datetime-picker
        v-model="defaultDateBrith"
        type="month-day"
        @confirm="onConfirmBirth($event)"
        @cancel="showCalendarStart = false"
        item-height="8.5rem"
        :formatter="formatterBirth"
      />
    </van-popup>
    <van-popup v-model="showCalendarBirthMonth" round position="bottom" class="popup-picker-custom">
      <van-picker
        show-toolbar
        :columns="monthOptions"
        @confirm="onConfirmBirthMonth"
        @cancel="showCalendarBirthMonth = false;"
        item-height="8.5rem"
      />
    </van-popup>

    <van-number-keyboard
      v-model="numVal"
      :title="numVal"
      :show="show_keyboard"
      @blur="show_keyboard = false;"
      @input="handleNumChange"
      @delete="handleNumDelete"
      class="otherEatNum-nk"
    />
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { Toast } from "vant"
import { mapGetters } from 'vuex';
import { datetimePickerFormatter, formatTime, formatTimeWitchLine, getBeforeDate } from '../../utils/util';
import popupOfDialog from '@/views/component/popupOfDialog.vue';

export default {
  components: {
    titlebar: titlebar,
    popupOfDialog,
  },
  data() {
    return {
      page_name: 'coupon-manage',
      chooseIndex: "", //选中的分类
      goodsList: [], // 商品列表
      categoryList: [], // 商品分类列表
      categoryId: "", // 选择的商品分类ID
      param: "", // 商品名称或条码
      batchDelFlag: false, // 批量删除flag
      lastCategoryId: undefined, // 从商品详情回来储存的分类ID
      params: {
        page: 1,
        limit: 20,
      },
      total: 0,
      tab_list: [
        { id: 1, name: '优惠券模板' },
        { id: 2, name: '发放优惠券' },
        { id: 3, name: '发放记录' },
      ],
      tab_id: 1,
      show_choose_coupon: false,
      select_coupon: [], // 选中的优惠券
      choosed_coupon: [], // 已选择的优惠券
      modal_coupon_list: [], // 弹窗内的优惠券数据
      m_page: 1, // 当前页数
      m_limit: 6,
      m_count: 0,
      dimensionDate: ["今天", "昨天", "7天", "30天"],
      date_id: 0,
      start_time: "",
      end_time: "",
      page: 1, // 当前页数
      limit: 11,
      count: 0,
      defaultDate: [],
      minDate: new Date(),
      maxDate: new Date(),
      showCalendarStart: false,
      showCalendarEnd: false,
      formatter: datetimePickerFormatter,
      change_log: [],
      check_log: [], // 盘点记录
      showDialog: false,
      dialogType: '',
      dialogTitle: '',
      dialogMsg: '',
      dialogData: {},
      coupon_list: [],
      startDateTime: 0,
      endDateTime: 0,
      send_model: '1', // 发放模式 1单个发放， 2批量发放
      send_type: '1', // 批量发放 会员信息
      show_vip_modal: false,
      vipInfoShow: [],
      vipTel: '',
      choose_index: -1,
      choose_mem: {},
      is_show_notdata: false,
      member_type_list: [],
      mem_types: [],
      birth: '',
      showCalendarBirth: false,
      formatterBirth: datetimePickerFormatter,
      defaultDateBrith: new Date(),
      send_log: [],
      numberIndex: -1, // 页面数据参数
      numVal: '', // 数字键盘显示金额
      numValArr: [], // 数字键盘金额数组
      show_keyboard: false,
      platform_name: localStorage.nowDevice || '',
      birth_type: '1',
      showCalendarBirthMonth: false,
      monthOptions: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    };
  },
  computed: {
    ...mapGetters({shop: 'shop'}),
  },
  filters: {
    // 状态：0未使用 1已使用 2已过期
    CouponStatus(val) {
      let obj = {
        0: '未使用',
        1: '已使用',
        2: '已过期',
      }
      return obj[val] || '';
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem('memobj');
  },
  async created() {
    this.choosed_coupon = [];
    if (sessionStorage.getItem('memobj')) {
      console.log(sessionStorage.getItem('memobj'));
      this.choose_mem = JSON.parse(sessionStorage.getItem('memobj')).data;
      this.tab_id = JSON.parse(sessionStorage.getItem('memobj')).tab_id;
    }
    this.getCurTabData();
    let newD = new Date().setMonth(new Date().getMonth() - 2)
    newD = new Date(newD).setHours(0);
    newD = new Date(newD).setMinutes(0);
    this.minDate = new Date(newD);
    this.maxDate = new Date();
    this.getmembertype();
  },
  methods: {
    onConfirmBirthMonth(e) {
      console.log(e);
      this.birth = parseInt(e);
      this.showCalendarBirthMonth = false;
    },
    goBack() {
      if (this.$route.query.pre == 'vip') {
        this.$router.replace({
          name: 'vipManagement',
          query: {
            name: 'vipManagement',
          },
        })
      } else {
        this.$router.replace({
          name: 'featureSet',
          query: {
            name: 'featureSet',
            active: this.$route.query.active,
          },
        })
      }
    },
    handleNumChange(e) {
			this.numValArr.push(e);
			this.numVal = this.numValArr.join('');
			this.choosed_coupon[this.numberIndex].num = this.numVal;
		},
		handleNumDelete(e) {
			this.numValArr.pop();
			this.numVal = this.numValArr.length > 0 ? this.numValArr.join('') : '';
			this.choosed_coupon[this.numberIndex].num = this.numVal;
		},
    openKeyboard(item, index) {
      this.numberIndex = index;
      this.numVal = item.num.toString() || '';
      this.numValArr = item.num.toString().split('');
      this.show_keyboard = true;
    },
    getSendLog() {
      let query = {
        page: this.page,
        limit: this.limit,
        start_time: this.start_time,
        end_time: this.end_time,
      }
      this.$api.CouponLog_getList(query).then(res => {
        if (res.code == 1) {
          this.send_log = res.data.list || [];
          this.count = res.data.count || 0;
        } else {
          this.$toast(res.msg);
        }
      })
    },
    onConfirmBirth(event) {
      this.birth = formatTime(event, 'MM-dd');
      this.showCalendarBirth = false;
    },
    //获取会员类型
    getmembertype() {
      this.$api.getMemberTypeList().then((res) => {
        if (res.code == 1) {
          let list = res.data.data || [];
          this.member_type_list = list;
        } else {
          this.$toast(res.msg);
        }
      });
    },
    confirmMember(index) {
      this.choose_index = index;
      if (this.choose_index == -1) {
        this.$toast('请选择会员');
        return;
      }
      this.choose_mem = this.vipInfoShow[this.choose_index];
      this.show_vip_modal = false;
    },
    openMemModal() {
      this.show_vip_modal = true;
      this.vipInfoShow = [];
      this.vipTel = '';
      this.choose_index = -1;
      this.is_show_notdata = false;
      this.$nextTick(() => {
        this.$refs.membercodeinput.focus();
        this.$refs.membercodeinput.setAttribute("readonly", "readonly");
        setTimeout(() => {
          this.$refs.membercodeinput.removeAttribute("readonly");
        }, 200);
      }, 100);
    },
    async vipTelSearch() { // 手机号搜索vip
      if (!this.vipTel || this.vipTel == '') {
        this.$toast('输入手机号或姓名搜索');
        return;
      }
      let res = await this.$api.dt_getMemberList({
        content: this.vipTel,
        status: 1,
      })
      if (res.code === 1) {
        this.vipInfoShow = res.data.data.list;
        this.is_show_notdata = this.vipInfoShow.length == 0;
      }
    },
    sendCoupon() {
      let arr = [];
      for (let i = 0; i < this.choosed_coupon.length; i++) {
        const element = this.choosed_coupon[i];
        if (element.num > 10) {
          this.$toast('优惠券数量上限10张');
          return;
        }
        arr.push({
          id: element.id,
          num: element.num
        })
      }
      let query = {
        type: this.send_model,
        coupon_ids: JSON.stringify(arr),
      }
      if (this.send_model == 1) {
        if (!Object.keys(this.choose_mem).length) {
          this.$toast('请选择会员');
          return;
        }
        query['member_id'] = this.choose_mem.id;
      }
      if (this.send_model == 2) {
        query['send_type'] = this.send_type;
        if (this.send_type == 2) {
          query['member_type'] = this.mem_types.join(',');
        }
        if (this.send_type == 3) {
          query['birth'] = this.birth;
          if (this.birth_type == 2) {
            query['send_type'] = '4';
          }
        }
      }
      this.$api.Coupon_sendCoupon(query).then(res => {
        if (res.code == 1) {
          this.choose_mem = {};
          this.choosed_coupon = [];
          this.birth = '';
          this.mem_types = [];
          this.$toast('发放成功');
        } else {
          this.$toast(res.msg);
        }
      })
    },
    handleFifler() {
      if (this.start_time == "" && this.end_time != "") {
        this.$toast("请选择开始日期");
        return;
      }
      if (this.start_time != "" && this.end_time == "") {
        this.$toast("请选择结束日期");
        return;
      }
      this.page = 1;
      this.getSendLog();
    },
    resetTemp() {
      this.start_time = '';
      this.end_time = '';
      this.date_id = 0;
      this.page = 1;
      this.getselectTime();
      this.getSendLog();
    },
    onConfirmTime(event, type) {
      // console.log(event, type);
      if (type == 1) {
        if (
          this.endDateTime > 0 &&
          new Date(event).getTime() > this.endDateTime
        ) {
          this.$toast("开始日期不能大于结束日期");
          return;
        }
        this.startDateTime = new Date(event).getTime();
        this.start_time = formatTime(event, 'yyyy-MM-dd hh:mm');
        this.showCalendarStart = false;
      } else {
        if (
          this.startDateTime > 0 &&
          new Date(event).getTime() < this.startDateTime
        ) {
          this.$toast("结束日期不能小于开始日期");
          return;
        }
        this.endDateTime = new Date(event).getTime();
        this.end_time = formatTime(event, 'yyyy-MM-dd hh:mm');
        this.showCalendarEnd = false;
      }
    },
    // 选择时间
    getselectTime() {
      switch (this.date_id) {
        case 0:
          this.start_time = formatTimeWitchLine(new Date()) + ' 00:00';
          this.end_time = formatTimeWitchLine(new Date()) + ' 23:59';
          this.defaultDate = [new Date(), new Date()];
          break;
        case 1:
          let beforeDate = getBeforeDate(1);
          this.start_time = formatTimeWitchLine(beforeDate) + ' 00:00';
          this.end_time = formatTimeWitchLine(beforeDate) + ' 23:59';
          this.defaultDate = [beforeDate, new Date()];
          break;
        case 2:
          let beforeDate_1 = getBeforeDate(6);
          this.start_time = formatTimeWitchLine(beforeDate_1) + ' 00:00';
          this.end_time = formatTimeWitchLine(new Date()) + ' 23:59';
          this.defaultDate = [beforeDate_1, new Date()];
          break;
        case 3:
          let beforeDate_2 = getBeforeDate(29);
          this.start_time = formatTimeWitchLine(beforeDate_2) + ' 00:00';
          this.end_time = formatTimeWitchLine(new Date()) + ' 23:59';
          this.defaultDate = [beforeDate_2, new Date()];
          break;
        default:
          break;
      }
    },
    chooseType(item, index) {
      if (this.date_id != index) {
        this.date_id = index;
        this.getselectTime();
        this.getSendLog();
      }
    },
    chooseThatCoupon(item) {
      item.delChoosed ? item.delChoosed = false : this.$set(item, 'delChoosed', true);
      let arr = this.select_coupon;
      if (item.delChoosed) {
        arr.push(item);
      } else {
        const index = arr.findIndex(e => e.id == item.id);
        arr.splice(index, 1);
      }
      console.log('this.choosed_coupon 624', this.choosed_coupon);
    },
    deleteThatCoupon(item, index) {
      this.choosed_coupon.splice(index, 1);
    },
    async confirmCouponModal() {
      let arr = [];
      this.select_coupon.forEach(element => {
        arr.push(Object.assign({}, element, {
          num: element.num || 1,
        }))
      });
      this.choosed_coupon = arr;
      this.closeCouponModal();
    },
    closeCouponModal() {
      this.show_choose_coupon = false;
    },
    async openCouponModal() {
      this.m_page = 1;
      await this.getCouponList(1);
      this.setSelectData();
      console.log('this.choosed_coupon', this.choosed_coupon);
      let arr = [];
      this.choosed_coupon.forEach(element => {
        arr.push(element);
      });
      this.select_coupon = arr;
      this.show_choose_coupon = true;
    },
    setSelectData() {
      for (let i = 0; i < this.modal_coupon_list.length; i++) {
        const element = this.modal_coupon_list[i];
        const index = this.choosed_coupon.findIndex(e => e.id == element.id);
        if (index > -1) {
          element.delChoosed = true;
        }
      }
    },
    async handleChangeOfModal() {
      await this.getCouponList(1);
      this.setSelectData();
    },
    toogle(i) {
      if (this.tab_id != i.id) {
        this.tab_id = i.id;
        this.getCurTabData();
      }
    },
    async getCurTabData() {
      if (this.tab_id == 1) {
        this.page = 1;
        this.getCouponList();
      } else if (this.tab_id == 2) {

      } else if (this.tab_id == 3) {
        this.page = 1;
        this.getselectTime();
        this.getSendLog();
      }
    },
    getCouponList(type) {
      this.modal_coupon_list = [];
      this.coupon_list = [];
      return new Promise((resolve, reject) => {
        let query = {}
        if (type == 1) {
          query = {
            page: this.m_page,
            limit: this.m_limit,
            hidden: 1, // 是否显示过期优惠券 0 显示 1不显示
          }
        } else {
          query = {
            page: this.page,
            limit: this.limit,
            hidden: 0, // 是否显示过期优惠券 0 显示 1不显示
          }
        }
        this.$api.Coupon_getList(query).then(res => {
          if (res.code = 1) {
            const list = res.data.list || [];
            const count = res.data.count || 0;
            list.forEach(element => {
              if (element.period == 2) {
                element.start_time = formatTime(new Date(element.start_time), 'yyyy-MM-dd');
                element.end_time = formatTime(new Date(element.end_time), 'yyyy-MM-dd');
              }
            });
            if (type == 1) {
              this.modal_coupon_list = list || [];
              this.m_count = count || 0;
            } else {
              this.coupon_list = list || [];
              this.count = count || 0;
            }
            resolve();
          } else {
            if (type == 1) {
              this.modal_coupon_list = [];
            } else {
              this.coupon_list = [];
            }
            this.$toast(res.msg);
            resolve();
          }
        })
      })
    },
    jumptoDetail(type, item) {
      if (type == 1) {
        this.$router.push({
          name: 'coupon-add',
          query: {
            name: 'coupon-add',
            active: 13
          }
        })
      } else if (type == 2) {
        this.$router.push({
          name: 'coupon-add',
          query: {
            name: 'coupon-edit',
            active: 13,
            id: item.id,
          }
        })
      }
    },
  },
};
</script>

<style lang="less" scoped>
.otherEatNum-nk {
  border-radius: 0.13rem;
  width: 30vw;
  left: 35vw;
  z-index: 9999;
}
.face-price-input {
  width: 11rem;
  height: 6.2rem;
  border: 0.2rem solid #DEDEDE;
  border-radius: 0.8rem;
  padding: 0 2rem;
  font-size: 2.4rem;
}
.vip-modal {
  height: 60vh;
  width: 86rem;
  background: #fff;
  .vip-modal-head {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    .vip-modal-head-title {
      font-size: 3.6rem;
    }
    img {
      position: absolute;
      right: 4rem;
      width: 4.3rem;
    }
  }
  .vip-modal-body {
    height: calc(100% - 19rem);
    overflow-y: scroll;
    .vip-modal-body-input {
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        //width: 62.6rem;
        width: 46rem;
        height: 6rem;
        background: #f8f8f8;
        border-radius: 0.8rem;
        //margin: 0 0 2rem 2rem;
        padding-left: 6.3rem;
        font-size: 2.6rem;
        font-weight: 400;
        color: #666666;
        line-height: 3.7rem;
        text-align: left;
      }
      .van-button {
        margin-left: 2rem;
        height: 6rem;
        border-radius: 0.8rem;
      }
    }
    .vip-modal-body-info {
      font-size: 2.6rem;
      margin: 0 10rem;
      line-height: 1.3;
      text-align: left;

      .vipinformation {
        color: #fff;
        padding: 0 2.5rem;
        height: 11rem;
        margin: 2rem 0 1.5rem 0;
        border-radius: 0.8rem;
        background-image: url("../../static/images/member-bgs.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }

      .choosed1 {
        background-image: url("../../static/images/member-bg.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
  }
  .vip-modal-bottom {
    height: 8.5rem;

    .confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
  }
}
.tab-content {
  height: calc(100vh - 28rem);
  position: relative;

  .tab-c-item {
    // box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.5);
    height: calc(100% - 13rem);
    border-radius: 0.8rem;
    // margin: 2rem 3rem;
    padding: 2rem 3rem 2rem;
    text-align: left;
    overflow-y: scroll;

    .card-item-line {
      padding: 3rem 1.5rem;
      border-bottom: 0.15rem solid #eee;
      display: flex;
      align-items: center;
      font-size: 2.4rem;

      .ff-chaoose-btn {
        width: 16rem;
        height: 6.2rem;
        border-radius: 0.8rem;
        font-size: 2.4rem;
        color: #fff;
        background: #1588F5;
      }
    }

  }
  .s-s-btns {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #fff;
    z-index: 10;
    height: 10rem;
    align-items: center;
    box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.26);
    left: 0;
    .s-s-btn {
      width: 43rem;
      height: 7rem;
      border-radius: 0.8rem;
      font-size: 2.4rem;
    }
  }

  .c-c-head {
    background: #f2f2f2;
    height: 7.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      flex: 1;
    }
  }

  .c-c-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      flex: 1;
      border-bottom: 0.15rem rgba(0, 0, 0, 0.06) solid;
      height: 7.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .stock-input {
      width: 40%;
      height: 6rem;
      border: 0.2rem solid #DEDEDE;
      border-radius: 0.8rem;
      padding: 0 2rem;
      font-size: 2.4rem;
    }
  }
}
.nullStyle {
  padding-top: 2rem;
  text-align: center;

  img {
    margin: 0 auto;
    width: 2.53rem;
    height: 1.77rem;
  }
}
.pagination {
  width: 30%;
  /deep/.van-pagination {
    font-size: 1.9rem;
  }
  /deep/.van-pagination__item--active {
    background-color: #1588F5;
  }
}
.checklog-box {
  margin: 1rem 2rem;
  .revenue-title {
    font-size: 2.6rem;
    color: #333333;
  }
  table thead,
  tbody tr,
  tfoot tr {
    display: table;
    width: 100%;
    table-layout: fixed;

    td {
      word-break: break-all;
    }
  }

  table {
    background: white;
    position: relative;
    display: table;
    // height: 100%;
    // overflow-y: scroll;
    width: 100%;

    ::-webkit-scrollbar {
      display: block;
      width: 0.6rem;
      height: 0.5rem;
      background-color: #fff;
    }

    ::-webkit-scrollbar-thumb {
      display: block;
      min-height: 0.5rem;
      min-width: 0.4rem;
      border-radius: 0.8rem;
      background-color: rgb(217, 217, 217);
    }

    thead {
      width: calc(100% - 0.7rem);
    }

    thead td {
      border: 0.15rem rgba(0, 0, 0, 0.06) solid;
    }

    tbody {
      overflow-y: scroll;
      display: block;
      height: calc(85vh - 46rem);
      width: 100%;
      overflow-x: hidden;
      position: relative;
    }

    .tbody-tr {
      overflow-y: scroll;
      height: 7.5rem;
      width: 100%;

      &.odd {
        background: #fef7ef;
      }

      td {
        border: 0.15rem rgba(0, 0, 0, 0.06) solid;
      }
    }
  }
}
.search-value {
  display: flex;
  flex-wrap: wrap;
  // background: #fff;
  padding: 0 3rem 0 0;
  margin: 2rem 2rem;
  align-items: center;

  .search-item {
    margin: 0 3rem 0 0;

    .timestyle {
      font-weight: 400;
      font-size: 2.4rem;
      color: #666666;
      padding-right: 2rem;
    }

    .dimension {
      border-radius: 0.8rem;
      font-weight: 400;
      font-size: 2.2rem;
      color: #666666;

      .dimensiondate {
        width: 7.8rem;
        height: 4.8rem;
        background: #ffffff;
        border: 0.1rem solid #dedede;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -0.2rem;
      }

      .dimensiondate:first-child {
        border-radius: 0.8rem 0 0 0.8rem;
      }
      .dimensiondate:last-child {
        border-radius: 0 0.8rem 0.8rem 0;
      }

      .dimensiondate-check {
        background: #1588F5;
        border-color: #1588F5;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -0.2rem;
        color: #fff;
      }
    }
  }

  .ordernum-input {
    border: 0.1rem #DEDEDE solid;
    width: 21.5rem;
    height: 4.8rem;
    border-radius: 0.8rem;
    font-size: 2.2rem;
    padding-left: 1.5rem;
  }

  .search-btn {
    background: #1588F5;
    font-size: 2.4rem;
    color: #fff;
    height: 4.8rem;
    padding: 0 2.5rem;
    border-radius: 0.8rem;
  }

  .search-date-input {
    width: 21.5rem;
    font-size: 2.4rem;
    // text-align: center;
  }
}
.choose-coupon-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

	.modal-content-box {
		width: 70%;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
	.close {
		width: 2.6rem;
    height: 2.6rem;
		position: absolute;
    right: 2.5rem;
    top: 2.5rem;
		z-index: 10;
	}
	.wrapper {
		padding: 0rem 3.5rem;
		position: relative;

		.title {
			padding-top: 2.5rem;
			border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
		}

    .mocontent {
      margin: 2rem 0 1rem;
      position: relative;
      display: flex;
      justify-content: center;
      overflow-y: scroll;
      height: 58vh;
      .deskcontent {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .del-checkbox {
          position: absolute;
          left: 0;
          top: 0;
          background: #fff;
        }
      }
    }
	}
	.btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8.5rem;
		.confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
	}
}
.type-head {
  font-size: 2.6rem;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;

  .ab {
    // flex: 1;
    text-align: center;
    line-height: 8.5rem;
    height: 8.5rem;
    position: relative;
    font-weight: bold;
    // margin-left: 2.5rem;
    width: 15%;
  }

  .ac::after {
    content: "";
    display: block;
    position: absolute;
    width: 12rem;
    height: 1.5rem;
    background: #1588F5;
    bottom: 0.5rem;
    left: calc(50% - 6rem);
    transform: translateY(-0.2rem);
    transition: 0.3s;
  }
}
.page {
  width: 100%;
  height: 100%;
  background: #fff;
  // display: flex;
  // flex-direction: column;
}
.coupon-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  height: calc(100% - 18rem);

  .index_list {
    grid-template-columns: repeat(3, 32.8%);
    grid-gap: 2.5rem 1%;
  }
}
.index_list {
  width: calc(100% - 4rem);
  // height: 50rem;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: repeat(auto-fill, 15rem);
  grid-gap: 2.5rem 3%;
  overflow-x: hidden;
  overflow-y: scroll;

  .addpro-index-box {
    display: flex;
    align-items: center;
    justify-content: center !important;
    font-size: 2.8rem;
    color: #1588F5;
    font-weight: bold;
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  .index_box {
    position: relative;
    // width: 32.9rem;
    // height: 16rem;
    background: #ffffff;
    // box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    position: relative;
    padding: 0.5rem 1.5rem;
    height: 15rem;
    border: 0.2rem #cdcdcd solid;
    border-radius: 0.8rem;

    .coupon-tag-img {
      position: absolute;
      top: -1.2rem;
      right: 0;
      width: 11rem;
      height: 11rem;
    }

    .coupon-left {
      width: 26.5%;
      position: relative;
    }
    .coupon-left::after {
      content: '';
      width: 0.15rem;
      height: 132%;
      position: absolute;
      right: 0;
      top: -15%;
      border-right: 0.15rem dashed #E5E5E5;
    }
  }
  .expired-box {
    div {
      color: #999;
    }
  }
}
</style>
