<template>
    <div class="pagepur">
        <!-- 传过来的标题 -->
        <titlebar :name="$route.name"/>
        <div class="page_box">订单号：<span>{{ info.order_num }}</span></div>
        <div class="list_box">
            <div class="page_list">
                <div class="list_num"></div>
                <div class="list_name">商品名称</div>
                <div class="tit1_gy">计量单位</div>
                <div class="tit1_gy">购买数量</div>
                <div class="tit1_gy">总数量(克)</div>
                <div class="tit2_gy">单价(元)</div>
                <div class="tit2_gy">小计(元)</div>
                <div class="tit4_gy">核销数量(克)</div>
            </div>
        </div>
        <div class="list_box">
            <div
                class="page_list"
                v-for="(item, index) in info.order_info"
                :key="index"
            >
                <div class="list_num">{{ index + 1 }}</div>
                <div class="list_name">{{ item.product_name }}</div>
                <div class="tit1_gy">{{ item.p_type === 1 ? '计件' : '计重(克)' }}</div>
                <div class="tit1_gy">{{ item.num }}</div>
                <div class="tit1_gy">{{ item.p_type === 1 ? item.num : $bigN(item.num).times(item.net).toNumber() }}</div>
                <div class="tit2_gy">{{ item.p_type === 1 ? item.attr_price || item.product_fact_price : (item.attr_price || item.product_fact_price) + '/' }}<span v-if="item.p_type === 2" style="vertical-align: text-top; font-size: 1.6rem">{{ `${item.net}g` }}</span></div>
                <div class="tit2_gy">{{ $bigN(item.num).times(item.attr_price || item.product_fact_price).toNumber().toPrice() }}</div>
              <div class="tit4_gy">
                    <van-stepper
                        v-if="item.p_type === 1"
                        v-model="item.returnNum"
                        theme="round"
                        button-size="22"
                        disable-input
                        :step="1"
                        min="0"
                        :max="item.num"
                    />
                    <van-stepper
                        v-else
                        :value="item.returnNum"
                        theme="round"
                        button-size="22"
                        min="0"
                        async-change
                        disable-input
                        @plus="plus(item)"
                        @minus="item.returnNum = 0"
                    />
                </div>
            </div>
        </div>
        <div class="btm_btn_total">
            <div class="btn" @click="getHxOrderAmount">核销</div>
        </div>
        <van-popup
            v-model="goodsWeightDialog"
            :close-on-click-overlay="false"
            @open="openGoodsWeightDialog"
            @close="goodsWeightDialogClose"
        >
            <div class="popups">
                <div class="system_title">
                    <div>请使用电子称获重量</div>
                    <img
                        src="https://cashier.demo.api.dianzhangbao.com.cn/202106/mdsy/close.png"
                        @click="goodsWeightDialog = false"
                    />
                </div>
                <!-- 其他支付 -->
                <div class="scale">
                    <div>
                        <van-radio-group disabled v-model="radio1">
                            <van-radio :name="1">稳定</van-radio>
                        </van-radio-group>
                        <van-radio-group disabled v-model="radio2">
                            <van-radio :name="2">净重</van-radio>
                        </van-radio-group>
                        <van-radio-group disabled v-model="radio3">
                            <van-radio :name="3">零位</van-radio>
                        </van-radio-group>
                    </div>

                    <div class="scale-con">
                        <div class="scale-con-content">
                            <div class="text">计重(kg)</div>
                            <div class="num">
                                {{ goodsNetWeight }}
                            </div>
                        </div>
                    </div>

                    <div class="scale-con">
                        <div class="scale-con-content">
                            <div class="text">皮重(kg)</div>
                            <div class="num">
                                <input v-model="skin" type="number" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="scale-price">
                    <div>商品名称: {{ goodsItem.product_name }}</div>
                </div>
                <div class="bottom-btn">
                    <div class="but" style="background: red" @click="clearWeight">
                        清零
                    </div>
                    <div class="but" @click="confirmDet">确定</div>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="showdet" @open="$nextTick(() => $refs.modalInput.focus())" @close="closed">
            <div class="popups">
                <div class="system_title">
                    <div>
                        <span>{{ orderAmount.type === 1 ? '请补款' : '退款' }}</span>
                    </div>
                    <img src="https://cashier.demo.api.dianzhangbao.com.cn/202106/mdsy/close.png" @click="showdet = false;" />
                </div>
                <!-- 其他支付 -->
                <div class="detail">
                    <div class="select" v-if="orderAmount.type === 1">
                        <div class="select-item" :class="payType === item.id ? 'selected': ''" v-for="item in payList" :key="item.id" @click="payType = item.id">{{ item.name }}</div>
                    </div>
                    <!--暂时直接付款-->
                    <div style="display: flex;justify-content: space-between;align-items: center">
                        金额：<input type="number" v-model="money" ref="modalInput" autofocus class="" />
                    </div>
                    <div style="text-align: center; margin-left: 50px; margin-top:5px; font-size: 12px; color: red;">
                      <p v-if="info.discount*10!=0">注：默认金额为用户折扣后的费用( {{ info.discount*10 }}折 )</p>
                    </div>
                    <div v-if="payType === 6 || payType === 8" style="display: flex;justify-content: space-between;align-items: center">
                        支付密码：<input type="password" v-model="paw_code" ref="modalInput" autofocus class="" />
                    </div>
                    <div v-if="payType === 3" class="money2">
                        <div class="money2-left">
                            <div>实收<span style="color: red">现金</span>：</div>
                        </div>
                        <div class="money2-right">
                            <input
                                type="number"
                                v-model="money2"
                                autofocus
                                class=""
                            />
                            <div>
                                找零：<span>￥{{ $bigN(money2 || 0).minus(money || 0).toNumber() }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="orderAmount.type === 1 && (payType === 1 || payType === 2 || payType === 7)" style="display: flex;justify-content: space-between;align-items: center;margin-top: 2rem">
                        付款码：<input type="text" v-model="paymentCode" @keyup.enter="onlineOrderPayOrReturn" ref="modalInput" autofocus class="" />
                    </div>
                </div>
                <!--暂时直接付款-->
                <div class="but" @click="onlineOrderPayOrReturn">确定</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import titlebar from "@/views/component/titlebar"
import {Toast} from "vant"
import {mapState} from "vuex"
export default {
    name: "writeOff",
    components: { titlebar },
    data() {
        return {
            info: {},
            goodsWeightDialog: false, // 称重弹框
            radio1: false, // 稳定
            radio2: false, // 净重
            radio3: false, // 零位
            skin: 0, // 皮重
            goodsItem: {}, // 选中的称重商品
            orderAmount: {},
            showdet: false,
            payList: [ // 支付方式  1微信,2支付宝，3现金 6会员余额 7-收呗（补的时候需要传该参数）
                { id: 7, name: '收呗' },
                // { id: 1, name: '微信' },
                // { id: 2, name: '支付宝' },
                { id: 3, name: '现金' },
                { id: 6, name: '会员余额' },
                // { id: 8, name: '银行余额' },
            ],
            payType: 7, // 支付类型ID
            money: '', // 支付金额
            paymentCode: undefined, // 支付码
            money2: '', // 用于找零
            paw_code: undefined, // 会员余额，银行支付的密码
          index:'',
        }
    },
    computed: {
        ...mapState(["WeightDataDetail"]), // 电子称数据
        goodsNetWeight() { // 计算净重
            let weight = this.$bigN(this.WeightDataDetail.net).div(1000).minus(this.skin || 0).toNumber()
            this.radio3 = weight === 0 ? 3 : false
            if ((this.WeightDataDetail.status == 1 || (this.WeightDataDetail.status == 3 && this.WeightDataDetail.net >= 0)) && weight > 0) {
                this.radio1 = 1
                this.radio2 = this.skin > 0 ? 2 : false
            } else {
                this.radio1 = false
                this.radio2 = false
            }
            return weight
        },
    },
    methods: {
        getDetail() {
            this.$api.getOnlineShopOrderDetail({
                order_id: this.$route.query.order_id
            }).then(res => {
                if (res.code == 1) {
                    res.data.order_info.forEach(item => {
                        this.$set(item, 'returnNum', item.p_type === 1 ? item.num : 0)
                    })
                    this.info = res.data
                }
            })
        },
        plus(e) {
            this.goodsItem = e
            this.goodsWeightDialog = true
        },
        // 弹窗开启
        openGoodsWeightDialog() {
            // 打开弹窗时连接电子称
            if (typeof KissSunmiPrint !== "undefined") {
                this.$scaleService.connectScaleService().then((msg) => {
                    this.$scaleService.getScaleDataKeepAlive(); // 连续获取电子秤净重、状态、数值返回
                    console.log("============================连接成功" + msg);
                }).catch((err) => {
                    Toast("电子秤未连接！");
                    console.log("============================连接失败" + err);
                });
            } else {
                Toast("无法找到电子秤");
            }
        },
        // 弹窗关闭
        goodsWeightDialogClose() {
            if (typeof KissSunmiPrint !== "undefined") {
                this.$scaleService.onDestroy().then((msg) => {
                    console.log("============================断开成功" + msg);
                }).catch((err) => {
                    console.log("============================断开失败" + err);
                });
            }
        },
        // 清空电子称重量
        clearWeight() {
            if (typeof KissSunmiPrint !== "undefined") {
                this.$scaleService.myZeros().then((msg) => {
                    this.$scaleService.getScaleDataKeepAlive(); // 连续获取电子秤净重、状态、数值返回
                    console.log("============================清空成功" + msg);
                }).catch((err) => {
                    console.log("============================清空失败" + err);
                });
            }
        },
        confirmDet() { // 计重弹框确认
            if (this.goodsNetWeight <= 0) return Toast("请称重后再进行确认！")
            this.goodsItem.returnNum = this.$bigN(this.goodsNetWeight).times(1000).toNumber() // 需要转换为克显示
            this.goodsWeightDialog = false
        },
        getHxOrderAmount() { // 多退少补生成订单
            let goods_info = JSON.stringify(
                this.info.order_info.map(item => {
                    console.log("商品信息：",item);
                    let attr = ''
                    if(item.attr_name){
                      attr = '/'+ item.attr_name;
                    }
                    return {
                        product_id: item.id,
                        weight: item.p_type === 1 ? item.returnNum : this.$bigN(item.returnNum).toNumber(), // 传g作为重量
                        o_weight: item.p_type === 1 ? item.num : this.$bigN(item.num).times(item.net).toNumber(),
                        product_name: item.p_type === 1 ? item.product_name : item.product_name + attr,
                        product_fact_price: item.product_fact_price,
                        p_type: item.p_type,
                        pay_num: item.num
                    }
                })
            )
            this.$api.getHxOrderAmount({
                order_id: this.$route.query.order_id,
                goods_info
            }).then(res => {
                if (res.code === 1) {
                    this.orderAmount = res.data
                    this.money = res.data.diffPrice
                    //console.log("测试内容",this.orderAmount);
                    //return false;
                    //如果返回的type!=3 则需要弹框进行多退少补操作
                    if(this.orderAmount.type != 3) {
                      this.showdet = true
                    }else{
                      //console.log("核销成功",this.orderAmount);
                      //console.log("核销成功",this.$route.query.order_id);
                      this.$api.onlineOrderWriteOff({
                          order_id: this.$route.query.order_id,
                      }).then(res => {
                          this.$toast(res.msg);
                          if (res.code === 1) {
                            setTimeout(() => {
                              this.$router.go(-1)
                            }, 1500)
                          }
                      })
                    }
                }
            })
        },
        onlineOrderPayOrReturn() { // 多退少补支付
            if(this.money === ""){
              this.money = 0;
            }
            let weight_info = JSON.stringify(
                this.info.order_info.map(item => {
                    let attr = ''
                    if(item.attr_name){
                        attr = '/'+ item.attr_name;
                    }
                    return {
                        product_id: item.id,
                        weight: item.p_type === 1 ? item.returnNum : this.$bigN(item.returnNum).toNumber(), // 传g作为重量
                        o_weight: item.p_type === 1 ? item.num : this.$bigN(item.num).times(item.net).toNumber(),
                        product_name: item.p_type === 1 ? item.product_name : item.product_name + attr
                    }
                })
            )
            this.$api.onlineOrderPayOrReturn({
                order_id: this.$route.query.order_id,
                order_num: this.orderAmount.order_num,
                pay_type: this.orderAmount.type === 1 ? this.payType : undefined,
                fact_money: this.money,
                pay_code: this.paymentCode,
                type: this.orderAmount.type,
                paw_code: this.payType === 6 || this.payType === 8 ? this.paw_code : undefined,
                weight_info
            }).then(res => {
                if (res.code === 1) {
                    this.showdet = false
                    Toast.success('核销成功！')
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1500)
                }
            })
        },
        closed() { // 关闭
            this.orderAmount = {}
            this.payType = 7
            this.money = '' // 支付金额
            this.paymentCode = undefined // 支付码
            this.money2 = ''
            this.paw_code = undefined // 会员余额，银行余额的支付密码
        },
    },
    created() {
        this.getDetail();
        this.index=this.$route.query.orderindex;
    }
}
</script>

<style lang="less" scoped>
.pagepur {
    width: 192rem;
    height: 100%;
    background: #f8f8f8;

    .page_box {
        display: flex;
        align-items: center;
        height: 8.1rem;
        padding-left: 7.4rem;
        font-size: 2.2rem;
        font-weight: 400;
        color: #333333;
        line-height: 3rem;
        background: #ffffff;
        border-top: 1px solid #e5e5e5;

        span {
            margin-left: 2.4rem;
            font-size: 2.4rem;
            font-weight: 500;
            color: #333333;
            line-height: 3.3rem;
        }
    }
    .list_box {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        // height: 94.9rem;
        overflow-y: scroll;
        overflow-x: hidden;

        .page_list {
            display: flex;
            width: 192rem;
            height: 9.4rem;
            align-items: center;
            background: #ffffff;
            font-size: 2.6rem;
            font-weight: 400;
            color: #333333;
            line-height: 3.7rem;

            .list_num {
                width: 15rem;
            }
            .list_name {
                // width: 65rem;
                width: 78rem;
                text-align: left;
            }
            .tit1_gy {
                width: 16rem;
            }

            .tit2_gy {
                width: 20rem;
            }

            .tit3_gy {
                width: 20rem;
            }
            .tit4_gy {
                width: 28rem;
                color: #1588F5;

                /deep/ .van-stepper--round .van-stepper__minus {
                    width: 2.9rem !important;
                    height: 2.9rem !important;
                    color: #272536;
                    background-color: #fff;
                    border: 1px solid #272536;
                }

                /deep/ .van-stepper--round .van-stepper__plus {
                    width: 2.9rem !important;
                    height: 2.9rem !important;
                    background-color: #999999;
                }

                /deep/ .van-stepper__input {
                    width: 8rem;
                    font-size: 2.8rem;
                    font-weight: 400;
                    color: #1588F5;
                    line-height: 4rem;
                    background: #ffffff;
                }
            }

            .tit5_gy {
                width: 28rem;
                color: #1588F5;
            }
        }
    }
}
.btm_btn_total {
    width: 192rem;
    height: 12.7rem;
    position: fixed;
    bottom: 0rem;
    background: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 0.1rem solid #e5e5e5;
    font-size: 2.6rem;
    font-weight: 400;
    color: #333333;
    line-height: 3.7rem;

    .btn {
        width: 19.4rem;
        height: 6rem;
        background: #1588F5;
        margin: 0 6.5rem 0 5rem;
        font-size: 2.6rem;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.popups {
    width: 102.9rem;
    //height: 48.7rem;
    background: #ffffff;
    text-align: -webkit-center;

    .system_title {
        display: flex;
        align-items: center;
        //justify-content: flex-end;
        //margin: 0 4.6rem 3.1rem 4.6rem;
        height: 12.5rem;
        border-bottom: 0.1rem solid #e5e5e5;
        font-size: 3.6rem;
        font-weight: 400;
        color: #333333;
        position: relative;
        > :first-child {
            flex: 1;
        }
        img {
            width: 4.3rem;
            position: absolute;
            right: 4rem;
            // height: 4.3rem;
            // text-align: end;
            //margin-left: 29.4rem;
        }
    }

    .scale {
        //display: flex;
        //justify-content: space-around;
        display: grid;
        grid-template-columns: repeat(3, 33%);
        font-size: 2.4rem;
        margin-top: 3rem;

        .van-radio__label--disabled {
            color: #1588f5;
            font-weight: 500;
            line-height: 2.2rem;
        }
        .van-radio-group {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            .van-radio {
                margin: 1rem;
            }
        }

        .scale-con {
            //text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            .scale-con-content {
                width: fit-content;
            }
            .text {
                text-align: right;
            }
            .num {
                text-align: right;
                font-size: 5rem;
                margin-top: 2rem;

                input {
                    width: 16rem;
                    text-align: right;
                }
            }
        }
        .van-radio__icon--disabled.van-radio__icon--checked .van-icon {
            color: #1588f5;
        }
    }
    .scale-price {
        display: flex;
        justify-content: space-around;
        font-size: 3rem;
        margin: 4rem 0;

        div {
            width: 33%;
        }
    }

    .detail {
        margin: 4rem 10rem 8.5rem;
        text-align: left;
        font-size: 2.8rem;
        font-weight: bold;
        color: #333333;
        line-height: 4rem;

        .select {
            display: flex;
            justify-content: center;
            margin-bottom: 4rem;
            color: #999;
            .select-item {
                padding: 0 1.6rem;
                border: 0.1rem solid;
                border-radius: 3.2rem;
                margin-right: 1rem;
            }
            .selected {
                color: #1588f5;
            }
        }

        input {
            //width: 62.6rem;
            width: 46rem;
            height: 7.1rem;
            background: #f8f8f8;
            border-radius: 0.8rem;
            //margin: 0 0 2rem 2rem;
            padding-left: 6.3rem;
            font-size: 2.6rem;
            font-weight: 400;
            color: #666666;
            line-height: 3.7rem;
        }

        .detail_context {
            font-size: 3.6rem;
            color: #fe5600;
        }
    }

    .bottom-btn {
        margin-top: 8rem;
        display: flex;
        justify-content: center;
        > :last-child {
            margin-left: 4rem;
        }
    }

    .but {
        width: 27rem;
        height: 7rem;
        background: linear-gradient(270deg, #1588f5 0%, #1588f5 100%);
        font-size: 2.8rem;
        font-weight: 500;
        color: #ffffff;
        line-height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3rem;
    }
}
.money2 {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .money2-right {
        width: 52.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
            width: 20rem;
        }
    }
}
</style>
