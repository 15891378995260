import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/request/api';

Vue.use(Vuex)

import $bigN from 'bignumber.js'
import { formatTime } from '../utils/util';

function accAdd(arg1, arg2) {
  var r1, r2, m;
  try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  return (arg1 * m + arg2 * m) / m
}

function accMul(arg1, arg2) {
  var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
  try { m += s1.split(".")[1].length } catch (e) { }
  try { m += s2.split(".")[1].length } catch (e) { }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
}

var timerCont = null;


const store = new Vuex.Store({
  state: {
    shopCartList: [], // 购物车列表
    // totalCount: 0, // 购物车总数
    // totalPrice: 0, // 购物车总额
    areCancellProList: [], // 挂单商品列表
    areCancellOrderNum: '', // 挂单订单编号
    currentSwee_code: "",//唯一编码
    WeightDataDetail: {
      net: 0
    }, // 重量对象对象
    vipInfo: {}, // 选中的vip信息
    isShowPrinter: false, // 是否展示选择打印机弹窗
    // outDeviceList: [], // USB设备列表
    moneyBoxDeviceName: '',
    is_show_navtar: false,
    now_time_str: '', // 当前时间展示
    shop: {}, // 门店信息
    deviceIp: '',
    deviceMac: '',
    qtPrinter: {}, // 系统中获取到的前台打印机
    outDeviceList: [], // 搜索到的设备列表
    deviceInfo: {
      ip: ''
    }, // 设备信息
    bindStatus: '', // 本机是否绑定了打印机
    isCutPage: '1', // 安卓是否切纸 0不切 1切纸
    weightDetail: {
      net: 0,
      weight: 0,
      status: 0,
      grossWeight: 0,
      overflow: ''
    }, // 重量对象对象
    mtScale: {}, // 美团内置电子秤
    usbConnectStatus: {}, // 安卓USB打印机连接状态
    stock_check_list: [], // 库存盘点的商品数据
    hcd_barcode: {},
    mtPrinterStatus: {}, // 美团内置打印机状态
    tagPrinter: [],
    labelDeviceList: [], // 搜索到的标签打印机
    labelPrintCount: 0,
    bluetoothDeviceIos: [], // iOS  搜到的蓝牙标签打印机
    blelabelConnectStatus: {}, // iOS蓝牙标签机连接状态
    labelConnectStatus: {}, // 安卓标签打印机连接状态
    printtag_good_list: [], // 要打印标签的商品
    is_change_model: false, // 是否在切换模式
    nowVersion: '2.1.8',
    show_pay_tip: false, // 是否显示付费提示
    isShowNotify: false, // 是否显示系统通知
    notifyContent: '', // 系统通知内容
    notifyOrders: [], // 自取和外卖通知内容
  },
  getters: {
    shopCartList: state => state.shopCartList,//购物车列表
    // 购物车总数
    totalCount(state) {
      let arr = state.shopCartList || [];
      let sum = arr.reduce((accumulator, cur) => {
        if (cur.dw == 'kg' || cur.dw == '千克' || cur.dw == '克' || cur.dw == '斤') {
          return accumulator + 1
        } else {
          return $bigN(accumulator).plus(cur.num).toNumber()
        }
      }, 0);
      return sum;
    },
    // 购物车总额
    totalPrice(state) {
      // console.log('state.shopCartList---', state.shopCartList);
      let totalPrice = 0;
      state.shopCartList.forEach(element => {
        let addPrice = $bigN(element.num).times(element.fact_price).div(100).toNumber().toFixed(2)
        totalPrice = $bigN(totalPrice).plus(addPrice).toNumber().toFixed(2)
      });
      return totalPrice
    },
    // 购物车会员价总额
    totalMemberPrice(state) {
      let totalMemberPrice = 0;
      state.shopCartList.forEach(element => {
        // 会员价为0的话就取原价
        const price = element.member_price && element.member_price > 0 && element.is_editprice != 1 ? element.member_price : element.fact_price;
        let addPrice = $bigN(element.num).times(price).div(100).toNumber().toFixed(2)
        totalMemberPrice = $bigN(totalMemberPrice).plus(addPrice).toNumber().toFixed(2)
      });
      return totalMemberPrice
    },
    areCancellProList: state => state.areCancellProList, // 挂单商品列表
    // 挂单商品总数
    areCancellTotalCount(state) {
      let arr = state.areCancellProList || [];
      let sum = arr.reduce((accumulator, cur) => accumulator + cur.num, 0);
      return sum;
    },
    areCancellOrderNum: state => state.areCancellOrderNum, // 挂单订单编号
    shop: state => state.shop,
    outDeviceList: state => state.outDeviceList,
    weightDetail: state => state.weightDetail,
    hcd_barcode: state => state.hcd_barcode,
    labelDeviceList: state => state.labelDeviceList,
    bluetoothDeviceIos: state => state.bluetoothDeviceIos,
    getNotifyOrders: state => state.notifyOrders,
  },
  mutations: {
    updateShowPayTip(state, val) {
      state.show_pay_tip = val;
    },
    updateIsChangeModel(state, val) {
      state.is_change_model = val;
    },
    updatePrinttagGoodList(state, val) {
      state.printtag_good_list = val;
    },
    updateLabelConnectStatus(state, val) {
      state.labelConnectStatus = val;
    },
    updateBluetoothDeviceIos(state, val) {
      state.bluetoothDeviceIos = val;
    },
    // 更新标签设备列表
    updateLabelDeviceList(state, val) {
      state.labelDeviceList = val;
    },
    updateStockCheckList(state, val) {
      state.stock_check_list = val;
    },
    updateMtPrinterStatus(state, val) {
      state.mtPrinterStatus = val;
    },
    updateHcdBarcode(state, val) {
      state.hcd_barcode = val;
    },
    updateUsbConnectStatus(state, val) {
      // console.log('store', val);
      state.usbConnectStatus = val;
    },
    updateMtScale(state, val) {
      state.mtScale = val;
    },
    // 更新电子称重量
    // updateWeightDataDetail(state, data) {
    //   state.weightDetail = data;
    // },
    updateWeightDetail(state, val) {
      state.weightDetail = val;
    },
    updateBindStatua(state, val) {
      state.bindStatus = val;
    },
    // 更新设备信息
    updateDeviceInfo(state, val) {
      state.deviceInfo = val;
    },
    // 更新设备列表
    // updateOutDeviceList(state, val) {
    //   state.outDeviceList = val;
    // },
    updateQtPrinter(state, val) {
      state.qtPrinter = val;
    },
    updateDeviceIp(state, val) {
      state.deviceIp = val;
    },
    updateDeviceMac(state, val) {
      state.deviceMac = val;
    },
    setShopInfo(state, val) {
      state.shop = val;
    },
    updateNowTimeStr(state, val) {
      state.now_time_str = val;
    },
    updateIsShowNavtar(state, val) {
      state.is_show_navtar = val;
    },
    updateMoneyBoxDeviceName(state, val) {
      state.moneyBoxDeviceName = val;
    },
    updateOutDeviceList(state, val) {
      state.outDeviceList = val;
    },
    updateIsShowPrinter(state, val) {
      state.isShowPrinter = val;
    },
    // 更新购物车
    updateShopCart(state, shopCartList) {
      state.shopCartList = shopCartList;
    },
    // 更新挂单商品列表
    updateAreCacellProList(state, areCancellProList) {
      state.areCancellProList = areCancellProList;
    },
    // 更新挂单订单编号
    updateAreCancellOrderNum(state, areCancellOrderNum) {
      state.areCancellOrderNum = areCancellOrderNum;
    },
    //更新唯一编码
    undateCurrentSwee_code(state, currentSwee_code) {
      state.currentSwee_code = currentSwee_code;
    },
    // 更新电子称重量
    updateWeightDataDetail(state, data) {
      state.WeightDataDetail = data;
    },
    setVipInfo(state, info) { // 设置vipInfo
      state.vipInfo = info
    },
    updateTagPrinter(state, val) {
      state.tagPrinter = val;
    },
    updateNotifyContent(state, val) {
      state.notifyContent = val;
    },
    updateNotifyOrders(state, orders) {
      state.notifyOrders = orders;
    },
    updateIsShowNotify(state, val) {
      state.isShowNotify = val;
    },
  },
  actions: {
    setShopInfo(context, data) {
      console.log("%c 🍺 dispatch setShopInfo", "color:#3f7cff", data);
      context.commit('setShopInfo', data)
      setTimeout(() => {
        let objData = {
          uid: data.id || 0,
          shop_id: 100000000 + data.s_id * 1 || 0,
          shop_name: data.name,
          phone: data.tel,
        }
        localStorage.setItem("dcServerData", JSON.stringify(objData))
      }, 500)
    },
    setNowTimeStr(context, data) {
      clearInterval(timerCont);
      timerCont = null;
      if (localStorage.token) {
        timerCont = setInterval(() => {
          let nowTime = formatTime(new Date(), 'yyyy-MM-dd hh:mm:ss');
          context.commit('updateNowTimeStr', nowTime);
        }, 1000);
      } else {
        clearInterval(timerCont);
        timerCont = null;
        context.commit('updateNowTimeStr', '');
      }
    },
    getStoreInfo(context, data) {
      api.dt_getShopInfo().then(res => {
        if (res.code == 1) {
          context.commit("setShopInfo", res.data);
          localStorage.setItem('scale_type', res.data.scale_type);
          localStorage.setItem('box_type', res.data.box_type);
        }
      })
    },
    getNotifyData(context, data) {
      api.upgradeRemind().then(res => {
        if (res.code == 1 && res.data && res.data.upgrade_note != '') {
          context.commit('updateNotifyContent', res.data.upgrade_note);
          context.commit('updateIsShowNotify', true);
        } else {
          context.commit('updateIsShowNotify', false);
          context.commit('updateNotifyContent', '');
        }
      })
    },
    updateNotifyOrders({ commit }, orders) {
      commit('updateNotifyOrders', orders);
    },
  },
  modules: {}
})

//导出store对象
export default store
