import axios from 'axios'
import router from '../router/index'
import {Toast} from 'vant'
import config_ from '../../config';
import store from '../store';

const service = axios.create({
    baseURL: config_.baseUrl,//正式
    // headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    // 'Access-Token': config_.AccessToken
    // }
})

//token设置
service.interceptors.request.use(function (config) {


    // Do something before request is sent
    let token = window.localStorage.token
    // console.log('token',token)
    if (config.url == config_.httpBaseURL + 'lonLat') { // 上报位置
        config.headers['X-AUTH-TOKEN'] = localStorage.getItem('shop_id');
    } else if (token) {
        config.headers['X-AUTH-TOKEN'] = token
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
service.interceptors.response.use(function (response) {
    // console.log('response.headers,',response.headers)
    console.log('response.code',response.data.code);
    store.commit('updateShowPayTip', false);
    if (response.headers['X-AUTH-TOKEN']) {
        localStorage.setItem('token', response.headers['X-AUTH-TOKEN'])
    }
    if (response.data.code == 401) {
        router.replace({
            path: '/login'
        });
        store.commit('updateIsShowNavtar', false);
        store.commit("updateShopCart", []);
        store.commit("undateCurrentSwee_code", '');
        localStorage.removeItem('token')
        localStorage.removeItem('shop_only_code');
    }
    // 判断异常
    // if (response.data.code != 1) {
    //   Toast(response.data.msg);
    // }
    if (response.data.code == 1001) {
        console.log("弹出授权");
        store.commit('updateShowPayTip', true);
    }
    // if (response.data.code == 1002) {
    //     console.log("弹出提示");
    //     store.commit('updateShowPayTip', true);
    // }
    return response;
}, function (error) {
    // Toast('网络连接失败！')
    // console.log('response error:', error);
    // Toast.fail('网络异常');
    console.log('网络不是太稳定:', error);
    return Promise.reject(error);
});

// service.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
// service.defaults.headers.common['X-AUTH-TOKEN'] = '';
export default function request({url, data = {}, method = 'get'}, dontLoad = true) {
    let load = null;
    // 开始请求 load
    if (!dontLoad) {
        load = Toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 0 //设定持续时间为永久,遇见另一个 Toast 或者使用 clear 方法清除
        });
    }
    return new Promise((resolve, reject) => {
        const options = {
            url,
            method,
        }
        // //console.log(url, method)
        if (method.toLowerCase() === 'get') {
            options.params = data
        } else {
            options.data = data
        }
        service(options).then(res => {
            // 请求完结束 load
            if (!dontLoad) {
                load.clear();
            }
            // 判断异常
            if (res.data.code != 1) {
                Toast(res.data.msg);
            }
            // //console.log('返回数据', res)
            resolve(res.data);
        })
            .catch(error => {
                if (!dontLoad) {
                    load.clear();
                }
                console.error(error)
                reject()
            })
    })
}

