<template>
  <div class="settlement-page">
    <!-- 引入的返回头部件 -->
    <titlebar :name="name" />
    <div class="payway-cont">
      <div class="paywaycont-left">
        <div>
          <div class="orderinfo">
            <div class="mg-bt30">订单号：{{ order_num }}</div>
            <div>
              <span>原价金额：</span>
              <span class="ft-24">￥</span>
              <span class="ft-40 ft-bold">{{ price }}</span>
            </div>
          </div>
          <div class="priceinfo">
            <div v-if="coupon_price > 0" class="pricedetail">
              <span>优惠券：{{ choosed_coupon.name }}</span>
              <div class="flex flex-ac">
                -￥{{ coupon_price || "0.00" }}
                <img class="mg-lt10" src="https://cashier.demo.api.dianzhangbao.com.cn/202106/mdsy/close.png" style="width: 3rem; height: 3rem;" @click="clearCoupon" />
              </div>
            </div>
            <div v-if="is_mem_price != 1 && member_price > 0" class="pricedetail">
              <span>会员折扣：{{ member_discount }}折</span><span>-￥{{ member_price || "0.00" }}</span>
            </div>
            <div v-if="is_mem_price == 1 && member_price > 0" class="pricedetail">
              <span>会员价优惠：</span><span>-￥{{ member_price || "0.00" }}</span>
            </div>
            <div v-if="rate_price > 0" class="pricedetail">
              <span>打折金额：{{ discount_rate / 10 }}折</span><span>-￥{{ rate_price || "0.00" }}</span>
            </div>
            <div v-if="wipe_price > 0" class="pricedetail">
              <span>抹零金额：</span><span>-￥{{ wipe_price || "0.00" }}</span>
            </div>
            <div v-if="integral_deduction_price > 0" class="pricedetail">
              <span>积分抵扣：</span><span>-￥{{ integral_deduction_price || "0.00" }}</span>
            </div>
            <div class="pricedetail">
              <span>应收金额：</span><span>￥{{ money || "0.00" }}</span>
            </div>
            <div class="pricedetail">
              <span>待收金额：</span><span>￥{{ money }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="paywaycont-right">
        <div class="flex flex-jsb vipimgs" style="align-items: center" @click.stop="openMemModal()">
          <template v-if="Object.keys(vipInfo).length">
            <div @click.stop="clearVip(); clearCoupon();" class="vip-clear-box">
              <img style="z-index: 999" src="@/static/images/close.png" />
            </div>
            <div class="flex" style="justify-content: left">
              <div>
                <div class="color-fff ft-32 pd-bt30">
                  {{ vipInfo.name }}
                </div>
                <div class="color-fff ft-32">
                  <span class="va-middle">{{ vipInfo.phone }}</span>
                  <button class="recharge-btn" @click.stop="openRechargeModal()">充值</button>
                </div>
              </div>
            </div>
            <div class="flex flex-column flex-ae flex-jc">
              <div class="price-font2">
                <span class="ft-24">￥</span>
                <span class="ft-40 ft-bold">{{ vipInfo.balance || '0.00' }}</span>
              </div>
              <div class="ft-24 color-fff mg-tp25">积分：{{ can_use_integral }}</div>
              <!-- <button class="recharge-btn" @click.stop="openRechargeModal">充值</button> -->
            </div>
          </template>
          <div class="vip_icon_add" v-else>
            <van-icon name="plus" color="#fff" size="4rem" />
            <span class="color-fff ft-38 mg-lt10">选择会员</span>
          </div>
        </div>

        <div class="actionbtns">
          <div class="mg-tp30">
            <button class="action-btn mg-rt50 bg-FE724F color-fff" @click="discountAction(1)">打折</button>
            <button class="action-btn bg-1D5CFA color-fff mg-rt50" :class="{
                'bg-F2F2F2 color-c8c9cc': !Object.keys(vipInfo).length || !open_deduction
              }" @click="openIntegralDeduction()">积分抵扣</button>
            <button class="action-btn bg-3DB956 color-fff" @click="discountAction(2)">抹零</button>
          </div>
          <span class="color-666 ft-20 text-underline mg-tp30" @click="careful_matter">打折抹零注意事项</span>
        </div>

        <div class="paywaycont-right-content">
          <span class="payment-method">选择支付方式</span>
          <div class="payway-list">
            <div class="content-item flex flex-ac flex-jc" @click="choosePayWay(item.pay_index, item)" v-for="(item, index) in payWayList" :key="item.pay_index" :class="{
                'mg-rt25': index != payWayList.length - 1,
                'active-way': payTypeId == item.pay_index,
              }">
              <van-icon v-if="item.pay_index == 3" name="gold-coin" :color="is_can_submit ? (payTypeId == item.pay_index ? '#fff' : '#1588F5') : '#c8c9cc'" size="4rem" />
              <van-icon v-if="item.pay_index == 6" name="diamond" :color="is_can_submit ? (payTypeId == item.pay_index ? '#fff' : '#1588F5') : '#c8c9cc'" size="4rem" />
              <van-icon v-if="item.pay_index == 7" name="scan" :color="is_can_submit ? (payTypeId == item.pay_index ? '#fff' : '#1588F5') : '#c8c9cc'" size="4rem" />
              <div class="ft-30 mg-lt20" :class="{'color-c8c9cc': !is_can_submit,}">
                {{ item.payment }}
              </div>
            </div>
          </div>
          <div class="width-full flex flex-ac mg-bt30" v-if="showInput">
            <input v-model="paycode" placeholder="请在输入框光标闪烁时扫描付款码" ref="paycodeInput" class="paycode-input" @focus="notKeyboard" :disabled="isdisabled" @blur="paycodeBlur" @keyup.enter="offlinePay" />
            <button class="clearcode-btn" @click="clearCode">清空</button>
          </div>
          <div class="width-full flex flex-ac mg-bt50" v-if="showInput">
            <span class="mg-rt25">扫码支付可使用</span>
            <div class="flex flex-ac mg-rt20"><van-icon name="wechat-pay" color="#09BB07" size="4rem" class="mg-rt7" />微信</div>
            <div class="flex flex-ac mg-rt20"><van-icon name="alipay" color="#02A9F1" size="4rem" class="mg-rt7" />支付宝</div>
            <div class="flex flex-ac mg-rt20"><img class="mg-rt15" style="width: 3.1rem; height: 3.1rem;" src="@/static/images/yunshanfu.png" />云闪付</div>
          </div>
          <div class="width-full flex flex-ac mg-bt50 flex-jsb" v-if="payTypeId == 3">
            <div class="flex flex-ac" style="width: 49%;">
              <span>收款￥</span>
              <input placeholder="请输入收款金额" class="paycode-input price-code-input" v-model="collection" readonly type="Number" @click="isShowNumKeyboard3 = true; collection = '';" @blur="collechange();isShowNumKeyboard3 = false;" />
            </div>
            <div class="flex flex-ac" style="width: 49%;">
              <span>找零￥</span>
              <input type="Number" v-model="give_change" class="paycode-input price-code-input" disabled />
            </div>
          </div>
        </div>

        <div style="height: 10.2rem;"></div>

        <div class="flex flex-ac flex-jc surepay-btn-box">
          <button class="color-fff bd-rd10 coupon-c-btn" :disabled="!is_can_submit || !Object.keys(vipInfo).length" :class="{'disabled_surepay-btn': !is_can_submit || !Object.keys(vipInfo).length}" @click="openCouponModal">优惠券({{ c_count }})</button>
          <button class="bg-1588F5 color-fff bd-rd10 surepay-btn" :disabled="!is_can_submit" :class="{'disabled_surepay-btn': !is_can_submit}" @click="pay">
            <span class="ft-28">确认收款</span>
            <span>
              <span>￥</span>
              <span class="ft-38">{{ money || '0.00' }}</span>
            </span>
          </button>
        </div>
      </div>
    </div>

    <van-popup v-model="vipModal" :close-on-click-overlay="false" @closed="vipInfoShow = []; vipTel = ''" :round="true">
      <div class="vip-modal">
        <div class="vip-modal-head">
          <div class="vip-modal-head-title">会员选择</div>
          <img src="https://cashier.demo.api.dianzhangbao.com.cn/202106/mdsy/close.png" @click="vipModal = false" />
        </div>
        <div class="vip-modal-body">
          <div class="vip-modal-body-input">
            <input v-model="vipTel" placeholder="手机号搜索" ref="membercodeinput" @keyup.enter="vipTelSearch" />
            <van-button color="#1588f5" @click="vipTelSearch">搜索</van-button>
          </div>
          <div class="vip-modal-body-info" v-if="vipInfoShow.length > 0">
            <div v-for="(item, index) in vipInfoShow" class="vipinformation flex flex-jsb flex-ac"
              :class="{'choosed1': choose_index == index,}"
              @click="confirmMemChoose(index)"
            >
              <div>
                <div class="mg-bt15">{{ item.name }}</div>
                <div>{{ item.phone }}</div>
              </div>
              <div>￥{{ item.balance }}</div>
            </div>
          </div>
          <div class="vip-modal-body-info" v-if="is_show_notdata">
            <div class="nullStyle2">
              <p class="text-center ft-24 color-999">抱歉，暂无搜索结果</p>
              <img src="@/static/images/zanwu.png" alt="" />
            </div>
          </div>
          <div style="height: 9rem;"></div>
          <!-- 确认/取消 -->
          <div class="btns flex flex-jc">
            <button class="cancel" @click="vipModal = false">取消</button>
            <button class="confirm" @click="toAddMem">新增会员</button>
          </div>
        </div>
      </div>
    </van-popup>

    <!-- 收款金额数字键盘 -->
    <van-number-keyboard v-model="collection" :title="collection" :show="isShowNumKeyboard3" extra-key="." @blur="collechange();isShowNumKeyboard3 = false;" class="otherEatNum-nk" />

    <popup-dialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      @closePopDialog="closePopDialog"
      @confirmPopDialog="confirmPopDialog"
      :showCancelBtn="showDialogCancelBtn"
      :confirmText="dialogConfirmText"
    ></popup-dialog>

    <div class="integral-deduction-modal" v-if="show_deduction_modal">
      <div class="modal-content-box relative" style="z-index: 2002;">
        <img src="@/static/images/close.png" class="close" alt srcset @click="closeIntegralDeduction" />

        <div class="wrapper">
          <!-- 标题 -->
          <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">积分抵扣</div>

          <div class="deduction-content">
            <div class="mg-bt50">
              <span class="mg-rt60">可用积分</span>
              <span class="color-1588F5">{{ can_use_integral }}</span>
            </div>
            <div class="flex flex-ac">
              <div class="mg-rt50">
                本次使用积分
                <input class="use-integral-input" :value="use_integral_num" readonly @click="openIntegralNum()" />
              </div>
              <div>
                <span class="mg-rt60">抵扣金额</span>
                <span class="color-1588F5">{{ use_deduction_price }}</span>
              </div>
            </div>
            <div style="height: 9rem;"></div>
          </div>
        </div>
        <!-- 确认/取消 -->
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeIntegralDeduction">取消</button>
          <button class="confirm " @click="confirmIntegralDeduction">确认</button>
        </div>
      </div>

      <!-- 本次使用积分 -->
      <van-number-keyboard v-model="use_integral_num" :title="use_integral_num" :show="show_use_num" extra-key="." @blur="useNumchange();show_use_num = false;" class="otherEatNum-nk" />
    </div>
    <!-- 充值开始 -->
    <div class="pplAmount van-popup van-popup--center" v-show="is_show_recharge">
      <img class="closeImg" src="@/static/images/close.png" @click="is_show_recharge = false; isShowMask = false;" />
      <div class="title">充值</div>
      <div class="line"></div>
      <div class="ft-28 bg-fff pd-tp10 pd-rt20 pd-bt10 mg-tp30 text-left" style="width: 84%;">
        <span class="ft-bold">选择充值金额：</span>
      </div>
      <div style="width: 84%">
        <div class="flex flex-jsb mg-tp20">
          <div>
            <span class="ft-26">充值金额</span>
            <input  placeholder="请输入金额"  v-model="amount" readonly  @click="isShowNumKeyboard = true; amount = '';" />
          </div>
          <div>
            <span class="ft-26">赠送金额</span>
            <input  placeholder="请输入金额"  v-model="give_amount" readonly @click="isShowNumKeyboard1 = true; give_amount = '';" />
          </div>
        </div>
      </div>
      <div class="ft-28 bg-fff pd-tp10 pd-rt20 pd-bt10 mg-tp30 text-left" style="width: 84%;">
        <span class="ft-bold">选择支付方式：</span>
      </div>
      <!-- 支付方式 -->
      <div class="payway-list">
        <div class="content-item flex flex-ac flex-jc" @click="choosePayWayRecharge(item.pay_index, item)" v-for="(item, index) in payWayListRecharge" :key="item.pay_index" :class="{
            'mg-rt70': index != payWayListRecharge.length - 1,
            'active-way': payTypeIdRecharge == item.pay_index,
          }">
          <van-icon v-if="item.pay_index == 6" name="gold-coin" :color="payTypeIdRecharge == item.pay_index ? '#fff' : '#1588f5'" size="4rem" />
          <van-icon v-if="item.pay_index == 7" name="scan" :color="payTypeIdRecharge == item.pay_index ? '#fff' : '#1588f5'" size="4rem" />
          <div class="ft-28 mg-lt25">
            {{ item.payment }}
          </div>
        </div>
      </div>
      <div class="flex flex-ac" style="width: 84%; margin: 1.5rem 0 3rem 0;" v-if="showInputRecharge">
        <input placeholder="请在输入框光标闪烁时扫描付款码" ref="paycodeInputRecharge" v-model="paycodeRecharge" autofocus class="paycode-input" @keyup.enter="payRecharge" />
        <button class="clearcode-btn" @click="clearCodeRecharge">清空</button>
      </div>
      <div class="width-full flex flex-ac mg-bt30 flex-jsb mem-paymoney" v-if="payTypeIdRecharge == '6'">
        <div class="flex flex-ac" style="width: 49%;">
          <span>收款￥</span>
          <input class="paycode-input price-code-input" placeholder="请输入金额" v-model="collection_recharge" readonly @click="isShowNumKeyboard2 = true; collection_recharge = '';" />
        </div>
        <div class="flex flex-ac" style="width: 49%;">
          <span>找零￥</span>
          <input type="Number" v-model="give_change_recharge" autofocus class="paycode-input price-code-input" disabled />
        </div>
      </div>
      <div class="line"></div>
      <div class="flex confirm">
        <div class="cancel" @click="pay_cancel">
          <p>取消</p>
        </div>
        <div class="ok" @click="payRecharge">
          <p>手动确认</p>
        </div>
      </div>
    </div>
    <!-- 充值结束 -->
    <!-- 黑色蒙版 -->
    <van-overlay :show="isShowMask" z-index="1000" />
    <!-- 充值金额 -->
    <van-number-keyboard v-model="amount" :title="amount" :show="isShowNumKeyboard" @blur="isShowNumKeyboard = false;" extra-key="."
      @input="amountChange" @delete="handleamountDelete" class="otherEatNum-nk" />
    <!-- 赠送金额 -->
    <van-number-keyboard v-model="give_amount" :title="give_amount" :show="isShowNumKeyboard1" @blur="isShowNumKeyboard1 = false;"
      extra-key="." class="otherEatNum-nk" />
    <!-- 收款金额 -->
    <van-number-keyboard v-model="collection_recharge" :title="collection_recharge" :show="isShowNumKeyboard2"
      @blur="collechangeRecharge(); isShowNumKeyboard2 = false;" extra-key="." class="otherEatNum-nk" />

    <!-- 打折抹零 -->
    <div class="setdiscount-modal" v-if="show_discount_modal">
      <div class="modal-content-box relative">
        <img class="close" src="@/static/images/close.png" @click="
            show_discount_modal = false;
            isShowMask = false;
          " />
        <div class="wrapper">
          <div :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">{{ modalTitle }}</div>

          <div class="discount-box-content">
            <div v-if="modaltype == 'dazhe'">
              <div class="flex flex-ac flex-jc">
                <span>折扣：</span>
                <!-- <input
                  v-model="atDiscount"
                  type="number"
                  class="diinput mg-rt20"
                  ref="atDiscountInput"
                  placeholder="输入数字,如:9"
                  step="0.1"
                  :min="0"
                  :max="10"
                /> -->
                <input :value="atDiscount" class="diinput mg-rt20" ref="atDiscountInput" placeholder="输入数字,如:9" readonly @click="show_discount_keyboard = true;" />
                <span>折</span>
              </div>
              <div v-if="errorMsg != ''" class="ft-20 color-e60012 mg-tp10 text-center">{{ errorMsg }}</div>
            </div>
            <div v-if="modaltype == 'moling'">
              <div class="flex flex-ac flex-jc">
                <span>抹零金额：</span>
                ￥
                <!-- <input
                  v-model="staff_discount"
                  type="number"
                  class="diinput"
                  ref="staffDiscountInput"
                  step="0.01"
                  :min="0.01"
                /> -->
                <input :value="staff_discount" class="diinput" ref="staffDiscountInput" readonly @click="show_wipe_keyboard = true;" />
              </div>
              <div v-if="errorMsg != ''" class="ft-20 color-e60012 mg-tp10 text-center">{{ errorMsg }}</div>
            </div>
          </div>
        </div>
        <div style="height: 9rem;"></div>
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeModal">取消</button>
          <button class="confirm " @click="submitCate">确认</button>
        </div>

        <!-- 打折数字键盘 -->
        <van-number-keyboard v-model="atDiscount" :title="atDiscount" :show="show_discount_keyboard" extra-key="." @blur="show_discount_keyboard = false;" class="otherEatNum-nk" />
        <!-- 抹零数字键盘 -->
        <van-number-keyboard v-model="staff_discount" :title="staff_discount" :show="show_wipe_keyboard" extra-key="." @blur="show_wipe_keyboard = false;" class="otherEatNum-nk" />
      </div>
    </div>

    <!-- 选择优惠券 开始 -->
    <div class="choose-coupon-modal" v-if="show_choose_coupon">
      <div class="modal-content-box relative" style="z-index: 2002;">
        <img src="@/static/images/close.png" class="close absolute" alt srcset @click="closeCouponModal" />

        <div class="wrapper">
          <!-- 标题 -->
          <div class="title color-333 ft-28 flex flex-ac flex-jc ft-bold' pd-bt25">选择优惠券</div>

          <div class="mocontent">
            <div class="deskcontent">
              <div class="index_list">
                <div v-for="(item, i) in modal_coupon_list" :key="i" class="index_box relative"
                  @click="chooseThatCoupon(item)"
                  :class="{
                    'disabled-coupon': !item.can_use
                  }">
                  <van-checkbox v-if="item.can_use" class="del-checkbox" icon-size="2.6rem" :value="select_coupon.id == item.id" shape="square" />
                  <div class="coupon-left">
                    <div class="color-1588F5 mg-bt15">
                      <span class="mg-rt5 ft-38 ft-bold">{{ item.face }}</span>
                      <span class="ft-22" v-if="item.type == 1" style="vertical-align: super;">元</span>
                      <span class="ft-22" v-if="item.type == 2" style="vertical-align: super;">折</span>
                    </div>
                    <div class="ft-20 color-333">满{{ item.cill }}可用</div>
                  </div>
                  <div style="flex: 1;" class="pd-lt20 pd-rt5">
                    <div class="ft-22 color-333 mg-bt25">{{ item.name }}</div>
                    <!-- <div v-if="item.period == 1" class="ft-20 color-666">领券后{{ item.day }}天内有效</div> -->
                    <div v-if="item.period == 1 || item.period == 2" class="ft-20 color-666">有效期：{{ item.start_time }} 至 {{ item.end_time }}</div>
                    <div v-if="item.period == 3" class="ft-20 color-666">有效期：长期有效</div>
                  </div>
                </div>
              </div>
              <div class="flex mg-tp10 mg-bt10 mg-rt20" style="justify-content:flex-end;">
                <div class="pagination" v-if="m_count > 0">
                  <van-pagination v-model="m_page" :total-items="m_count" :items-per-page="m_limit" mode="simple" force-ellipses @change="getCouponList" style="background-color: #f2f2f2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 9rem;"></div>
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeCouponModal">取消</button>
          <button class="confirm " @click="confirmCouponModal()">确认</button>
        </div>
      </div>
    </div>
    <!-- 选择优惠券 结束 -->
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { Dialog, Toast } from "vant";
// import { mapState, mapMutations } from 'vuex';
import popupOfDialog from '@/views/component/popupOfDialog.vue';
import { formatTime, getPayWayFromCode } from '../../utils/util';
import { initHCDScannerDecode, openBox } from '../../utils/outPrint';
import { mapGetters } from 'vuex';

export default {
  created() {
    this.name = this.$route.query.name || ""; // 拿到传过来的标题
    this.type = this.$route.query.type * 1 || 2;
    this.proList = this.type == 2 ? this.shopCartList : this.areCancellProList;
    console.log("%c Line:243 🥑 this.proList", "color:#7f2b82", this.proList);
    this.id = this.$route.query.id || undefined;
    this.getIntegtalSetting();
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    })
    this.getPrice();
    this.getOrder(); //生成订单
    if (Object.keys(this.vipInfo).length) {
      this.payTypeId = 6;
    } else {
      this.payTypeId = 3;
    }
    this.$store.commit('updateHcdBarcode', '');
    if (Object.keys(this.vipInfo).length) {
      this.getCouponList();
    }
  },
  components: {
    titlebar,
    'popup-dialog': popupOfDialog,
  },
  data() {
    return {
      name: "",
      type: "", // 1挂单 2支付 3挂单支付
      proList: [], // 要下单的商品列表
      price: 0, // 原价金额
      dz_price: 0, //应收金额
      order_num: "", //订单编号
      overPay: true, // 控制付款请求是否结束
      money: "", // 实收金额
      payStatus: 1, // 支付状态
      id: "",
      money2: '', // 支付的现金用于计算找零
      vipModal: false, // 选择vip的弹框
      vipTel: undefined, // vip搜索的手机号
      vipInfoShow: [], // vip弹框的vip信息显示
      is_can_submit: false,
      payWayList: [
        { pay_index: "3", payment: "现金" },
        { pay_index: "6", payment: "会员余额" },
        { pay_index: "7", payment: "扫码支付" },
      ],
      payTypeId: "",
      isdisabled: false,
      paycode: "", // 付款码
      showInput: false,
      collection: '0', //收款
      isShowNumKeyboard3: false,
      showDialog: false,
      dialogType: "",
      dialogTitle: "",
      dialogMsg: "",
      dialogData: {},
      showDialogCancelBtn: true,
      dialogConfirmText: '确认',
      member_price: 0, // 会员优惠金额
      is_mem_price: 0, // 是否启用会员价
      choose_index: -1,
      order_id: '',
      is_show_notdata: false,
      is_click: false,
      integral_deduction_price: 0, // 积分抵扣金额
      show_deduction_modal: false,
      use_integral_num: '', // 本次使用积分
      // can_use_integral: 0, // 可使用积分
      // deduction_threshold: 8, // 积分抵扣条件：消耗[X]积分，抵扣Y元
      deduction_num: 0, // 积分抵扣比例：消耗X积分，抵扣[Y]元
      use_deduction_price: 0, // 弹窗内抵扣金额计算
      show_use_num: false,
      open_deduction: false, // 是否开启积分抵扣
      is_show_recharge: false, //充值弹窗
      isShowMask: false, //是否显示遮罩层
      payWayListRecharge: [
        { pay_index: "6", payment: "现金" },
        { pay_index: "7", payment: "扫码支付" },
      ], //支付方式
      payTypeIdRecharge: "",
      showInputRecharge: false,
      paycodeRecharge: "", // 付款码
      amount: "",
      amountVals: [],
      give_amount: "",
      isClick: false,
      collection_recharge: '', //收款
      isShowNumKeyboard: false,
      isShowNumKeyboard1: false,
      isShowNumKeyboard2: false,
      atDiscount: "", // 打折输入
      staff_discount: "", // 抹零输入
      wipe_price: '0', // 抹零金额
      discount_rate: 100, // 折扣率
      show_discount_modal: false,
      modalTitle: "",
      modaltype: "",
      errorMsg: '',
      rate_price: 0, // 整单打折金额
      // real_price: 0, // 实收里面不包括积分抵扣的金额
      show_discount_keyboard: false,
      show_wipe_keyboard: false,
      c_count: 0,
      show_choose_coupon: false,
      select_coupon: {},
      choosed_coupon: {},
      coupon_code: '',
      coupon_price: 0, // 优惠券优惠金额
      modal_coupon_list: [],
      m_page: 1, // 当前页数
      m_limit: 6,
      m_count: 0,
    };
  },
  computed: {
    ...mapGetters({ hcd_barcode: 'hcd_barcode' }),
    // 购物车总额
    totalPrice() {
      return this.$store.getters.totalPrice;
    },
    // 购物车会员价总额
    totalMemberPrice() {
      return this.$store.getters.totalMemberPrice;
    },
    // 购物车列表
    shopCartList() {
      return this.$store.state.shopCartList;
    },
    // 挂单商品列表
    areCancellProList() {
      return this.$store.state.areCancellProList;
    },
    //唯一编码
    currentSwee_code() {
      return this.$store.state.currentSwee_code;
    },
    // 挂单订单编号
    // ...mapState(['areCancellOrderNum'])
    vipInfo() { // 选中的会员信息
      return this.$store.state.vipInfo
    },
    // 找零
    give_change() {
      if (this.collection) {
        let price = Number(this.collection - this.money).toFixed(2);
        return price;
      }
      return "0.00";
    },
    // 会员折扣
    member_discount() {
      if (Object.keys(this.vipInfo).length) {
        return this.$bigN(this.vipInfo.rate).div(10).toNumber();
      }
      return 10;
    },
    // 可使用积分
    can_use_integral() {
      return this.vipInfo.score || 0;
    },
    // 找零
    give_change_recharge() {
      if (this.collection_recharge && this.collection_recharge > this.amount) {
        let price = Number(this.collection_recharge - this.amount).toFixed(2);
        return price;
      }
      return "0.00";
    },
    platform_name() {
      return localStorage.nowDevice;
    },
  },
  watch: {
    hcd_barcode: {
      handler(newV, oldV) {
        console.log('this.hcd_barcode', this.hcd_barcode);
        if (this.hcd_barcode.code == 1) {
          console.log('this.showInput', this.showInput);
          console.log('this.showInputRecharge', this.showInputRecharge);
          if (this.showInput) {
            this.paycode = this.hcd_barcode.data;
            console.log('this.paycode', this.paycode);
            this.$forceUpdate();
          }
          if (this.showInputRecharge) {
            this.paycodeRecharge = this.hcd_barcode.data;
            this.$forceUpdate();
          }
        }
      },
      deep: true,
    },
    'vipInfo.id': {
      handler(newV, oldV) {
        this.getCouponList();
      },
      deep: true,
    }
  },
  // destroyed () {
  //     this.updateAreCancellOrderNum(''); // 清空挂单订单编号
  // },
  methods: {
    toAddMem() {
      this.$router.push({
        name: 'addMember',
        params: { type: 'settlement' },
        query: {
          name: 'addMember'
        }
      })
    },
    clearCoupon() {
      this.choosed_coupon = {};
      this.coupon_code = '';
      this.coupon_price = 0;
      this.getPrice();
    },
    chooseThatCoupon(item) {
      if (!item.can_use) {
        return;
      }
      this.select_coupon = item;
    },
    async confirmCouponModal() {
      this.choosed_coupon = this.select_coupon;
      this.coupon_code = this.select_coupon.coupon_code;
      this.closeCouponModal();
      this.getPrice();
    },
    closeCouponModal() {
      this.show_choose_coupon = false;
    },
    async openCouponModal() {
      this.m_page = 1;
      this.select_coupon = this.choosed_coupon;
      this.coupon_code = this.choosed_coupon.coupon_code;
      await this.getCouponList();
      this.show_choose_coupon = true;
    },
    getCouponList() {
      return new Promise((resolve, reject) => {
        let params = {
          type: 0,
          page: this.m_page,
          limit: this.m_limit,
          uid: this.vipInfo.id,
          time: formatTime(new Date(), 'yyyy-MM-dd'),
          price: this.money,
        }
        this.$api.Coupon_memberCoupon(params).then(res => {
          if (res.code == 1) {
            const list = res.data.data || [];
            const count = res.data.count || 0;
            this.modal_coupon_list = list;
            this.m_count = count;
            this.c_count = count;
            this.modal_coupon_list.forEach(element => {
              // console.log('element.cill', element.cill, 'this.money', this.money, element.cill * 1 <= this.money * 1);
              if (element.period == 2) {
                const s_time = new Date(element.start_time).getTime();
                const e_time = new Date(element.end_time).getTime();
                const now_time = new Date().getTime();
                if (element.cill * 1 <= this.money * 1 && s_time <= now_time && now_time <= e_time) {
                  element['can_use'] = true;
                } else {
                  element['can_use'] = false;
                }
              } else {
                if (element.cill * 1 <= this.money * 1) {
                  element['can_use'] = true;
                } else {
                  element['can_use'] = false;
                }
              }
              if (element.period == 1) {
                element.start_time = formatTime(new Date(element.create_time), 'yyyy-MM-dd');
                element.end_time = formatTime(new Date(element.expire_time), 'yyyy-MM-dd');
              } else if (element.period == 2) {
                element.start_time = formatTime(new Date(element.start_time), 'yyyy-MM-dd');
                element.end_time = formatTime(new Date(element.end_time), 'yyyy-MM-dd');
              }
            });
            console.log(this.modal_coupon_list);
            resolve();
          } else {
            this.$toast(res.msg);
          }
        })
      })
    },
    // 现金支付打开钱箱
    openDevieBox() {
      if (this.platform_name == 'android' || this.platform_name == 'mt_android') {
        openBox();
      }
    },
    // 注意事项
    careful_matter() {
      this.dialogTitle = '注意事项';
      this.dialogMsg = '';
      this.dialogType = '2';
      this.dialogData = {
        'msgs': [
          '1、折扣优先级为：会员折扣>打折>积分抵扣>抹零。',
          '2、修改打折数值会清空积分抵扣和抹零；修改积分抵扣会清空抹零。',
        ]
      };
      this.showDialogCancelBtn = false;
      this.dialogConfirmText = '我知道了';
      this.showDialog = true;
    },
    clearRateOrWipe(type) {
      if (type == 1) { // 修改会员清空打折、抹零、积分抵扣、优惠券
        this.atDiscount = '';
        this.errorMsg = '';
        this.discount_rate = '';
        this.rate_price = 0;
        this.wipe_price = '0';
        this.staff_discount = '0';
        this.use_integral_num = '';
        this.use_deduction_price = 0;
        this.integral_deduction_price = 0;
        this.choosed_coupon = {};
        this.coupon_price = 0;
        this.getPrice();
      } else if (type == 2) { // 修改打折清空抹零、积分抵扣
        this.wipe_price = '0';
        this.staff_discount = '0';
        this.use_integral_num = '';
        this.use_deduction_price = 0;
        this.integral_deduction_price = 0;
        this.choosed_coupon = {};
        this.coupon_price = 0;
        this.getPrice();
      } else if (type == 3) { // 修改抹零
        // this.use_integral_num = '';
        // this.use_deduction_price = 0;
        // this.integral_deduction_price = 0;
        this.choosed_coupon = {};
        this.coupon_price = 0;
        this.getPrice();
      } else if (type == 4) { // 修改积分抵扣清空抹零
        this.wipe_price = '0';
        this.staff_discount = '0';
        this.choosed_coupon = {};
        this.coupon_price = 0;
        this.getPrice();
      }
    },
    // 提交打折
    submitAtDiscount() {
      if (Number(this.atDiscount) < 0 || Number(this.atDiscount) > 10 || !/^[0-9]+([.]{1}[0-9]{1})?$/.test(this.atDiscount)) {
        this.errorMsg = '请输入0~10内的数字，最多一位小数'
        return;
      }
      this.errorMsg = '';
      this.discount_rate = this.atDiscount * 10;
      this.wipe_price = '0';
      this.staff_discount = '0';
      this.clearRateOrWipe(2);
      // this.getOrder();
      this.show_discount_modal = false;
    },
    // 提交抹零
    submitStaffDiscount() {
      // console.log(this.staff_discount);
      if (this.staff_discount != '' && this.staff_discount > Number(this.money)) {
        this.errorMsg = '抹零金额不能大于待收金额';
        return;
      }
      if (this.staff_discount != '' && this.staff_discount < 0) {
        this.errorMsg = '抹零金额不能小于0';
        return;
      }
      if (isNaN(this.staff_discount)) {
        this.wipe_price = '0';
        this.closeModal();
        return;
      }
      if (this.staff_discount != '' && !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.staff_discount)) {
        this.errorMsg = '抹零金额必须为数字，小数点后最多两位';
        return
      }
      this.errorMsg = '';
      this.wipe_price = this.staff_discount || '0';
      this.clearRateOrWipe(3);
      // this.getOrder();
      this.closeModal();
    },
    submitCate() {
      if (Object.keys(this.choosed_coupon).length) {
        this.showDialog = true;
        this.dialogTitle = "提示";
        this.dialogType = this.modaltype;
        this.dialogMsg = '修改打折、积分抵扣、抹零会清空已选中的优惠券，可重新选择优惠券。';
        return;
      }
      switch (this.modaltype) {
        case "dazhe":
          this.submitAtDiscount();
          break;
        case "moling":
          this.submitStaffDiscount();
          break;
      }
    },
    closeModal() {
      if (this.modaltype == "dazhe") {
        if (this.discount_rate == "100" || this.discount_rate == "") {
          this.atDiscount = "";
        } else {
          this.atDiscount = (this.discount_rate / 10).toString();
        }
      }
      this.show_discount_modal = false;
      this.errorMsg = '';
    },
    // 打折或抹零
    discountAction(type) {
      if (type == 1) {
        this.show_discount_modal = true;
        this.modalTitle = "打折";
        this.modaltype = "dazhe";
        this.$nextTick(() => {
          this.$refs.atDiscountInput.focus();
        }, 100);
        this.show_discount_keyboard = true;
      } else if (type == 2) {
        // console.log(this.await_amount);
        this.show_discount_modal = true;
        this.modalTitle = "抹零";
        this.modaltype = "moling";
        let moling = this.money.toString().split(".");
        if (moling[1] == "00") {
          this.staff_discount = '0';
        } else {
          this.staff_discount = "0." + moling[1];
        }
        if (isNaN(this.staff_discount)) {
          this.staff_discount = '';
        }
        this.$nextTick(() => {
          this.$refs.staffDiscountInput.focus();
        }, 100);
        this.show_wipe_keyboard = true;
      }
    },
    amountChange(e) {
      this.amountVals.push(e);
      this.collection_recharge = this.amountVals.join('');
    },
    handleamountDelete(e) {
      this.amountVals.pop();
      this.collection_recharge = this.amountVals.join('');
    },
    getMemberInfo(id) {
      this.$api.dt_getMemberList({ id: id }).then((res) => {
        if (res.code == 1) {
          const form = res.data.data.list[0];
          this.$store.commit('setVipInfo', form);
        } else {
          this.$toast(res.msg);
        }
      });
    },
    tj_cz(params) {
      console.log(params);
      this.$api.dt_memberRecharge(params).then((res) => {
        this.$toast.clear();
        this.$toast(res.msg);
        if (res.code == 1) {
          this.is_show_recharge = false;
          this.isShowMask = false;
          if (params.pay_type == '6') {
            this.openDevieBox();
          }
          this.getMemberInfo(this.vipInfo.id);
          this.rechargePrinterPrint(res.data, params.pay_type);
        }
        this.isClick = false;
      }).catch(() => {
        this.$toast.clear();
        this.isClick = false;
      });
    },
    rechargePrinterPrint(data, pay_type) {
      console.log(data);
      if (data.recharge_print == 1) {
        const p_data = data.data || {};
        let result = {
          type: 3,
          shopName: p_data.shop_name || '',
          shop_admin_name: p_data.casher_name || '',
          recharge_time: p_data.recharge_time || '',
          recharge_no: p_data.recharge_num || '',
          member_name: p_data.member_name || '',
          member_phone: p_data.member_phone || '',
          amount: p_data.amount || '',
          give_amount: p_data.give_amount || '',
          recharge_before: p_data.before_amount || '',
          recharge_after: p_data.after_amount || '',
          pay_type_text: this.getPayTypeText(pay_type),
          shop_address: p_data.address || '',
          shop_phone: p_data.shop_phone || '',
        }
        this.$printFunc.printReceipt(result);
      }
    },
    getPayTypeText(pay_type) {
      const obj = {
        1: '微信',
        2: '支付宝',
        4: '云闪付',
        6: '现金',
      }
      return obj[pay_type] || '';
    },
    // 支付
    payRecharge() {
      if (this.amount == '') {
        this.$toast('充值金额不能为空');
        return
      }
      if (this.amount < 0 || this.give_amount < 0) {
        this.$toast("充值金额不能为负数！");
        return;
      }
      if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.amount)) {
        this.$toast("充值金额必须为数字，小数点后最多两位");
        return;
      }
      if (this.give_amount && this.give_amount != '' && this.give_amount > 0 && !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.give_amount)) {
        this.$toast("赠送金额必须为数字，小数点后最多两位");
        return;
      }
      if (!this.payTypeIdRecharge || this.payTypeIdRecharge == '') {
        this.$toast('请选择支付方式');
        return
      }
      if (this.isClick) {
        return
      }
      if (this.payTypeIdRecharge == 1 || this.payTypeIdRecharge == 2 || this.payTypeIdRecharge == 7 || this.payTypeIdRecharge == 4) {
        // 微信、支付宝
        this.offlinePayRecharge();
        return;
      }
      this.moneyPayRecharge();
    },
    initHcdScan() {
      if (this.platform_name == 'android') {
        this.$store.commit('updateHcdBarcode', {});
        initHCDScannerDecode('starthcdscan');
      }
    },
    // 扫描支付码支付
    offlinePayRecharge() {
      if (this.paycodeRecharge == "") {
        this.$toast("请扫描付款码进行结账");
        this.initHcdScan();
        this.$nextTick(() => {
          this.$refs.paycodeInputRecharge.focus();
          this.$refs.paycodeInputRecharge.setAttribute("readonly", "readonly");
          setTimeout(() => {
            this.$refs.paycodeInputRecharge.removeAttribute("readonly");
          }, 200);
        }, 100);
        return;
      }
      const pay_type = getPayWayFromCode(this.paycodeRecharge);
      this.isClick = true;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let params = {
        id: this.vipInfo.id,
        amount: this.amount,
        give_amount: this.give_amount || '0',
        pay_type: pay_type,
        pay_bar_code: this.paycodeRecharge,
        line_type: 0,
      }
      this.tj_cz(params);
    },
    // 现金确认
    moneyPayRecharge() {
      this.isClick = true;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let params = {
        id: this.vipInfo.id,
        amount: this.amount,
        give_amount: this.give_amount || '0',
        pay_type: this.payTypeIdRecharge,
        line_type: 0,
      }
      this.tj_cz(params);
    },
    // 充值取消
    pay_cancel() {
      this.is_show_recharge = false;
      this.isShowMask = false;
      this.showInputRecharge = false;
      this.amount = "";
      this.give_amount = "";
      this.paycodeRecharge = "";
      this.$store.commit('updateHcdBarcode', '');
    },
    choosePayWayRecharge(id, item) {
      this.payTypeIdRecharge = id;
      this.pay_type_text = item.payment;
      this.showInputRecharge = false;
      if (this.payTypeIdRecharge == 1 || this.payTypeIdRecharge == 2 || this.payTypeIdRecharge == 7 || this.payTypeIdRecharge == 4) {
        this.showInputRecharge = true;
        this.initHcdScan();
        this.$nextTick(() => {
          this.$refs.paycodeInputRecharge.focus();
          this.$refs.paycodeInputRecharge.setAttribute("readonly", "readonly");
          setTimeout(() => {
            this.$refs.paycodeInputRecharge.removeAttribute("readonly");
          }, 200);
        }, 100);
      } else if (this.payTypeIdRecharge == 6) {
        this.collection_recharge = this.amount;
      }
    },
    clearCodeRecharge() {
      this.paycodeRecharge = "";
      this.initHcdScan();
      this.$nextTick(() => {
        this.$refs.paycodeInputRecharge.focus();
        this.$refs.paycodeInputRecharge.setAttribute("readonly", "readonly");
        setTimeout(() => {
          this.$refs.paycodeInputRecharge.removeAttribute("readonly");
        }, 200);
      }, 100);
    },
    // 充值
    openRechargeModal() {
      this.payTypeIdRecharge = "";
      this.is_show_recharge = true;
      this.isShowMask = true;
    },
    collechangeRecharge() {
      if (Number(this.collection_recharge) < Number(this.amount)) {
        this.$toast("收款金额小于待收金额！");
        this.collection_recharge = this.amount;
      }
    },
    printOrder(data, pay_type) {
      if (data.list.print_receipt == 1) {
        let printData = data.list.print_data;
        printData['type'] = 2;
        printData['pay_time'] = data.list.pay_time || '';
        printData['discount_amount'] = this.member_price || '0';
        printData['is_member_price'] = this.is_mem_price;
        if (Object.keys(this.vipInfo).length) {
          this.vipInfo.balance = this.$bigN(this.vipInfo.balance).minus(this.money).toNumber();
          this.vipInfo.balance = this.vipInfo.balance.toString();
        }
        printData['member'] = this.vipInfo || {};
        printData['member_type'] = this.vipInfo || {};
        printData['pay_type'] = pay_type.toString() || '';
        printData['pay_type_text'] = this.getPayType(pay_type);
        printData['price'] = data.list.change_price.toString() || '';
        printData['actual_amount'] = this.money.toString() || '0';
        printData['discount_rate_price'] = this.rate_price.toString() || '0';
        printData['discount_rate_text'] = (this.discount_rate / 10).toString() || '';
        printData['wipe_price'] = this.wipe_price.toString() || '0';
        printData['score_discount'] = this.integral_deduction_price.toString() || '0';
        printData['coupon_price'] = this.coupon_price.toString() || '0';
        this.$printFunc.printReceipt(printData, this.order_id);
      }
    },
    getIntegtalSetting() {
      this.$api.shop_scoreGet().then(res => {
        if (res.code == 1) {
          if (res.data.score_set) {
            this.open_deduction = res.data.score_set.is_offset == 1;
            this.deduction_num = res.data.score_set.offset_score;
          }
        } else {
          this.$toast(res.msg);
        }
      })
    },
    clearVip() {
      this.$store.commit('setVipInfo', {});
      // this.integral_deduction_price = 0;
      // this.getPrice();
      this.clearRateOrWipe(1);
      this.getOrder()
    },
    useNumchange() {
      if (Number(this.use_integral_num) > Number(this.can_use_integral)) {
        this.$toast("不能大于可使用积分");
        this.use_integral_num = '';
      }
      if (this.use_integral_num && !/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.use_integral_num)) {
        this.$toast('必须为数字，小数点后最多两位');
        return;
      }
      if (this.use_integral_num) {
        // this.use_deduction_price = this.$bigN(this.use_integral_num).div(this.deduction_threshold).times(this.deduction_num).toFixed(2);
        this.use_deduction_price = this.$bigN(this.use_integral_num).times(this.deduction_num).toFixed(2);
      } else {
        this.use_deduction_price = 0;
      }
    },
    openIntegralNum() {
      this.show_use_num = true;
    },
    confirmIntegralDeduction() {
      if (this.$bigN(this.use_deduction_price).gt(this.money)) {
        this.$toast('积分抵扣不可超过订单应收金额');
        return;
      }
      if (Object.keys(this.choosed_coupon).length) {
        this.showDialog = true;
        this.dialogTitle = "提示";
        this.dialogType = 'deduction';
        this.dialogMsg = '修改打折、积分抵扣、抹零会清空已选中的优惠券，可重新选择优惠券。';
        return;
      }
      this.integral_deduction_price = this.use_deduction_price;
      this.clearRateOrWipe(4);
      // this.getPrice();
      this.closeIntegralDeduction();
    },
    closeIntegralDeduction() {
      this.show_deduction_modal = false;
    },
    openIntegralDeduction() {
      if (!this.open_deduction) {
        this.$toast('未启用积分抵扣，可到【设置-会员设置】中开启使用');
        return;
      }
      if (!Object.keys(this.vipInfo).length) {
        this.$toast('请选择会员');
        return;
      }
      if (!this.can_use_integral || this.can_use_integral <= 0) {
        this.$toast('无可用积分，不能抵扣');
        return;
      }
      this.use_integral_num = '';
      this.show_deduction_modal = true;
    },
    openMemModal() {
      this.vipModal = true;
      this.vipInfoShow = [];
      this.vipTel = '';
      this.choose_index = -1;
      this.is_show_notdata = false;
      this.$nextTick(() => {
        this.$refs.membercodeinput.focus();
        this.$refs.membercodeinput.setAttribute("readonly", "readonly");
        setTimeout(() => {
          this.$refs.membercodeinput.removeAttribute("readonly");
        }, 200);
      }, 100);
    },
    confirmMemChoose(index) {
      this.choose_index = index;
      if (this.choose_index == -1) {
        this.$toast('请选择会员');
        return;
      }
      this.$store.commit('setVipInfo', { ...this.vipInfoShow[this.choose_index] });
      // this.getPrice();
      this.clearRateOrWipe(1);
      this.getOrder();
      this.vipModal = false;
      this.payTypeId = 6;
    },
    getPayType(val) {
      let obj = {
        1: "微信",
        2: '支付宝',
        3: "现金",
        4: '云闪付',
        6: '会员余额'
      };
      return obj[val] || "";
    },
    offlinePay() {
      const pay_type = getPayWayFromCode(this.paycode);
      let params = {
        pay_type: pay_type,
        pay_bar_code: this.paycode, // 支付码
        // fact_pay_price: this.real_price, // 不包含积分抵扣
        fact_pay_price: this.money, // 实收
        order_num: this.order_num, //订单号
        cash_price: this.money2, // 直接手动输入
        discount_rate_price: this.rate_price || '0', // 打折金额
        discount_rate: this.discount_rate || '100', // 打折折扣
        wipe_price: this.wipe_price || '0', // 抹零金额
      }
      if (Object.keys(this.vipInfo).length) {
        params['pay_member_id'] = this.vipInfo.id;
      }
      if (this.use_integral_num && this.use_integral_num > 0) {
        params['score'] = this.use_integral_num;
      }
      this.$api.dt_immediatePay(params, "dontLoad").then((res) => {
        this.payStatus = res.code;
        if (res.code === 1) {
          this.$toast.success("支付成功");
          this.$store.commit("updateShopCart", []); // 提交后清除购物车列表
          this.$store.commit("undateCurrentSwee_code", ""); // 提交后清除唯一编码
          this.order_num = ""; //重置订单号
          this.printOrder(res.data, pay_type);
          if (res.data.list.audio_url) {
            const instance = new Audio(); // 创建音频对象
            instance.src = res.data.list.audio_url; // 设置音频播放地址
            instance.play(); // 调用音频播放
          }
          this.$store.commit('setVipInfo', {}) // 清空会员选择
          this.$router.replace({ name: "index" }); //支付成功跳转回购物车
        } else {
          Toast(res.msg || "支付失败");
          this.overPay = true; // 付款请求完成后才允许再次付款
          this.is_click = false;
        }
      })
        .catch((err) => {
          this.$toast.clear();
          this.$toast("支付失败");
          this.is_click = false;
        });
    },
    // 现金、会员余额支付
    shoukuanPay() {
      let params = {
        pay_type: this.payTypeId, // 支付方式 1微信,2支付宝，3现金，4云闪付，5其它，6会员余额 7收呗 8银行余额 9会员码
        order_num: this.order_num, //订单号
        // fact_pay_price: this.real_price, // 不包含积分抵扣
        fact_pay_price: this.money, // 实收
        cash_price: this.money2, // 直接手动输入
        discount_rate_price: this.rate_price || '0', // 打折金额
        discount_rate: this.discount_rate || '100', // 打折折扣
        wipe_price: this.wipe_price || '0', // 抹零金额
      }
      if (Object.keys(this.vipInfo).length) {
        params['pay_member_id'] = this.vipInfo.id;
      }
      if (this.use_integral_num && this.use_integral_num > 0) {
        params['score'] = this.use_integral_num;
      }
      console.log('shoukuanPay params', params);
      this.$api.dt_immediatePay(params, "dontLoad").then((res) => {
        this.payStatus = res.code;
        if (res.code == 1) {
          this.$toast.success("支付成功");
          this.$store.commit("updateShopCart", []); // 提交后清除购物车列表
          this.$store.commit("undateCurrentSwee_code", ""); // 提交后清除唯一编码
          this.order_num = ""; //重置订单号
          if (this.payTypeId == 3) {
            this.openDevieBox();
          }
          this.printOrder(res.data, this.payTypeId);
          if (res.data.list.audio_url) {
            const instance = new Audio(); // 创建音频对象
            instance.src = res.data.list.audio_url; // 设置音频播放地址
            instance.play(); // 调用音频播放
          }
          this.$store.commit('setVipInfo', {}) // 清空会员选择
          this.$router.replace({ name: "index" }); //支付成功跳转回购物车
        } else {
          Toast(res.msg || "支付失败");
          this.overPay = true; // 付款请求完成后才允许再次付款
          this.is_click = false;
        }
      })
        .catch((err) => {
          this.$toast.clear();
          this.$toast("支付失败!");
          this.is_click = false;
        });
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    confirmPopDialog(data) {
      switch (data.type) {
        case "shoukuan":
          this.shoukuanPay();
          break;
        case 'memshoukuan':
          // 会员支付
          console.log(Object.keys(this.vipInfo).length);
          if (Object.keys(this.vipInfo).length) {
            this.shoukuanPay();
          } else {
            this.$toast("未选择会员！");
          }
          break;
        case "dazhe":
          this.submitAtDiscount();
          this.closePopDialog();
          break;
        case "moling":
          this.submitStaffDiscount();
          this.closePopDialog();
          break;
        case 'deduction':
          this.integral_deduction_price = this.use_deduction_price;
          this.clearRateOrWipe(4);
          // this.getPrice();
          this.closeIntegralDeduction();
          this.closePopDialog();
          break;
        default:
          this.closePopDialog();
          break;
      }
    },
    // 支付
    async pay() {
      if (!this.payTypeId || this.payTypeId == '') {
        this.$toast('请选择支付方式');
        return;
      }
      if (this.payTypeId != 6 && this.payTypeId != 3 && this.money <= 0) {
        this.$toast("订单金额不允许为0，请重新点餐");
        return;
      }
      if (this.is_click) return;
      this.is_click = true;
      await this.getOrder(1);
      if (this.payTypeId == 7) {
        this.isdisabled = true;
        // 微信、支付宝
        this.offlinePay();
        return;
      } else if (this.payTypeId == 6) { // 会员余额
        if (Object.keys(this.vipInfo).length) {
          this.shoukuanPay();
        } else {
          this.$toast("未选择会员！");
        }
      } else if (this.payTypeId == 3) { // 现金
        this.shoukuanPay();
      }

      let that = this;
      // this.isShowModal=true;
      // this.showDialog = true;
      // this.dialogTitle = "确认收款";
      // this.dialogType = this.payTypeId == 6 ? 'memshoukuan' : "shoukuan";
      // this.dialogMsg =
      //   (this.payTypeId == 6 ? '会员余额' : "现金") +
      //   ",确认收款￥" +
      //   this.money +
      //   "?";
    },
    // 阻止键盘弹起
    notKeyboard(event) {
      this.$nextTick(() => {
        event.preventDefault();
        this.$refs.paycodeInput.setAttribute("readonly", "readonly");
        setTimeout(() => {
          this.$refs.paycodeInput.removeAttribute("readonly");
        }, 200);
      }, 100);
    },
    clearCode() {
      this.paycode = "";
      this.isdisabled = false;
      this.initHcdScan();
      this.$nextTick(() => {
        this.$refs.paycodeInput.focus();
        this.$refs.paycodeInput.setAttribute("readonly", "readonly");
        setTimeout(() => {
          this.$refs.paycodeInput.removeAttribute("readonly");
        }, 200);
      }, 100);
    },
    collechange() {
      if (Number(this.collection) < Number(this.money)) {
        this.$toast("收款金额小于待收金额！");
        this.collection = this.money;
      }
    },
    choosePayWay(id, item) {
      if (!this.is_can_submit) return;
      // this.showdiv = false;
      this.payTypeId = id;
      this.showInput = false;
      if (this.payTypeId == 7) {
        this.isdisabled = false;
        this.showInput = true;
        this.paycode = "";
        this.initHcdScan();
        this.$nextTick(() => {
          this.$refs.paycodeInput.focus();
          this.$refs.paycodeInput.setAttribute("readonly", "readonly");
          setTimeout(() => {
            this.$refs.paycodeInput.removeAttribute("readonly");
          }, 200);
        }, 100);
      }
    },
    paycodeBlur(e) {
      if (!e.target.value || e.target.value == '') {
        this.isdisabled = false;
        this.showInput = false;
        this.paycode = '';
        this.payTypeId = '';
        this.$store.commit('updateHcdBarcode', '');
      }
    },
    paySuccessPropt(money) {
      Dialog({
        title: '付款成功！',
        // message: `用户付款：${money}元！`,
        confirmButtonColor: '#1588f5'
      });
      setTimeout(() => {
        Dialog.close()
      }, 2000)
    },
    // 计算商品总额
    getPrice() {
      return new Promise((resolve, reject) => {
        let discount_rate = this.discount_rate == "" ? 100 : this.discount_rate;
        console.log('discount_rate', discount_rate);
        if (Object.keys(this.vipInfo).length) {
          console.log("%c Line:1489 🍣 this.vipInfo", "color:#fca650", this.vipInfo, this.is_mem_price);
          if (this.is_mem_price == 1) {
            // 会员价
            // console.log('this.totalPrice', this.totalPrice, 'this.totalMemberPrice', this.totalMemberPrice);
            const face = this.choosed_coupon.face || 0;
            this.price = this.totalPrice;
            let ac_money = this.$bigN(this.totalMemberPrice).times(discount_rate).div(100).minus(this.wipe_price).minus(this.integral_deduction_price).toFixed(2);;
            if (Object.keys(this.choosed_coupon).length && this.choosed_coupon.type == 1) { // 满减券
              this.money = this.$bigN(ac_money).minus(face).toFixed(2);
            } else if (Object.keys(this.choosed_coupon).length && this.choosed_coupon.type == 2) { // 折扣券
              this.money = this.$bigN(ac_money).times(face).div(10).toFixed(2);
            } else {
              this.money = ac_money;
            }
            // console.log('this.money', this.money, this.$bigN(this.totalMemberPrice).times(discount_rate).div(100).toFixed(2));
            this.dz_price = this.totalMemberPrice;
            this.collection = this.money;
            this.member_price = this.$bigN(this.totalPrice).minus(this.totalMemberPrice).toFixed(2);
            // console.log(this.member_price, 'member_price');
            this.rate_price = this.$bigN(this.totalMemberPrice).minus(this.$bigN(this.totalMemberPrice).times(discount_rate).div(100)).toFixed(2); // 整单打折金额
            if (this.choosed_coupon.type == 1) { // 满减券
              this.coupon_price = face;
            } else if (this.choosed_coupon.type == 2) { // 折扣券
              this.coupon_price = face ? this.$bigN(ac_money).minus(this.$bigN(ac_money).times(face).div(10)).toFixed(2) : 0; // 优惠券优惠金额
            }
            console.log('this.coupon_price', this.coupon_price);
            // this.real_price = this.$bigN(this.totalMemberPrice).times(discount_rate).div(100).minus(this.wipe_price).toFixed(2);
            resolve();
          } else { // 会员折扣
            const face = this.choosed_coupon.face || 0;
            console.log('face', face);
            // console.log('daishou_vip', daishou_vip);
            this.price = this.totalPrice
            const daishou_vip = this.vipInfo.rate ? this.$bigN(this.totalPrice).times(this.vipInfo.rate).div(100).toFixed(2) : this.totalPrice;
            let daishou = this.$bigN(daishou_vip).times(discount_rate).div(100).toFixed(2);
            console.log('daishou', daishou);
            // if (this.choosed_coupon.type == 2 && face > 0) { // 折扣券
            //   daishou = this.$bigN(daishou_vip).times(discount_rate).div(100).toFixed(2);
            // }
            console.log('待收', daishou);
            this.dz_price = daishou;
            let ac_money = this.$bigN(daishou).minus(this.wipe_price).minus(this.integral_deduction_price).toFixed(2);
            if (Object.keys(this.choosed_coupon).length && this.choosed_coupon.type == 1) { // 满减券
              this.money = this.$bigN(ac_money).minus(face).toFixed(2);
            } else if (Object.keys(this.choosed_coupon).length && this.choosed_coupon.type == 2) { // 折扣券
              this.money = this.$bigN(ac_money).times(face).div(10).toFixed(2);
            } else {
              this.money = ac_money;
            }
            this.collection = this.money;
            this.member_price = this.vipInfo.rate ? this.$bigN(this.totalPrice).minus(daishou_vip).toFixed(2) : 0; // 会员优惠金额
            this.rate_price = this.$bigN(daishou_vip).minus(this.$bigN(daishou_vip).times(discount_rate).div(100)).toFixed(2);
            if (this.choosed_coupon.type == 1) { // 满减券
              this.coupon_price = face;
            } else if (this.choosed_coupon.type == 2) { // 折扣券
              this.coupon_price = face ? this.$bigN(ac_money).minus(this.$bigN(ac_money).times(face).div(10)).toFixed(2) : 0;
            }
            // this.real_price = this.$bigN(daishou).minus(this.wipe_price).toFixed(2);
            resolve();
          }
        } else {
          this.price = this.totalPrice;
          this.money = this.$bigN(this.totalPrice).times(discount_rate).div(100).minus(this.wipe_price).minus(this.integral_deduction_price).toFixed(2);
          this.dz_price = this.totalPrice;
          this.collection = this.money;
          this.member_price = 0;
          this.rate_price = this.$bigN(this.totalPrice).minus(this.$bigN(this.totalPrice).times(discount_rate).div(100).toFixed(2));
          // this.real_price = this.$bigN(this.totalPrice).times(discount_rate).div(100).minus(this.wipe_price).toFixed(2);
          this.coupon_price = 0;
          resolve();
        }
      })
    },
    //生成订单
    getOrder(type) {
      return new Promise((resolve, reject) => {
        this.$api.dt_placeAnOrder({
          t: this.type,
          // total_price: this.totalPrice,
          // total_price: this.dz_price,
          // total_price: this.price,
          swee_code: this.currentSwee_code,
          // member_id: this.id || undefined,
          member_id: this.vipInfo.id || undefined,
          discount_rate: this.discount_rate || '100', // 打折折扣
          wipe_price: this.wipe_price || '0', // 抹零金额
          total: this.is_mem_price == 1 ? this.totalMemberPrice : this.price,
          coupon_code: this.coupon_code || '', // 优惠券券码
          score: this.use_integral_num || 0, // 抵扣所用积分
          sub: this.money, // 实收
        }).then(async (res) => {
          //生成订单号
          if (res.code === 1) {
            this.order_num = res.data.order_num;
            this.order_id = res.data.id;
            this.is_mem_price = res.data.is_member_price;
            if (this.is_mem_price == 1) {
              await this.getPrice();
            }
            console.log('this.order_num', this.order_num);
            setTimeout(() => {
              this.is_can_submit = true;
              this.$toast.clear();
            }, 800);
            resolve();
          } else {
            this.$toast(res.msg || '下单失败');
            this.is_click = false;
          }
        });
      })
    },
    async vipTelSearch() { // 手机号搜索vip
      if (!this.vipTel || this.vipTel == '') {
        this.$toast('输入手机号或姓名搜索');
        return;
      }
      if (this.vipTel != '') {
        const code_cont = this.vipTel.replace(/[|]/g, "|")
          .replace(/[丨]/g, "|")
          .replace(/[｜]/g, "|");
        console.log('code_cont', code_cont);
        let cont_arr = code_cont.split('|');
        console.log(cont_arr[0], cont_arr[1], parseInt((new Date().getTime() - 30000) / 1000));
        if (cont_arr[1] > 0 && cont_arr[1] < parseInt((new Date().getTime() - 30000) / 1000)) {
          this.vipTel = '';
          this.$toast('会员码已过期，请重新打开小程序出示会员码');
          return;
        }
        this.vipTel = cont_arr[0];
      }
      let res = await this.$api.dt_getMemberList({ content: this.vipTel, status: 1, })
      if (res.code === 1) {
        this.vipInfoShow = res.data.data.list;
        console.log(this.vipInfoShow);
        this.is_show_notdata = this.vipInfoShow.length == 0;
      } else {
        this.vipInfoShow = [];
        this.is_show_notdata = true;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.pagination {
  width: 30%;
  /deep/.van-pagination {
    font-size: 1.9rem;
  }
  /deep/.van-pagination__item--active {
    background-color: #1588f5;
  }
}
.index_list {
  width: calc(100% - 4rem);
  // height: 50rem;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: repeat(auto-fill, 15rem);
  grid-gap: 2.5rem 3%;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  .disabled-coupon {
    opacity: 0.5;
  }

  .index_box {
    position: relative;
    // width: 32.9rem;
    // height: 16rem;
    background: #ffffff;
    // box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    position: relative;
    padding: 0.5rem 1.5rem;
    height: 15rem;
    border: 0.2rem #cdcdcd solid;
    border-radius: 0.8rem;

    .coupon-left {
      width: 26.5%;
      position: relative;
    }
    .coupon-left::after {
      content: "";
      width: 0.15rem;
      height: 132%;
      position: absolute;
      right: 0;
      top: -15%;
      border-right: 0.15rem dashed #e5e5e5;
    }
  }
}
.choose-coupon-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

	.modal-content-box {
		width: 70%;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
	.close {
		width: 2.6rem;
    height: 2.6rem;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
		z-index: 10;
	}
	.wrapper {
		padding: 0rem 3.5rem;
		position: relative;

		.title {
			padding-top: 2.5rem;
			border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
		}

    .mocontent {
      margin: 2rem 0 1rem;
      position: relative;
      display: flex;
      justify-content: center;
      overflow-y: scroll;
      height: 58vh;
      .deskcontent {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .del-checkbox {
          position: absolute;
          left: 0;
          top: 0;
          background: #fff;
        }
      }
    }
  }
  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8.5rem;
    .confirm,
    .cancel {
      color: #fff;
      min-width: 20rem;
      height: 6.5rem;
      border-radius: 0.8rem;
      font-size: 2.4rem;
      padding: 0 2.5rem;
    }
    .cancel {
      background: #eaeaea;
      color: #666666;
      margin-right: 10rem;
    }
    .confirm {
      background: #1588f5;
    }
  }
}
.setdiscount-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

	.modal-content-box {
		width: 82rem;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
	.close {
		width: 2.6rem;
    height: 2.6rem;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
		z-index: 10;
	}
	.wrapper {
		padding: 0rem 3.5rem;
		position: relative;

		.title {
			padding-top: 2.5rem;
			border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
		}
	}
	.btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8.5rem;
		.confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
	}

  .discount-box-content {
    font-size: 2.4rem;
    padding: 7rem 4rem 7rem 4rem;

    .diinput {
      width: 10rem;
      height: 6.2rem;
      border: 0.2rem solid #dedede;
      border-radius: 0.8rem;
      margin-left: 1rem;
      margin-right: 1rem;
      padding: 0 2rem;
      font-size: 2.4rem;
    }
  }
}
.pplAmount {
  z-index: 1999;
  position: fixed;
  width: 82rem;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0.8rem;

  .title {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    font-size: 2.8rem;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }

  .line {
    width: 84%;
    height: 1px;
    background: rgba(204, 204, 204, 1);
  }

  input {
    width: 13.5rem;
    height: 4.7rem;
    border: 0.1rem solid #dedede;
    font-size: 2.6rem;
    color: rgba(153, 153, 153, 1);
    border-radius: 0.8rem;
    margin-left: 1.5rem;
    padding-left: 1rem;
  }

  .paycode-input {
    border: 0.2rem rgba(0, 0, 0, 0.06) solid;
    padding: 0.5rem 1rem;
    flex: 1;
    border-radius: 0.8rem;
    font-size: 2.4rem;
  }

  .clearcode-btn {
    background: #1588f5;
    color: #fff;
    font-size: 2.4rem;
    width: 11rem;
    height: 5.7rem;
    border-radius: 0.5rem;
    margin-left: 2rem;
  }

  .closeImg {
    width: 2.6rem;
    height: 2.6rem;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
  }

  .payway-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 2rem;
    width: 84%;

    .content-item {
      color: #333;
      width: calc((100% - 8rem) / 2);
      height: 11rem;
      border-radius: 0.8rem;
      background: #f2f2f2;
      position: relative;
    }

    .active-way {
      color: #fff;
      background: #1588f5;
    }
  }

  .mem-paymoney {
    font-size: 2.6rem;
    width: 84%;
    .price-code-input {
      width: 84%;
      text-align: left;
    }
  }

  .confirm {
    padding: 4.5rem 0 3rem;
    .cancel,
    .ok {
      width: 20rem;
      height: 6.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.8rem;
    }

    .cancel {
      margin-right: 10rem;
      background: #eaeaea;

      p {
        font-size: 2.4rem;
        color: #666;
      }
    }

    .ok {
      background: #1588f5;

      p {
        font-size: 2.4rem;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
.recharge-btn {
  width: 8rem;
  height: 3.5rem;
  border-radius: 0.8rem;
  color: #333;
  font-size: 2.4rem;
  background: #fff;
  margin-left: 1.5rem;
}
.nullStyle2 {
  padding-top: 4rem;
  text-align: center;
  img {
    margin: 1.5rem auto 0;
    width: 22rem;
    height: 18rem;
  }
}
.otherEatNum-nk {
  border-radius: 0.13rem;
  width: 30vw;
  left: 35vw;
  z-index: 9999;
}
.settlement-page {
  height: 100%;
}
.payway-cont {
  height: calc(100% - 10.4rem);
  // margin: 5vh 29vw;
  // line-height: 1.8;
  font-size: 2.4rem;
  color: #333;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  .paywaycont-left,
  .paywaycont-right {
    width: 49%;
    background: #ffffff;
    border-radius: 0.8rem;
    position: relative;
    // padding-bottom: 0.68rem;
  }

  .paywaycont-left {
    overflow-y: scroll;
    padding-bottom: 3rem;
    .orderinfo {
      background: #333333;
      // background: #FE724F;
      height: 17rem;
      padding: 0 3.5rem;
      font-size: 2.4rem;
      color: #fff;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: baseline;
      border-radius: 0.8rem 0.8rem 0 0;
    }

    .priceinfo {
      margin: 3rem 3.5rem 0;

      .pricedetail {
        padding: 3rem 0;
        border-bottom: 0.2rem rgba(0, 0, 0, 0.06) solid;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .actionbtns {
    // position: absolute;
    width: 100%;
    // bottom: 0.68rem;
    // left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .action-btn {
      width: 15rem;
      height: 5.5rem;
      border-radius: 0.8rem;
      font-size: 2.2rem;
    }
  }

  .paywaycont-right {
    overflow-y: scroll;
    //
    .paywaycont-right-content {
      text-align: left;
      padding: 2.7rem;
      // margin-top: 2rem;

      .payment-method {
        font-size: 3.2rem;
        font-weight: 600;
      }
    }
  }

  .payway-list {
    display: flex;
    align-items: center;
    margin: 2.8rem 0;

    .content-item {
      color: #333;
      width: calc((100% - 5rem) / 3);
      height: 11rem;
      border-radius: 0.8rem;
      background: #f2f2f2;
      position: relative;
    }

    .active-way {
      color: #fff;
      background: #1588f5;
    }
  }

  .surepay-btn-box {
    width: 43%;
    position: fixed;
    bottom: 1.5rem;
    right: 0;
    padding: 0 2.5rem;

    .coupon-c-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 8.7rem;
      background: #3db956;
      border-radius: 0.8rem;
      font-size: 2.8rem;
      padding: 0 3rem;
      margin-right: 2rem;
    }

    .surepay-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      height: 8.7rem;
      background: #1588f5;
      border-radius: 0.8rem;
      font-size: 2.8rem;
      padding: 0 4rem;
    }

    .disabled_surepay-btn {
      color: #c8c9cc;
      background: #f2f2f2;
    }
  }

  .paycode-input {
    border: 0.2rem rgba(0, 0, 0, 0.06) solid;
    padding: 1.5rem 1.5rem;
    flex: 1;
    border-radius: 0.8rem;
  }

  .price-code-input {
    width: 80%;
  }

  .clearcode-btn {
    background: #1588f5;
    color: #fff;
    font-size: 2.6rem;
    width: 12rem;
    height: 5.7rem;
    border-radius: 0.8rem;
    margin-left: 3rem;
  }

  .vipimgs {
    text-align: left;
    margin: auto;
    height: 17rem;
    background-image: url("../../static/images/member-bgimg.png");
    // background: #333333;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 0 6rem 0 4.2rem;
    font-size: 2.6rem;
    font-weight: bold;
    position: relative;

    .vip-clear-box {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 6rem;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 2.8rem;
        height: 2.8rem;
      }
    }

    .vip_icon_add {
      position: absolute;
      top: 40%;
      left: 38%;
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .price-font2 {
      color: #fff;
      font-weight: 600;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

.vip-modal {
  height: 60vh;
  width: 86rem;
  background: #fff;
  .vip-modal-head {
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    .vip-modal-head-title {
      font-size: 3.6rem;
    }
    img {
      position: absolute;
      right: 4rem;
      width: 4.3rem;
    }
  }
  .vip-modal-body {
    height: calc(100% - 19rem);
    overflow-y: scroll;
    .vip-modal-body-input {
      display: flex;
      justify-content: center;
      align-items: center;
      input {
        //width: 62.6rem;
        width: 46rem;
        height: 6rem;
        background: #f8f8f8;
        border-radius: 0.8rem;
        //margin: 0 0 2rem 2rem;
        padding-left: 6.3rem;
        font-size: 2.6rem;
        font-weight: 400;
        color: #666666;
        line-height: 3.7rem;
        text-align: left;
      }
      .van-button {
        margin-left: 2rem;
        height: 6rem;
        border-radius: 0.8rem;
      }
    }
    .vip-modal-body-info {
      font-size: 2.6rem;
      margin: 0 10rem;
      line-height: 1.3;
      text-align: left;

      .vipinformation {
        color: #fff;
        padding: 0 2.5rem;
        height: 11rem;
        margin: 2rem 0 1.5rem 0;
        border-radius: 0.8rem;
        background-image: url("../../static/images/member-bgs.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }

      .choosed1 {
        background-image: url("../../static/images/member-bg.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }

    .btns {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 8.5rem;
      .confirm,
      .cancel {
        color: #fff;
        min-width: 20rem;
        height: 6.5rem;
        border-radius: 0.8rem;
        font-size: 2.4rem;
        padding: 0 2.5rem;
      }
      .cancel {
        background: #eaeaea;
        color: #666666;
        margin-right: 10rem;
      }
      .confirm {
        background: #1588f5;
      }
    }
  }
  .vip-modal-bottom {
    height: 8.5rem;

    .confirm,
    .cancel {
      color: #fff;
      min-width: 20rem;
      height: 6.5rem;
      border-radius: 0.8rem;
      font-size: 2.4rem;
      padding: 0 2.5rem;
    }
    .cancel {
      background: #eaeaea;
      color: #666666;
      margin-right: 10rem;
    }
    .confirm {
      background: #1588f5;
    }
  }
}

.integral-deduction-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
	
	.modal-content-box {
		width: 82rem;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
	.close {
		width: 2.6rem;
    height: 2.6rem;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
		z-index: 10;
	}
	.wrapper {
		padding: 0rem 3.5rem;
		position: relative;

		.title {
			padding-top: 2.5rem;
			border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
		}
	}
	.btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8.5rem;
		.confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
	}
}

.deduction-content {
  font-size: 2.4rem;
  padding: 7rem 4rem;

  .use-integral-input {
    width: 10rem;
    height: 6.2rem;
    border: 0.2rem solid #dedede;
    border-radius: 0.8rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0 2rem;
    font-size: 2.4rem;
  }
}
</style>
