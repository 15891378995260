<template>
	<div class="ratholOrChair">
		<div class="modal-content-box relative" style="z-index: 2002;">
			<img v-if="showCloseBtn" src="@/static/images/close.png" class="close" alt srcset @click="closePopDialog" />

			<div class="wrapper">
				<!-- 标题 -->
				<div v-if="title != ''" :class="['title', 'color-333', 'ft-28', 'flex', 'flex-ac', 'flex-jc', 'ft-bold','pd-bt25']">{{title}}</div>

				<div class="list-content" v-if="type == '1'">
					<div class="cont-title-box">
						<div v-for="(item, i) in list" class="cont-title-item" :class="{'cont-title-item-ac': i == idx}" @click="idx = i;">{{ item.title }}</div>
					</div>
					<div class="msglist-cont">
						<div v-for="(item, index) in thisPaper.msgs" :key="index">{{ item }}</div>
						<div class="sms-skm" v-if="typeof thisPaper.image == 'string'">
							<img :src="thisPaper.image" />
						</div>
						<div class="sms-skm dialog-img-line-box" v-if="typeof thisPaper.image == 'object'">
							<img class="dialog-img-line-item" v-for="item in thisPaper.image" :src="item" />
						</div>
					</div>
				</div>
				<template v-else>

					<template v-if="type == '3'">
						<div class="dia-content">
							<div v-for="(item, index) in data.dis" :key="index" class="disdata-item">
								<span>{{ item.name }}</span>
								<span>￥{{ item.value }}</span>
							</div>
						</div>
					</template>

					<template v-if="type == '2'">
						<div class="msglist-cont">
							<div v-for="(item, index) in data.msgs" :key="index">{{ item }}</div>
							<div class="sms-skm" v-if="data.image">
								<img :src="data.image" />
							</div>
						</div>
					</template>

          <template v-if="type == 'buyTips'">
            <div class="msglist-cont">
              <div v-for="(item, index) in data.msgs" :key="index">{{ item }}
                <span v-if="type == 'buyTips' && index == data.msgs.length - 1" class="text-underline color-FE724F" @click.stop="toContact">联系我们</span>
              </div>
              <div v-if="type == 'buyTips'" class="sms-skm">
                <img :src="data.image" />
              </div>
            </div>
          </template>


					<div class="deskcontent alltext-lh" v-else>{{ message }}</div>
				</template>
			</div>
				<div v-if="showCancelBtn || showConfirmBtn" style="height: 9rem;"></div>
			<!-- 确认/取消 -->
			<div class="btns flex flex-jc">
				<button class="cancel" v-if="showCancelBtn" @click="closePopDialog">{{ cancelText }}</button>
				<button class="confirm " v-if="showConfirmBtn" @click="confirmPopDialog">{{ confirmText }}</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '提示'
		},
		message: {
			type: String,
			default: ''
		},
		showCancelBtn: {
			type: Boolean,
			default: true
		},
		cancelText: {
			type: String,
			default: '取消'
		},
		showConfirmBtn: {
			type: Boolean,
			default: true
		},
		confirmText: {
			type: String,
			default: '确认'
		},
		type: {
			type: String,
			default: ''
		},
		data: {
			type: Object,
			default: {}
		},
		list: {
			type: Array,
			default() {
				return []
			},
		},
		showCloseBtn: {
			type: Boolean,
			default() {
				return true
			}
		}
	},
	data() {
		return {
			idx: 0,
		};
	},
	async mounted() {

	},
	computed: {
		thisPaper() {
			console.log(this.list[this.idx]);
			return this.list[this.idx];
		}
	},
	methods: {
    toContact() {
      this.$router.push({
        name: 'featureSet',
        query: {
          name:"about-dzb",
          active: 12,
          type:2
        }
      })
      this.closePopDialog();
    },
		closePopDialog() {
			this.$emit("closePopDialog", { type: this.type, data: this.data });
		},
		confirmPopDialog() {
			this.$emit('confirmPopDialog', { type: this.type, data: this.data })
		}
	}
};
</script>

<style lang="less" scoped>
.disdata-item {
	padding: 3.5rem 1rem;
	border-bottom: 0.15rem rgba(0, 0, 0, 0.06) solid;
	display: flex;
	justify-content: space-between;
	font-size: 2.4rem;
}
// .disdata-item:last-child {
// 	border-bottom: none;
// }
* {
	box-sizing: border-box;
}
.pd-bt49 {
	padding-bottom: 0.49rem;
}
.ratholOrChair {
	text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;

	.modal-content-box {
		width: 82rem;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
	.close {
		width: 2.6rem;
    height: 2.6rem;
		position: absolute;
    right: 2.5rem;
    top: 2.5rem;
		z-index: 10;
	}
	.wrapper {
		padding: 0rem 3.5rem;
		position: relative;

		.title {
			padding-top: 2.5rem;
			border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
		}
	}
	.btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8.5rem;
		.confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
	}
}
.deskcontent {
	display: flex;
	align-items: center;
	font-size: 2.6rem;
	line-height: 1.6;
	max-height: calc(88vh - 19rem);
	height: fit-content;
	overflow-y: scroll;
	margin: 3rem 5rem 3rem;
	position: relative;
	min-height: 25vh;
	justify-content: center;
}

.alltext-lh {
	line-height: 1.8;
}

.msglist-cont {
	font-size: 2.6rem;
	line-height: 1.6;
	// width: 100%;
	max-height: calc(88vh - 19rem);
	height: fit-content;
	overflow-y: scroll;
	margin: 3rem 5rem 3rem;
	position: relative;
	min-height: 25vh;

	.sms-skm {
		width: 28rem;
		height: 33rem;
		margin: 0 auto;
		img {
			width: 100%;
			height: 100%;
		}
	}

	.dialog-img-line-box {
		display: flex;
		width: 100%;
		height: 39rem;
		align-items: center;
		justify-content: space-between;

		.dialog-img-line-item {
			width: 32rem;
			height: 32rem;
		}
		.dialog-img-line-item:nth-child(2) {
			margin-right: 0;
		}
	}
}

.dia-content {
	margin: 4rem auto 5rem;
	position: relative;
	min-height: 18vh;
	height: auto;
	width: 75%;
}

.list-content {
	.content {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}
	.cont-title-box {
		display: flex;
		justify-content: center;
		.cont-title-item {
			text-align: center;
			line-height: 7rem;
			width: 31%;
			height: 7rem;
			border: 0.2rem solid #DEDEDE;
			font-size: 2.6rem;
			color: #666666;
			margin-top: 3rem;
		}

		.cont-title-item:nth-child(1) {
			border-right: none;
			border-radius: 0.8rem 0 0 0.8rem;
		}
		.cont-title-item:nth-child(3) {
			border-left: none;
			border-radius: 0 0.8rem 0.8rem 0;
		}

		.cont-title-item-ac {
			background: #1588f5;
			border-color: #1588f5;
			color: #fff;
		}
	}
}
</style>
